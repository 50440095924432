<div class="main-artboard position-relative">
    <div class="floating-rays">
        <img src="assets/images/big-studd.png" class="car-f0 zindex1" alt="big studds">
    </div>
    <div class="map-loader text-center" *ngIf="mediaLoader==true">
        <span></span>
    </div>
    <section class="dealer-sec zindex1" *ngIf="mediaLoader==false" >
        <div class="container">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <h1 class="sec-title text-center mb-4">{{pageTitle}}</h1>
                    <p style="font-family: 'Roboto', sans-serif; text-align: center; color: #fff; font-size: 1.1em; margin-top: 0; margin-bottom: 1.2em;" [innerHTML]="pageDescription"></p>
                    <div [innerHTML]="content">

                    </div>
                </div>
            </div>
        </div>
    </section>
</div>