<div class="main-artboard position-relative">
		<div class="floating-rays">
            <img src="assets/images/big-studd.png" class="car-f0 zindex1" alt="big studds">
		</div>

		<section class="cat-wrap text-center text-white position-relative zindex1">
			<div class="container">
				<div class="row">
					<div class="col-md-6 offset-md-6 text-right mb-4 filterbox-wrap"  *ngIf="categoryproduct">
						<span style="font-size: 1.5em;">SORT BY</span>
						<div class="filterbox ml-2">
							<select #sorting (change)="sortIt(sorting.value)">
								<option value="">Select Filter</option>
								<option value="lowtohigh">Price: Low to High</option>
								<option value="hightolow">Price: High to Low</option>
								<option value="asc">By Name: A-Z</option>
								<option value="desc">By Name: Z-A</option>
								<!-- <option>Newest Arrivals</option> -->
							</select>	
						</div>
					</div>
				</div>
				<div class="row new-desc-2">
						<div class="col-lg-12">
							<h1 class="sec-title text-white mb-3" *ngIf="pageTitle != ''">{{mainTitle}}</h1>
							<!-- <p style="font-family: 'Roboto', sans-serif; text-align: justify; color: #fff; font-size: 1.1em; margin-top: 0; margin-bottom: 1.2em;" [innerHtml]="mainDesc"></p> -->
						</div>
					</div>
				<div class="map-loader text-center" *ngIf="mediaLoader==true">
                    <span></span>
                </div>
				<div class="row"  *ngIf="mediaLoader==false">
					<div class="col-lg-3 col-md-4 col-sm-6 col-6" *ngFor="let cat of category" >
						<div *ngIf="categorycheck">
							<a class="cat-single wow fadeIn" href="{{firstid}}/{{ cat.producturl }}">
								<div class="casd">
									<div class="cat-single-inner">
										<img [src]="cat.image" class="img-fluid" alt="{{ cat.alt_tag  }}">
										<h3 class="cat-single-name">{{ cat.name }}</h3>
									</div>
								</div>
							</a>
						</div>
						<div *ngIf="categoryproduct">
							<a class="cat-single wow fadeIn" href="{{firstid}}/{{secondid}}/{{ cat.producturl }}">
								<div class="casd">
									<div class="cat-single-inner">
										<img [src]="cat.image" class="img-fluid" alt="{{ cat.alt_tag  }}">
										<h3 class="cat-single-name">{{ cat.name }}</h3>
										<!-- <h5 class="cat-single-price">MRP &#8377; {{ cat.price }}<br><span style="font-size: 13px">(Incl. of all taxes)</span></h5> -->
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div class="new-desc-2">
					<div class="col-lg-12">
						<p class="mb-0 pb-5" style="font-family: 'Roboto', sans-serif; text-align: justify; color: #fff; font-size: 1.1em; margin-top: 0; margin-bottom: 1.2em;" [innerHtml]="mainDesc"></p>
					</div>
				</div>
			</div>
		</section>
		<section class="access-sec text-center zindex1" style="padding-top: 50px;" *ngIf="showAccess">
			<div class="container">
				<h2 class="sec-title wow slideInDown">MOTORCYCLE ACCESSORIESs</h2>
				<div class="row align-items-center justify-content-center">
					<ng-container *ngFor="let acc of motoAccess">
                        <div class="col-lg-4 col-md-6 col-12 wow bounceIn" data-wow-delay="100ms">
                            <img [src]="acc.icons" class="access-icon" alt="{{acc.name}}">
                            <div class="access-single">
                            <a href="{{acc.purl}}/{{acc.url}}">{{acc.name}}</a>
                            </div>
                        </div>
                    </ng-container>
				</div>
			</div>
		</section>
	</div>
	<ng-container *ngIf="isHelmet!=true">
		<!-- <button class="floating-know" (click)="openKnow()">Know Your Helmet Size</button> -->
		<button class="vid-trigger3" (click)="openKnow()">
			<svg height="64" width="140">
				<path d="M62,31 a30,30 1 1,0 -30,30 l100 0" stroke-dasharray="240px" stroke-dashoffset="240px" stroke="#0fd1cf" stroke-width="2" fill="none" />
			</svg>
			<img src="assets/images/studdsbuttonbig2.png"><span>Know Your Helmet Size</span>
		</button>
		<div class="know-popup" id="know-popup">
			<div class="popup-inner">
				<h4 class="pop-head">How to choose the right helmet size?</h4>
				<p class="pop-desc">It is important to choose a helmet that will fit you perfectly. Check out the video to know how to measure your head size and check if the helmet fit comfortably on your head.</p>
				<div class="popwrap">
					<iframe width="100%" height="300" src="https://www.youtube.com/embed/SKhxxlnvmE0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
				<span class="fa fa-times" (click)="closeKnow()"></span>
			</div>
		</div>
	</ng-container>