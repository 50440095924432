import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { isNumber } from 'util';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {

  blogs: any;
  firstid: any;
  sub: any;
  paginationArray: any = [];
  prevPage: number;
  nextPage: number;
  showPrev: boolean;
  showNext: boolean;
  activatePagination: boolean;
  blogSearchOpened: boolean = false;
  public allBlogs: any;
  blogResultOpened: boolean = false;
  blog_url: any;
  mediaLoader: boolean = false;
  searchedValue:any;
  key:any;
  constructor(private data: ApiService, private route: ActivatedRoute, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }

  ngOnInit() {
    this.mediaLoader = true;
    this.sub = this.route.params.subscribe(params => {
      this.firstid = params['key']; // (+) converts string 'id' to a number
      this.firstid = this.firstid.replace(/-/g, ' ');
      this.key == this.firstid
    });
      this.data.getrelatedblogs(this.firstid).subscribe(data=> {
        this.mediaLoader = false;
        this.blogs = data;
        this.blogs.forEach(single => {
          single.mainpicture = this.data.baseImageUrl+single.mainpicture;
          single.thumbnail = this.data.baseImageUrl+single.thumbnail;
        });
        this.blogs.forEach(blgData => {
          let blgurl = blgData.blog_url.toLowerCase().replace(/\s+/g, '-');
          blgData.excerpt = blgData.excerpt.slice(0, 75) + '...';
          blgData.blogUrl = blgurl;
        });
      })

      const breadcrumb =  {dynamicText: this.firstid};
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
    }
    
  showblogresults(eve:any) {
    eve = eve.toLowerCase();
    this.searchedValue = eve;
    this.data.getblogs().subscribe(data=> {
      this.allBlogs = data;
      this.blog_url = {};
      this.allBlogs.forEach(function(accnames){
        let accname_url = accnames.title.toLowerCase().replace(/\s+/g, '-');
        let sorted_name = accnames.title.toLowerCase();
        accnames.url = accname_url;
        accnames.sortedName = sorted_name;
        accnames.sortedName = sorted_name;
        if(accnames.tags!=null) {
          accnames.tags = accnames.tags.split(',');
        }
      })
      if(eve!='') {
        this.blogResultOpened = true;
        this.allBlogs = this.allBlogs.filter(item=>{
          let tagMatched = false;
          if(item.tags!=null){
            item.tags.forEach(singleTag => {
              if(singleTag.toLowerCase().search(eve) > -1){
                tagMatched = true;
              }
            });
          }
          if(item.sortedName.search(eve) > -1 || tagMatched){
            return item;
          }
        })
      }
      else {
        this.blogResultOpened = false;
      }
    })
  }
  showrelatedwithkeywords(key:any){
     if(key == '' || key == undefined){ 
       return false;
     }
     location.href = 'blogs/search?q=' + key;
   }

}
