import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { AgmCoreModule, AgmInfoWindow } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction'; 

@Component({
  selector: 'app-brand-outlet',
  templateUrl: './brand-outlet.component.html',
  styleUrls: ['./brand-outlet.component.css']
})
export class BrandOutletComponent implements OnInit {
  outlets: any;
  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  states: any;
  cities: any;
  stateData: any;
  noState: boolean = false;
  noCity: boolean = false;
  loading: boolean = false;
  lat:number
  lng:number
  zoom:number
  center:any
  origin:any
  destination:any
  image:any;
  allImages:any;
  current:any;
  all:any;
  openpopup:any;
  firstId: any;
  protected map: any;
  //Slider options
  mediaLoader: boolean = false;
  popupDetails: any;
  currentState: any;
  currentCity: any;
  brandOptions: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 3000,
    items: 1,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 0,
    center: true,
    margin: 10,
    autoplayHoverPause: true,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    nav: false
  };

  brandPopUpOptions: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 3000,
    items: 1,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 0,
    center: true,
    margin: 10,
    autoplayHoverPause: true,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    nav: true
  };
  constructor(location: Location, private router: Router, private data: ApiService,private gmaps: AgmCoreModule,) {
    router.events.subscribe((val) => {
      if(location.path() == ''){
        this.url1 = true;
      } else {
        this.url1 = false;
      }
    });
    this.href = location.path();
    this.pathCount = this.href.split('/').length - 1;
  }

  ngOnInit() {
    this.mediaLoader = true;

    this.firstId= this.href.split('/')[1];
    this.data.getmetadatabypage(this.firstId).subscribe(data=> {
      this.titles = data;
      if(this.titles.length>0) {
        this.pageTitle=this.titles[0].pagetitle;
        this.pageDescription=this.titles[0].pagedescription;
      }
    })
    if(this.pathCount==2) {
      this.href = this.href.split('/')[2];
      this.currentState = this.href.split('/')[2];
      this.currentCity = '';
      this.getCityData(this.href.replace(/-/g, ' '), true);
    }
    else if (this.pathCount==3) {
      
      this.currentState = this.href.split('/')[2];
      this.currentCity = this.href.split('/')[3];
      this.href = this.href.split('/')[3];
      this.getOutletData(this.href.replace(/-/g, ' '), true);
      this.data.getoutletcitybystate(this.currentState).subscribe(data=>{
        this.loading=false;
        this.stateData = data;
        this.cities = this.stateData.city;
        console.log(this.cities);
        this.cities.forEach(single => {
          single.cityurl = single.city.toLowerCase().replace(/\s+/g, '-');
        });
      })
    }


    this.data.getOutletState().subscribe(data=>{
      this.states = data;
      this.states.forEach(single => {
        single.stateurl = single.state.toLowerCase().replace(/\s+/g, '-');
      });
      if(this.states.length==0) {
        this.noState = true;
      }
      this.mediaLoader = false;
    })
    this.data.getfaqbyid().subscribe(data=>{
      this.popupDetails = data;
    })
  }
  getCityData(state, reload) {
    this.loading=true;
    this.currentState = state.toLowerCase().replace(/\s+/g, '-');
    if(reload==false) {
      history.pushState({}, null, 'brand-outlet/'+state.toLowerCase().replace(/\s+/g, '-'));
    }
    this.data.getoutletcitybystate(state).subscribe(data=>{
      this.loading=false;
      this.stateData = data;
      this.cities = this.stateData.city;
      this.outlets = this.stateData.outlets;
      this.zoom = 10;
      this.origin = { lat: this.lat, lng: this.lng };
      
      this.outlets.forEach(single => {
        single.lat = +single.lat;
        single.lng = +single.lng;
        this.destination = { lat: single.lat, lng: single.lng };
       // this.map.setCenter({ lat: +single.lat, lng: +single.lng });
        single.image=single.image.split(',');
        single.alt_tag=single.alt_tag.split(',');
        for(let n=0;n<single.image.length;n++) {
          single.image[n] = 
          { 
            'image':this.data.baseImageUrl + single.image[n],
            'alt_tag': single.alt_tag[n],
          }
        }
      });
      if(this.cities.length==0) {
        this.noCity = true;
      }
    })
  }

  public mapReady(map) {
    this.map = map;
  }

  getOutletData(city, reload) {
    this.loading=true;
    this.currentCity = city.toLowerCase().replace(/\s+/g, '-');
    if(reload==false) {
      history.pushState({}, null, 'brand-outlet/'+this.currentState.toLowerCase().replace(/\s+/g, '-')+'/'+this.currentCity.toLowerCase().replace(/\s+/g, '-'));
    }
    this.data.getsingleoutlet(this.currentCity).subscribe(data=>{
      this.loading=false;
      this.outlets = data;
      this.zoom = 10;
      this.origin = { lat: this.lat, lng: this.lng };
      this.outlets = this.outlets.outlets;
      this.outlets.forEach(single => {
        single.lat = +single.lat;
        single.lng = +single.lng;
        this.destination = { lat: single.lat, lng: single.lng };
        single.image=single.image.split(',');
        single.alt_tag=single.alt_tag.split(',');
        for(let n=0;n<single.image.length;n++) {
          single.image[n] = 
          { 
            'image':this.data.baseImageUrl + single.image[n],
            'alt_tag': single.alt_tag[n],
          }
        }
      });
    })
  }

  openDetails(event: any, Details: any, allImages:any) {
    this.openpopup = true;
    this.allImages = Details.image;
    this.all = Details;
    let popup = document.getElementById('branddetailpopup');
    if(typeof popup !== 'undefined' && popup !== null) {
      popup.classList.add('active');
    }
    
  }

  closeDetails(){
    document.getElementById('branddetailpopup').classList.remove('active');
  }

  openPopup(): void{
    document.getElementById('detail-popup').classList.add('active');
  }
  closePopup(): void{
    document.getElementById('detail-popup').classList.remove('active');
  }

  openPop():void{
    let vid = <HTMLVideoElement> document.getElementById('popvid');
    // vid.play();
    document.getElementById('vidpop').classList.add('opened');
  }
  closePop():void{
    let vid = <HTMLVideoElement> document.getElementById('popvid');
    vid.pause();
    document.getElementById('vidpop').classList.remove('opened');
  }


}
