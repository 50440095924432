<div class="main-artboard position-relative">
    <div class="map-loader text-center" *ngIf="mediaLoader==true">
        <span></span>
    </div>
    <section class="blog-section position-relative zindex1" *ngIf="mediaLoader==false">
        <div class="container">
            <div class="blog-thumbnail">
                <img [src]="blogmainPic" height="450" alt="{{blog_alt_tag}}">
            </div>
            <div class="row blog-detail-wrap">
                <div class="col-lg-12 blog-details">
                    <div class="blog-detail-meta">
                        <ul>
                            <li><i class="fa fa-user"></i>{{blogauthor}}</li>
                            <li><i class="fa fa-calendar"></i>{{blogdate}}</li>
                            
                        </ul>
                    </div>
                    <h1 class="blog-title text-white">{{blogtitle}}</h1>
                    <div class="blog-content" [innerHTML]="blogcontent"></div>
                    
                   <button *ngFor="let tags of nameArr" class="btn btn-special" style="margin: 30px 10px 0px 0px;font-size: 13px" (click)="openwithkeyowrds(tags)">{{tags}}</button>
                    
                    <div class="blog-comment">
                    </div>
                </div>
            </div>
            <div class="single-blog-comment">
                <div class="comment-form">
                    <h3>Post Your Comment</h3>
                    <form [formGroup]="commentForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-input" [ngClass]="{'has-error': commentForm.get('message').errors && (commentForm.get('message').touched || commentForm.get('message').dirty) }">
                                    <textarea formControlName="message" class="form-field" placeholder="Comment *" required></textarea>
                                    <span class="help-block" *ngIf="commentForm.get('message').errors && (commentForm.get('message').touched || commentForm.get('message').dirty)">
                                        Comment is required
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-input" [ngClass]="{'has-error': commentForm.get('name').errors && (commentForm.get('name').touched || commentForm.get('name').dirty) }">
                                    <input type="text" formControlName="name" class="form-field" placeholder="Name*" required>
                                    <span class="help-block" *ngIf="commentForm.get('name').errors && (commentForm.get('name').touched || commentForm.get('name').dirty)">
                                        Name is required
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-input" [ngClass]="{'has-error': commentForm.get('email').errors && (commentForm.get('email').touched || commentForm.get('email').dirty) }">
                                    <input type="email" formControlName="email" class="form-field" placeholder="Email*" required>
                                    <span class="help-block" *ngIf="commentForm.get('email').errors && commentForm.get('email').errors.required && (commentForm.get('email').touched || commentForm.get('email').dirty)">
                                        Email is required
                                    </span>
                                    <span class="help-block" *ngIf="commentForm.get('email').errors && commentForm.get('email').errors.email && (commentForm.get('email').touched || commentForm.get('email').dirty)">
                                        Please enter a valid email
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-input">
                                    <input type="text" formControlName="website" class="form-field" placeholder="Website">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-input">
                                    <button type="submit" class="btn btn-special">Submit</button>
                                    <span style="margin-left: 15px" class="alert alert-danger dngr" *ngIf="emptyForm">Please fill the details</span>
                                    <span style="margin-left: 15px" class="alert alert-success" *ngIf="commentSuccess">Your Comment has been submitted successfully and awaiting for the approval.</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="comments-wrap">
                    <h4 class="comment-count text-white mb-4" *ngIf="commentCount>1">Total {{commentCount}} Comments</h4>
                    <h4 class="comment-count text-white mb-4" *ngIf="commentCount==1">Total {{commentCount}} Comment</h4>
                    <h4 class="comment-count text-white mb-4" *ngIf="commentCount==0">No Comments to Display</h4>
                    <div class="single-comment" *ngFor="let com of commentsList">
                        <img src="assets/icons/user.png" class="user-img" alt="user">
                        <div>
                            <h4 class="comment-user">
                                <span class="comment-user-name"><a [href]="com.website" target="_blank">{{com.name}}</a></span>
                                <span class="comment-date">({{com.dayBefore}})</span>
                            </h4>
                            <p class="user-comment">{{com.message}}</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>