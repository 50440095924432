<div class="main-artboard position-relative about-page">
        <div class="floating-rays">
         <img src="assets/images/big-studd.png" class="car-f0 zindex1" alt="big studds">
       </div> 
       <div class="map-loader text-center" *ngIf="mediaLoader==true">
        <span></span>
    </div>
       <section class="investor-sec zindex1" *ngIf="mediaLoader==false">
           <div class="container text-center mb-4">
               <div class="row">
                   <div class="col-lg-10 offset-lg-1">
                       <h1 class="sec-title text-white mb-3">{{pageTitle}}</h1>
                       <p style="font-family: 'Roboto', sans-serif; text-align: center; color: #fff; font-size: 1.1em; margin-top: 0; margin-bottom: 1.2em;" [innerHTML]="pageDescription"></p>
                   </div>
               </div>
           </div>
           <div class="container-fluid">
               <div class="row align-items-center">
                   <div class="col-md-12 investor-nav-wrap">
                       <ul class="investor-nav" >
                        <ng-container *ngFor="let item of investerArray; let i= index">
                            <li [attr.data-target]="item.id" [ngClass]="i?'':'active'" (click)="toggleInvesters($event, item.id)"><span>{{item.heading}}</span></li>
                        </ng-container>
                        
                       </ul>
                   </div>
                </div>
                   <div class="row align-items-center">
                   <div class="col-md-7 offset-md-3 text-center">
                        <ng-container *ngFor="let invester of investerArray; let i=index">
                            <div class="investor-details" [id]="invester.id" [ngStyle]="{'display': i ? 'none' : 'block' }">
                                <ng-container *ngFor="let desc of invester.pdfs">
                                    <div class="investor-single" id="indet-{{invester.id}}">
                                        <div class="investor-head" (click)="toggleBody($event, invester.id)">
                                            <h4>{{desc.pdftitle}}</h4>
                                            <a href="javascript:void(0)"></a>
                                        </div>
                                        <div class="investor-body">
                                            <a target="blank" [href]="desc.pdflinks"><img src="assets/images/pdficon.png" alt="pdficon"></a>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                       
                       <div class="investor-details" id="234423424" >
                           <div class="investor-single pt-3 text-left">
                               <div class="col-md-12">
                                   <div class="invest-cont row" style="font-size: 1em; font-family: roboto">
                                        <ng-container *ngFor="let inv of investorcontacts">
                                            <div class="col-md-6">
                                                <p><strong>{{inv.heading}}</strong></p>
                                                <p>{{inv.name}}</p>
                                                <p>{{inv.designation}}</p>
                                                <p>{{inv.email}}</p>
                                                <p>{{inv.phone}} </p>
                                            </div>
                                        </ng-container>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </section>
    </div>

    <button class="vid-trigger3" (click)="openPop()">
        <svg height="64" width="140">
            <path d="M62,31 a30,30 1 1,0 -30,30 l100 0" stroke-dasharray="240px" stroke-dashoffset="240px" stroke="#0fd1cf" stroke-width="2" fill="none" />
        </svg><img src="assets/images/studdsbutton2.png"><span>STUDDS In News</span></button>
    <div class="vidpop" id="vidpop">
        <div class="vidpopinner text-center">
            <h4 class="vcaption">Our respected MD, Mr. Sidharth Khurana in a video interview with Jagran HiTech about import from China becoming zero, #Studds new manufacturing units, doubling capacity of motorcycle helmets, export plans, helmet industry approach as well as Covid-19 impact and new smart helmet technology.</h4>
            <div class="wrap">
                <iframe id="popvid" width="100%" height="320" src="https://www.youtube.com/embed/NNRsC0rQodI?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <span class="fa fa-times" (click)="closePop()"></span>
        </div>
    </div>