<div class="main-artboard position-relative">
    <div class="floating-rays">
        <img src="assets/images/big-studd.png" class="car-f0 zindex1">
    </div>
    <section class="gallery-page zindex1" style="padding-top: 120px;">
        <div class="container position-relative text-center zindex1 pb-5">
            <img src="assets/icons/error404.png" style="width: 400px; max-width: 90%;">
            <h1 class="sec-title2 text-white mt-4">Page Not Found</h1>
            <p class="mb-5" style="font-size: 1.5em; font-family: roboto; margin-bottom: 1em; color: #fff;">The page you are looking for does not exist.</p>
            <a href="/" class="btn btn-special">Home</a>
        </div>
    </section>
</div>