<div class="main-artboard position-relative about-page">
		<div class="floating-rays">
			<img src="assets/images/middle-hanger.png" class="infleftright corp-ray0" alt="middle hanger">
			<img src="assets/images/big-studd.png" class="safetyray0 zindex1" alt="big studds">
		</div>
		<div class="map-loader text-center" *ngIf="mediaLoader==true">
			<span></span>
		</div>
		<section class="about-main zindex1" *ngIf="mediaLoader==false">
			<ng-container *ngFor="let section of aboutUsSections; let i = index;">
				<div class="about-sing noslid">
					<div class="container position-relative">
						<div class="row align-items-end noslid-wrap" [ngClass]="{'flex-lg-row-reverse':i==1}">
							<div class="col-lg-5">
								<img [src]="section.image" class="about-single-img img-fluid wow fadeInLeft" alt="{{section.alt_tag}}">
							</div>
							<div class="col-lg-7">
								<div class="about-single-det">
									<h3 class="about-single-title wow fadeInUp">{{section.title}}</h3>
									<div [innerHTML]="section.description"></div>
								</div>
							</div>
						</div>	
					</div>
				</div>
			</ng-container>
			<div class="about-sing">
				<div class="container position-relative" style="overflow: hidden;">
					<h3 class="about-single-title wow fadeInUp">{{aboutUsSliderTitle}}</h3>
					<div class="infra-sliders" *ngIf="resized">
						<owl-carousel-o [options]="customOptions">
							<ng-container *ngFor="let aw of aboutUsSlider; let i = index">
								<ng-template carouselSlide [id]="aw.id">
									<div class="item">
										<div class="row align-items-end">
											<div class="col-lg-5 mb-3 mb-lg-0">
												<img [src]="aw.image" class="about-single-img img-fluid wow fadeInLeft" alt="{{aw.alt_tag}}">
											</div>
											<div class="col-lg-7">
												<div class="about-single-det">
													<div class="item">
														<h3 class="about-subtitle mb-2 wow fadeInUp">{{aw.title}}</h3>
														<div [innerHTML]="aw.description"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</ng-container>
						</owl-carousel-o>
					</div>
				</div>
			</div>
		</section>
		<h2 class="about-vid-title text-center text-white position-relative zindex1">The Journey of a STUDDS Helmet: From Inception To Design to Execution</h2>
		<section class="about-video zindex1">
			<div class="about-floating">
				<div class="container">
					<div class="row">
						<div class="col-lg-8 offset-lg-2 col-md-12">
							<img src="assets/images/clock3.png" class="img-fluid" alt="STUDDS Helemt">
						</div>
					</div>
				</div>
			</div>
			<div class="container">
				<div class="row">
					<div class="col-lg-8 offset-lg-2 col-md-12">
						<div class="custom-video">
							<video autoplay="true" controls muted [muted]="true" preload="auto" playsinline="true">
								<source src="assets/images/about-vid.mp4" type="video/mp4">
							</video>
						</div>
					</div>
				</div>
			</div>
        </section>
	</div>
	<button class="vid-trigger3" (click)="openPop()">
		<svg height="64" width="140">
            <path d="M62,31 a30,30 1 1,0 -30,30 l100 0" stroke-dasharray="240px" stroke-dashoffset="240px" stroke="#0fd1cf" stroke-width="2" fill="none" />
		</svg>
		<img src="assets/images/studdsbutton2.png"><span>Do You Know?</span>
	</button>
	<div class="vidpop" id="vidpop">
		<div class="vidpopinner text-center">
			<h4 class="vcaption">A helmet is made of 100 small parts and components. Check the video to know how a helmet is made.</h4>
			<div class="wrap">
				<video controls preload="auto" playsinline="true" id="popvid">
					<source src="assets/images/about-vid.mp4" type="video/mp4">
				</video>
			</div>
			<span class="fa fa-times" (click)="closePop()"></span>
		</div>
	</div>