<div class="main-artboard position-relative">
		<div class="floating-rays">
			<img src="assets/images/big-studd.png" class="safetyray0" alt="big studds">
		</div>
		<div class="map-loader text-center" *ngIf="mediaLoader==true">
			<span></span>
		</div>
		<section class="zindex1" *ngIf="mediaLoader==false">
				<div class="container text-center ">
					<div class="row">
						<div class="col-lg-12">
							<h1 class="sec-title text-white mb-3" *ngIf="pageTitle != ''">{{pageTitle}}</h1>
							<p style="font-family: 'Roboto', sans-serif; text-align: justify; color: #fff; font-size: 1.1em; margin-top: 0; margin-bottom: 1.2em;" [innerHtml]="pageDescription"></p>
						</div>
					</div>
				</div>
		</section>
		<section class="product-single zindex1" [ngClass]="{'narrow':varCount < 6,'wide':varCount >= 6}" *ngIf="mediaLoader==false">
			<div class="container position-relative">
				<div class="row align-items-center">
					<div class="col-md-4 text-center text-md-right">
						<h2 class="product-name">
							{{ productname }}<br>
							<!-- <span class="product-price"><span>MRP &nbsp;</span>&#8377; {{price}} </span><br> -->
							
							<!-- <span class="price-desc">(INCL. OF ALL TAXES)<br>QUANTITY - {{quantity}}</span> -->
							<span class="price-desc"><span>Generic Name&nbsp;</span>: {{genricname}} </span><br>
							<div class="overall-rating">
								<span [ngStyle]="{'width':overallReview+'%'}"></span>
							</div>
							<div *ngIf="reviewCount == 1; then thenBlock else elseBlock"></div>
							<ng-template #thenBlock><p class="review" >{{ reviewCount }} Review</p></ng-template>
							<ng-template #elseBlock><p class="review" >{{ reviewCount }} Reviews</p></ng-template>

							<p class="rateus" (click)="scrollToElement(target)"><i class="fa fa-hand-pointer-o"></i>
								<button class="btn btn-special">RATE OUR PRODUCT</button>
							</p>
							
						</h2>
						
					</div>
					<div class="col-md-5 main-helmet-wrap">
						<img src="assets/images/behind-circle.png" class="behind-mask" alt="behind mask">
						<img src="assets/images/clock1mask.png" class="clock1mask" alt="clock1mask">
						<div class="main-helmet">
							<img [src]="image" alt="{{ alt_tag }}" >
						</div>
					</div>
					<div class="col-md-3">
						<div class="prod-var varcount{{varCount}}" id="product-varients">
							<div class="prod-var-single" id="myele{{prodvar.id }}" *ngFor="let prodvar of productVarient" (click)="getVarient(prodvar.id, prodvar.image)">
								<img [src]="prodvar.image" alt="{{ prodvar.alt_tag }}">
								<h5 class="prod-var-name">{{prodvar.color}}</h5>
							</div>
						</div>
						<div class="prod-var-nav d-block d-md-none text-center">
							<button class="prod-var-prev"><i class="fa fa-chevron-left"></i></button>
							<button class="prod-var-next"><i class="fa fa-chevron-right"></i></button>
						</div>
					</div>
				</div>

			</div>
		</section>
		<section class="sizes-sec zindex1">
			<div class="floating-size">
				<img src="assets/images/sizes-bg.png" alt="sizes bg">	
			</div>
			<div class="container position-relative">
				<div class="text-center mt-5" style="font-family: roboto" *ngIf="checkUrban">
					<p>(PINK COLOUR IS AVAILABLE IN SIZES XS, S, M)</p>
					<p>(BLUE COLOUR IS AVAILABLE IN SIZES L, XL)</p>
				</div>
				<div class="text-center mt-5">
					<p style="font-size: 1.2em;font-family: roboto;">Experience and buy the product at our 
						<a href="brand-outlet" class="floating-buy">Exclusive Brand Outlets <i class="fa fa-hand-pointer-o"></i></a> or locate a 
						<a href="dealers" class="floating-buy">Nearest Dealer<i class="fa fa-hand-pointer-o"></i></a>
					</p>
				</div>
				<ng-container *ngIf="thirdid=='helmets'">
					<h3 class="mt-5 mb-5 text-center">"GETTING THE RIGHT FIT FOR YOUR HEAD IS THE ONE THING <br>
					THAT MAKES ALL THE DIFFERENCE IN RIDING EXPERIENCE"</h3>
				</ng-container>
				<ng-container *ngIf="thirdid!='helmets'">
					<div style="height: 100px;"></div>
				</ng-container>
				<div *ngIf="nosizes == false">
					<h2 class="sec-title2 text-center">SIZES</h2>
					<div class="row">
						<div class="col-lg-10 col-md12 offset-lg-1">
							<div class="row align-items-center justify-content-center">
								<div class="col-md-4" *ngFor="let size of productsizes">
									<div class="size-single">
										<img [src]="image" alt="{{ alt_tag }}">
										<div class="size-desc">
											<h3 class="size-name">{{size.name}}</h3>
											<h4 class="product-size">{{size.value}}</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="prod-det zindex1" *ngIf="featuresempty">
			<div class="container">
				<h2 class="sec-title2 text-center mb-4">PRODUCT DETAILS</h2>
				<hr style="background: #fff; margin-bottom: 2em">
				<div class="row pro-det-wrap text-center align-items-end justify-content-center">
					<div class="col-md-4 col-sm-6" *ngFor="let ft of features">
						<div>
							<img [src]="ft.icons"  alt="{{ ft.alt_tag }}">
							<h4 class="det-title">{{ft.name}}</h4>
						</div>
						<p class="prod-catchline" *ngIf="ft.description!=''">{{ft.description}}</p>
					</div>
				</div>
			</div>
		</section>
	
		<section class="access-sec text-center zindex1" style="padding-top: 50px;" *ngIf="showAccess">
			<div class="container">
				<h2 class="sec-title wow slideInDown">MOTORCYCLE ACCESSORIESs</h2>
				<div class="row align-items-center justify-content-center">
					<ng-container *ngFor="let acc of motoAccess">
                        <div class="col-lg-4 col-md-6 col-12 wow bounceIn" data-wow-delay="100ms">
                            <img [src]="acc.icons" class="access-icon" alt="{{acc.name}}">
                            <div class="access-single">
                            <a href="{{acc.purl}}/{{acc.url}}">{{acc.name}}</a>
                            </div>
                        </div>
                    </ng-container>
				</div>
			</div>
		</section>
		
		<section class="review" #target>
			<div class="container position-relative zindex1">
				<div class="row">
					<div class="col-lg-6 offset-lg-3 text-center">
						<h2 class="sec-title wow slideInDown text-white" style="font-size: 2.8em;"> Customer Reviews</h2>
						<form [formGroup]="reviewForm" (ngSubmit)="reviewSubmit()">
							<div class="ratings">
								<input type="radio" id="star5" class="star" formControlName="rating" name="rating" value="100"><label for="star5" class="fa fa-star star"></label>
								<input type="radio" id="star4" class="star" formControlName="rating" name="rating" value="80"><label for="star4" class="fa fa-star star"></label>
								<input type="radio" id="star3" class="star" formControlName="rating" name="rating" value="60"><label for="star3" class="fa fa-star star"></label>
								<input type="radio" id="star2" class="star" formControlName="rating" name="rating" value="40"><label for="star2" class="fa fa-star star"></label>
								<input type="radio" id="star1" class="star" formControlName="rating" name="rating" value="20" checked><label for="star1" class="fa fa-star star"></label>
							</div>
							<p *ngIf="emptyFormStar" class="error-m">Please also give star rating</p>
							<div class="review-form">
								<div class="col-lg-12 p-0">
									<input formControlName="title" class="form-control" placeholder="Name" style="font-family: sans-serif;">
									<p *ngIf="emptyFormTitle" class="error-m">Please also write your Name</p>
									<br>
									<input formControlName="email" class="form-control" placeholder="Email" style="font-family: sans-serif;">
									<p *ngIf="emptyFormEmail" class="error-m">Please also write your Email</p>
									<br>
									<input formControlName="mobile" class="form-control" placeholder="Mobile" style="font-family: sans-serif;">
									<p *ngIf="emptyFormMobile" class="error-m">Please also write your Mobile</p>
									<br>
									<textarea formControlName="message" class="form-control" placeholder="Write a Review" style="font-family: sans-serif;"></textarea>
									<p *ngIf="emptyForm" class="error-m">Please also write your review</p>
								</div>
								<div class="col-lg-12 p-0 text-center my-4">
									<button type="submit" class="btn btn-special">SUBMIT</button>
									<div style="margin-left:15px" class="submit-loader text-center" *ngIf="loading"><span></span></div>
								</div>
								<span style="margin-left: 15px;margin-bottom:15px" class="alert alert-danger dngr" *ngIf="serverError">Some Error Occured!!</span>
								<span style="margin-left: 15px;margin-bottom:15px" class="alert alert-success" *ngIf="reviewSuccess">Your review has been submitted successfully.</span>
							</div>
						</form>
						<ul class="review-list" style="font-size: 16px;" *ngIf="reviewCount!=0">
							<ng-container *ngFor="let review of reviewData | paginate: { itemsPerPage: 10, currentPage: p }">
								<li>
									<!-- <p>{{ review.title }}</p>  -->
									<div class="overall-rating"><span [ngStyle]="{'width': review.rating+'%'}"></span></div>
									<span class="ml-3"><i class="fa fa-calendar mr-2"></i>{{review.date}}</span>
									<p class="review-desc"> {{ review.message }} </p>
								</li>
							</ng-container>
							<pagination-controls (pageChange)="p = $event" class="my-pagination" style="text-transform: capitalize;"></pagination-controls>
						</ul>
					</div>
				</div>
			</div>
		</section>
	</div>
	<!-- <button class="floating-know" (click)="openKnow()">Know Your Helmet Size</button> -->
	<button class="vid-trigger3" (click)="openKnow()">
		<svg height="64" width="140">
            <path d="M62,31 a30,30 1 1,0 -30,30 l100 0" stroke-dasharray="240px" stroke-dashoffset="240px" stroke="#0fd1cf" stroke-width="2" fill="none" />
        </svg>
		<img src="assets/images/studdsbuttonbig2.png"><span>Know Your Helmet Size</span>
	</button>
	<div class="know-popup" id="know-popup">
		<div class="popup-inner">
			<h4 class="pop-head">How to choose the right helmet size?</h4>
			<p class="pop-desc">It is important to choose a helmet that will fit you perfectly. Check out the video to know how to measure your head size and check if the helmet fit comfortably on your head.</p>
			<div class="popwrap">
				<iframe width="100%" height="300" src="https://www.youtube.com/embed/SKhxxlnvmE0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
			<span class="fa fa-times" (click)="closeKnow()"></span>
		</div>
	</div>