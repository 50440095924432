import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import {NgxPaginationModule} from 'ngx-pagination';

@Component({
  selector: 'app-corporate-media',
  templateUrl: './corporate-media.component.html',
  styleUrls: ['./corporate-media.component.css']
})
export class CorporateMediaComponent implements OnInit {
  media: any;
  mediaYears: any;
  currentYear: any;
  currentMedia: any;
  mediaLoader: boolean = false;
  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  mediaLoader2: boolean = false;
  firstcount:number;
  p: number = 1;
  constructor(location: Location, private router: Router, private data: ApiService) {
    router.events.subscribe((val) => {
      if(location.path() == ''){
        this.url1 = true;
      } else {
        this.url1 = false;
      }
    });
    this.href = location.path();
    this.pathCount = this.href.split('/').length - 1;
  }

  ngOnInit() {
    this.mediaLoader2 = true;
    if(this.pathCount==1) {
      this.href= this.href.substr(1);
      this.data.getmetadatabypage(this.href).subscribe(data=> {
        this.titles = data;
        if(this.titles.length>0) {
          this.pageTitle=this.titles[0].pagetitle;
          this.pageDescription=this.titles[0].pagedescription;
        }
      })
    }
    this.data.getmedia().subscribe(data=> {
      this.media = data;
      this.media.media.forEach(single => {
        single.picture = this.data.baseImageUrl+single.picture;
      });
      this.mediaYears = this.media.years;
      this.currentYear = this.mediaYears[0].year;
      this.data.getmediabyyear(this.currentYear).subscribe(data=>{
        this.currentMedia = data;
        //console.log(this.currentMedia.media);
        this.currentMedia = this.currentMedia.media;
        var mediaCount = this.currentMedia.length;
        var i =0;

        this.firstcount = 3;
        for(i=0;i< mediaCount;i++){
          //console.log(this.currentMedia[i].date);
          this.currentMedia[i].picture = this.data.baseImageUrl + this.currentMedia[i].picture;
          const months = ["January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"];
          let current_datetime = new Date(this.currentMedia[i].date); 
          let formatted_date = current_datetime.getDate() + " " + months[current_datetime.getMonth()] + " " + current_datetime.getFullYear();
          this.currentMedia[i].date = formatted_date;
        }
         //console.log(this.currentMedia);
      })
      this.mediaLoader2 = false;
    })
  }

  getmedia(year: any, i: number) {
    this.mediaLoader = true;
    this.data.getmediabyyear(year).subscribe(data=>{
     
      this.currentMedia = data;
      this.currentMedia = this.currentMedia.media;
      this.currentMedia.forEach(single=>{
        single.picture = this.data.baseImageUrl + single.picture;
        const months = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        let current_datetime = new Date(single.date); 
        let formatted_date = current_datetime.getDate() + " " + months[current_datetime.getMonth()] + " " + current_datetime.getFullYear();
        single.date = formatted_date;
      })
      this.mediaLoader = false;
    })
    let yearList = document.querySelectorAll('.year-list > li');
    yearList.forEach(single=>{
      single.classList.remove('active');
    })
    yearList[i].classList.add('active');
  }

  openPop():void{
    let vid = <HTMLIFrameElement> document.getElementById('popvid');
    // vid.src += "&autoplay=1";
    document.getElementById('vidpop').classList.add('opened');
  }
  closePop():void{
    let vid = <HTMLIFrameElement> document.getElementById('popvid');
    vid.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
    // vid.src = vid.src.substring(0, vid.src.length-11);
    document.getElementById('vidpop').classList.remove('opened');
  }
 
}
