import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';


@Component({
  selector: 'app-csractivities',
  templateUrl: './csractivities.component.html',
  styleUrls: ['./csractivities.component.css']
})
export class CSRActivitiesComponent implements OnInit {
  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  mediaLoader: boolean = false;
  detail:any;
  constructor(location: Location, private router: Router, private data: ApiService) {
    router.events.subscribe((val) => {
      if(location.path() == ''){
        this.url1 = true;
      } else {
        this.url1 = false;
      }
    });
    this.href = location.path();
    this.pathCount = this.href.split('/').length - 1;
  }
  csrArray: any;
  ngOnInit() {
    this.mediaLoader = true;
    if(this.pathCount==1) {
      this.href= this.href.substr(1);
      this.data.getmetadatabypage(this.href).subscribe(data=> {
        this.titles = data;
        if(this.titles.length>0) {
          this.pageTitle=this.titles[0].pagetitle;
          this.pageDescription=this.titles[0].pagedescription;
        }
      })
    }
    this.data.getCSR().subscribe(data=> {
      this.csrArray = data;
      this.csrArray = Object.keys( this.csrArray).map(key =>  this.csrArray[key])
      this.csrArray.forEach(single => {
        single.content[0].imglinks = this.data.baseImageUrl+single.content[0].imglinks;
      });
      this.mediaLoader = false;
      //console.log(this.csrArray);
    })
  }

  toggleInvesters(eve , id){
    let allnavs = document.querySelectorAll('.investor-nav > li');
    let allinvesters = document.querySelectorAll('.investor-details');
    allinvesters.forEach(element => {
      element.setAttribute('style','display: none');
      element.classList.remove('opened');
    });
    document.getElementById(id).style.display = 'block';
    document.getElementById(id).classList.add('opened');
    allnavs.forEach(element => {
      element.classList.remove('active');
    });
    eve.target.classList.add('active');
  }

  openDetails(event: any, Details: any) {
    this.detail = Details;
    document.getElementById('details').innerHTML = this.detail.description;
    document.getElementById('heading').innerHTML = this.detail.imgtitle;
    document.getElementById('csrimage').setAttribute( 'src', this.detail.imglinks);
    document.getElementsByClassName('careerdetailpopup')[0].classList.add('active');
  }
  closeDetails(){
    document.getElementsByClassName('careerdetailpopup')[0].classList.remove('active');
  }

  openPop():void{
    let vid = <HTMLIFrameElement> document.getElementById('popvid');
    // vid.src += "&autoplay=1";
    document.getElementById('vidpop').classList.add('opened');
  }
  closePop():void{
    let vid = <HTMLIFrameElement> document.getElementById('popvid');
    vid.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
    // vid.src = vid.src.substring(0, vid.src.length-11);
    document.getElementById('vidpop').classList.remove('opened');
  }

}

