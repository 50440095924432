import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { AgmCoreModule, AgmInfoWindow } from '@agm/core';

@Component({
  selector: 'app-manufacturing-facility',
  templateUrl: './manufacturing-facility.component.html',
  styleUrls: ['./manufacturing-facility.component.css']
})
export class ManufacturingFacilityComponent implements OnInit {
  manufacturers: any;
  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  mediaLoader: boolean = false;
  map:any;
  zoom:any;
  constructor(location: Location, private router: Router, private data: ApiService,private gmaps: AgmCoreModule,) {
    router.events.subscribe((val) => {
      if(location.path() == ''){
        this.url1 = true;
      } else {
        this.url1 = false;
      }
    });
    this.href = location.path();
    this.pathCount = this.href.split('/').length - 1;
  }

  ngOnInit() {
    this.mediaLoader = true;
    if(this.pathCount==1) {
      this.href= this.href.substr(1);
      this.data.getmetadatabypage(this.href).subscribe(data=> {
        this.titles = data;
        if(this.titles.length>0) {
          this.pageTitle=this.titles[0].pagetitle;
          this.pageDescription=this.titles[0].pagedescription;
        }
      })
    }
    this.data.getallmanufacturer().subscribe(data=> {
      this.manufacturers = data;
      this.zoom = 13;
      this.manufacturers.forEach(single=>{
        single.phoneUrl = single.phone.replace(/ /g,'');
        if (this.map){
          this.map.setCenter({ lat: +single.lattitude, lng: +single.longitude  });
          this.zoom = 10;
        }
        //console.log(typeof(+single.lattitude));
        //console.log(+single.lattitude);
        //console.log(typeof(+single.longitude));
        //console.log(+single.longitude);
      })
      this.mediaLoader = false;
    })
  }

  openPop():void{
    let vid = <HTMLVideoElement> document.getElementById('popvid');
    // vid.play();
    document.getElementById('vidpop').classList.add('opened');
  }
  closePop():void{
    let vid = <HTMLVideoElement> document.getElementById('popvid');
    vid.pause();
    document.getElementById('vidpop').classList.remove('opened');
  }

  public mapReady(map) {
    this.map = map;
  }

}
