<section class="main-banner " id="scene">
    <div class="banner-slider-wrap" style="padding-top: 76px" *ngIf="mediaLoader==false">
        <div class="banner-extra">
            <ul class="banner-social">
                    <ng-container *ngFor="let social of socialLink">
                        <li *ngIf="social.type=='instagram'"><a target="_blank" [href]="social.value"><i class="fa fa-instagram"></i></a></li>
                        <li *ngIf="social.type=='facebook'"><a target="_blank" [href]="social.value"><i class="fa fa-facebook"></i></a></li>
                        <li *ngIf="social.type=='twitter'"><a target="_blank" [href]="social.value"><i class="fa fa-twitter"></i></a></li>
                        <li *ngIf="social.type=='youtube'"><a target="_blank" [href]="social.value"><i class="fa fa-youtube-play"></i></a></li>
                    </ng-container>
            </ul>
        </div>
        <div class="banner-slider sliderVdots" *ngIf="resized">
            <ng-container *ngIf="desktop==true">
                <owl-carousel-o #bannerSlide [options]="bannerOptions">
                    <ng-container *ngFor="let banner of banners; let i=index;">
                        <ng-template carouselSlide [id]="i">
                            <div class="item">
                                <div class="container-fluid p-0">
                                    <div class="row align-items-center">
                                         <a [href]="banner.btn_url"><img [src]="banner.banner" class="img-fluid" alt="{{ banner.banner_alt_tag}}"></a>
                                        <a [href]="banner.btn_url" class="btn more-btn btn-special">View More</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-container>
            <ng-container *ngIf="desktop==false">
                <owl-carousel-o #bannerSlide [options]="bannerOptions">
                    <ng-container *ngFor="let banner of banners; let i=index;">
                        <ng-template carouselSlide [id]="i">
                            <div class="item">
                                <div class="container-fluid p-0">
                                    <div class="row align-items-center">
                                        <a [href]="banner.btn_url"><img [src]="banner.m_banner" class="img-fluid"  alt="{{ banner.m_banner_alt_tag}}"></a>
                                        <a [href]="banner.btn_url" class="btn more-btn btn-special">View More</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-container>
        </div>
    </div>
    <a href="javascript:void(0)" class="jumptonext"><span></span></a>
</section>
<section class="main-banner " id="scene">
    <div class="banner-slider-wrap" style="padding-top: 0" *ngIf="mediaLoader==false">
        <div class="banner-slider sliderVdots" *ngIf="resized">
            <ng-container>
                <div class="item">
                    <div class="container-fluid p-0">
                        <div class="row align-items-center">
                            <div class="banner-vid w-100 position-relative">
                                <video id="b_vid" loop playsinline autoplay [muted]="true" poster="assets/images/video-poster.jpg">
                                    <source src="assets/videos/banner-1-video.mp4" type="video/mp4">
                                </video>
                                <div class="vidControls">
                                    <span (click)="mute()" *ngIf="vidMuted"><img src="assets/images/unmute_voice.svg" ></span>
                                    <span (click)="unmute()" *ngIf="!vidMuted"><img src="assets/images/mute_voice.svg" ></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    
</section>

<div class="popup">
    <div class="popup-inner">
        <div class="custom-video p-0">
            <div id="videobanner1" style="width: 100%; height: 600px; max-height: 85vh;">
            </div>
        </div>
        <span class="close-p"><i class="fa fa-times"></i></span>
    </div>
</div>
<div class="popup2">
    <div class="popup2-inner">
        <div class="custom-video p-0">
            <div class="youtube-embed" data-embed="-DKZcCRQKYE" style="width: 100%; height: 600px; max-height: 85vh;">

            </div>
            <!-- <iframe width="100%" height="600" src="https://www.youtube.com/embed/-DKZcCRQKYE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
        </div>
        <span class="close-p2"><i class="fa fa-times"></i></span>
    </div>
</div>
<div class="popup3">
    <div class="popup3-inner">
        <div class="custom-video p-0">
            <div class="youtube-embed" data-embed="f0Lkl7XIS_w" style="width: 100%; height: 600px; max-height: 85vh;">

            </div>
            <!-- <iframe width="100%" height="600px" src="https://www.youtube.com/embed/f0Lkl7XIS_w" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
        </div>
        <span class="close-p3"><i class="fa fa-times"></i></span>
    </div>
</div>
<section class="below-banner pt-5 pb-4">
    <div class="container text-center ">
        <div class="row">
            <div class="col-lg-10 offset-lg-1">
                <h1 class="sec-title text-white mb-3" *ngIf="pageTitle != ''">{{pageTitle}}</h1>
                <p style="font-family: 'Roboto', sans-serif; text-align: justify; color: #fff; font-size: 1.1em; margin-top: 0; margin-bottom: 1.2em;" [innerHtml]="pageDescription"></p>
            </div>
        </div>
    </div>
</section>
<section class="special-month" id="scene"  *ngIf="resized">
    <div class="container" data-depth="1">
        <owl-carousel-o #bannerSlide [options]="helmetSlider">
            <ng-container *ngFor="let hel of helmetOfTheMonth; let i=index;">
                <ng-template carouselSlide [id]="i">
                    <div class="row align-items-center">
                        <div class="col-md-6 p-0 wow slideInLeft">
                            <img [src]="hel.mainpicture" class="img-fluid" alt="{{hel.alt_tag}}">
                        </div>
                        <div class="col-md-6 p-0 text-center wow slideInRight">
                            <h3 class="special-month-title">{{hel.title}}</h3>
                            <h4 class="special-month-text wow flash" data-wow-delay="1000ms" data-wow-duration="300ms" data-wow-iteration="2">{{hel.image_below_title}}</h4>
                            <div class="text-center">
                                <a [href]="hel.link" class="btn btn-special wow bounceIn" data-wow-delay="1000ms">View Helmet</a>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
        <!-- <div class="row align-items-center" *ngFor="let hel of helmetOfTheMonth | slice:0:1">
            <div class="col-md-6 p-0 wow slideInLeft">
                <img [src]="hel.mainpicture" class="img-fluid" alt="{{hel.title}}">
            </div>
            <div class="col-md-6 p-0 text-center wow slideInRight">
                <h3 class="special-month-title">{{hel.title}}</h3>
                <h4 class="special-month-text wow flash" data-wow-delay="1000ms" data-wow-duration="300ms" data-wow-iteration="2">{{hel.image_below_title}}</h4>
                <div class="text-center">
                    <a [href]="hel.link" class="btn btn-special wow bounceIn" data-wow-delay="1000ms">View Helmet</a>
                </div>
            </div>
        </div> -->
    </div>
</section>
<div class="main-artboard position-relative">
    <div class="floating-rays home-floating">
        <img srcset="
        assets/images/big-studd-xl.png 1920w,
        assets/images/big-studd.png 1366w
    " 
     src="assets/images/big-studd.png" class="home-ray0" alt="STUDDS Floating Logo">
    </div>
    <section class="home-cats zindex1" *ngIf="homeloaded == true">
        <div class="container-fluid custom-container position-relative">
            <div class="row">
                <div class="col-md-10 offset-md-1 text-center single-cat main-cat">
                    <div class="home-cat-wrap">
                        <div class="home-cat-inner wow rotateIn">
                            <img src="assets/images/behind-circle.png" class="behind-circle" alt="circle">
                            <img src="assets/images/openface-circle.png" class="glow-circle" alt="openface circle">
                            <img src="assets/images/openface-text.png" class="cat-name" id="openface" alt="openface text">
                        </div>
                        <div class="center-helmet">
                            <img [src]="homeImage5" alt="Open Face Helmet" class="lazyImg center-helmet-img wow zoomIn" data-wow-delay="200ms" data-wow-duration="500ms">
                        </div>
                        <div class="varient-button">
                            <a href="helmets/open-face-helmet" class="btn wow zoomIn" data-wow-delay="200ms"><img src="assets/images/varient-button.png" class="img-fluid" alt="varient button"></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 text-center single-cat d-none d-md-block" data-url="helmets/flip-up-full-face-helmet">
                    <div class="home-cat-wrap">
                        <div class="home-cat-inner wow rotateIn" data-wow-delay="300ms">
                            <img src="assets/images/flipup-circle.png" class="glow-circle" alt="flipup circle">
                            <img src="assets/images/flipup-text.png" class="cat-name" id="flipup" alt="flipup text">
                        </div>
                        <div class="center-helmet">
                            <img [src]="homeImage2" alt="Flip Up Full Face Helmet" class="center-helmet-img wow zoomIn" data-wow-delay="350ms">
                        </div>
                    </div>
                </div>
                <div class="col-md-3 text-center single-cat d-none d-md-block" data-url="helmets/flip-off-full-face-helmet">
                    <div class="home-cat-wrap">
                        <div class="home-cat-inner wow rotateIn" data-wow-delay="600ms">
                            <img src="assets/images/flipoff-circle.png" class="glow-circle" alt="flipoff circle">
                            <img src="assets/images/flipoff-text.png" class="cat-name" id="flipoff" alt="flipoff text">
                        </div>
                        <div class="center-helmet">
                            <img [src]="homeImage3" alt="Flip Off full face helmet" class="center-helmet-img wow zoomIn" data-wow-delay="650ms">
                        </div>
                    </div>
                </div>
                <div class="col-md-3 text-center single-cat d-none d-md-block" data-url="helmets/sporting-helmet">
                    <!-- <div class="home-cat-wrap">
                        <div class="home-cat-inner wow rotateIn" data-wow-delay="900ms">
                            <img src="assets/images/sporting-circle.png" class="glow-circle" alt="sporting circle">
                            <img src="assets/images/sporting-text.png" class="cat-name" id="sporting" alt="sporting">
                        </div>
                        <div class="center-helmet">
                            <img [src]="homeImage6" alt="Sporting Helmet" class="center-helmet-img wow zoomIn" data-wow-delay="950ms">
                        </div>
                    </div> -->
                </div>
                <div class="col-md-3 text-center single-cat d-none d-md-block" data-url="helmets/off-road-full-face-helmet">
                    <div class="home-cat-wrap">
                        <div class="home-cat-inner wow rotateIn" data-wow-delay="1200ms">
                            <img src="assets/images/flipup-circle.png" class="glow-circle" alt="flipup circle">
                            <img src="assets/images/offroad-text.png" class="cat-name" id="offroad" alt="offroad text">
                        </div>
                        <div class="center-helmet">
                            <img [src]="homeImage4" alt="Off Road Full Face Helmet" class="center-helmet-img wow zoomIn" data-wow-delay="1250ms">
                        </div>
                    </div>
                </div>
                <div class="col-md-3 text-center single-cat d-none d-md-block" data-url="helmets/full-face-helmet">
                    <div class="home-cat-wrap">
                        <div class="home-cat-inner wow rotateIn" data-wow-delay="1500ms">
                            <img src="assets/images/openface-circle.png" class="glow-circle" alt="openface circle">
                            <img src="assets/images/fullface-text.png" class="cat-name" id="fullface" alt="fullface text">
                        </div>
                        <div class="center-helmet">
                            <img [src]="homeImage1" alt="Full Face Helmet" class="center-helmet-img wow zoomIn" data-wow-delay="1550ms">
                        </div>
                    </div>
                </div>
                <div id="cat-slide-owl" class="owl-carousel cat-slider-phone row mx-0 flex-nowrap d-flex d-md-none">
                    <div class="col-md-3 text-center single-cat" data-url="helmets/flip-up-full-face-helmet">
                        <div class="home-cat-wrap">
                            <div class="home-cat-inner wow rotateIn" data-wow-delay="300ms">
                                <img src="assets/images/flipup-circle.png" class="glow-circle" alt="flipup circle">
                                <img src="assets/images/flipup-text.png" class="cat-name" id="flipup" alt="flipup text">
                            </div>
                            <div class="center-helmet">
                                <img [src]="homeImage2" alt="Flip Up Full Face Helmet" class="center-helmet-img wow zoomIn" data-wow-delay="350ms">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 text-center single-cat" data-url="helmets/flip-off-full-face-helmet">
                        <div class="home-cat-wrap">
                            <div class="home-cat-inner wow rotateIn" data-wow-delay="600ms">
                                <img src="assets/images/flipoff-circle.png" class="glow-circle" alt="flipoff circle">
                                <img src="assets/images/flipoff-text.png" class="cat-name" id="flipoff" alt="flipoff text">
                            </div>
                            <div class="center-helmet">
                                <img [src]="homeImage3" alt="Flip Off Full Face Helmet" class="center-helmet-img wow zoomIn" data-wow-delay="650ms">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 text-center single-cat" data-url="helmets/sporting-helmet">
                        <div class="home-cat-wrap">
                            <div class="home-cat-inner wow rotateIn" data-wow-delay="900ms">
                                <img src="assets/images/sporting-circle.png" class="glow-circle" alt="sporting circle">
                                <img src="assets/images/sporting-text.png" class="cat-name" id="sporting" alt="sporting text">
                            </div>
                            <div class="center-helmet">
                                <img [src]="homeImage6" alt="Sporting Helmet" class="center-helmet-img wow zoomIn" data-wow-delay="950ms">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 text-center single-cat" data-url="helmets/off-road-full-face-helmet">
                        <div class="home-cat-wrap">
                            <div class="home-cat-inner wow rotateIn" data-wow-delay="1200ms">
                                <img src="assets/images/flipup-circle.png" class="glow-circle" alt="flipup circle">
                                <img src="assets/images/offroad-text.png" class="cat-name" id="offroad" alt="offroad text">
                            </div>
                            <div class="center-helmet">
                                <img [src]="homeImage4" alt="Off Road Full Face Helmet" class="center-helmet-img wow zoomIn" data-wow-delay="1250ms">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 text-center single-cat" data-url="helmets/full-face-helmet">
                        <div class="home-cat-wrap">
                            <div class="home-cat-inner wow rotateIn" data-wow-delay="1500ms">
                                <img src="assets/images/openface-circle.png" class="glow-circle" alt="open face circle">
                                <img src="assets/images/fullface-text.png" class="cat-name" alt="full face text" id="fullface">
                            </div>
                            <div class="center-helmet">
                                <img [src]="homeImage1" alt="Full Face Helmet" class="center-helmet-img wow zoomIn" data-wow-delay="1550ms">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="prod-var-nav d-block d-md-none text-center" style="width: 100%; margin-top: 20px;">
                    <button class="prod-var-prev2"><i class="fa fa-chevron-left"></i></button>
                    <button class="prod-var-next2"><i class="fa fa-chevron-right"></i></button>
                </div>
            </div>
        </div>
    </section>
    <section class="access-sec text-center zindex1">
        
        <div class="container">
            <h2 class="sec-title wow slideInDown">MOTORCYCLE ACCESSORIES</h2>
            <div class="row align-items-center justify-content-center">
                <ng-container *ngFor="let acc of motoAccess">
                    <div class="col-lg-4 col-md-6 col-12" data-wow-delay="100ms">
                        <img [src]="acc.icons" class="access-icon" alt="{{acc.icon_alt_tag}}">
                        <div class="access-single">
                        <a href="{{acc.purl}}/{{acc.url}}">{{acc.name}}</a>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </section>
    <section class="gallery-sec zindex1">
        <div class="container position-relative zindex1">
            <h2 class="sec-title text-center position-relative zindex1">Gallery</h2>
            <p class="position-relative zindex1" style="margin: 1em 0 2em; font-size: 1em; color: #fff; font-family: roboto; text-align: center;">To know more, Watch our product videos.</p>
            <div class="row">
                <div class="col-sm-12 wow zoomIn position-relative" data-wow-duration=".6s">
                    <div class="gallery-clock">
                        <img src="assets/images/clock3.png" class="img-fluid" alt="clock3">
                    </div>
                    <div class="gallery-slider" *ngIf="resized">
                        <owl-carousel-o [options]="galleryOptions" (translated)="getPassedData($event)">
                            <ng-container *ngFor="let gal of videoGallery; let i=index;">
                                <ng-template carouselSlide [id]="i">
                                    <div class="item" id="i">
                                        <video id="v1" class="vid-fluid" autoplay="i==0?'true':'false'" controls muted [muted]="true" preload="auto" playsinline="true">
                                            <source [src]="gal.video_src" type="video/mp4">
                                        </video>
                                        <h4 class="text-center">{{gal.title}}</h4>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="helmet-sec pt-4 zindex1">
        <h2 class="sec-title text-center mb-0">STANDOUT FEATURES</h2>
        <div class="helmet-slider owl-carousel">
            <div class="helmet-single bottom-helmet">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-5 col-lg-6 offset-lg-5">
                            <div class="helmet-single-inner">
                                <div class="helmet-back-clock">
                                    <img src="assets/images/clock1.png" class="img-fluid" alt="clock1">
                                </div>
                                <div class="helmet-img">
                                    <div class="helmet-svgs">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="710" height="180" viewBox="0 0 710 180">
                                            <path class="helmet-svg1" fill="none" stroke="white" stroke-dasharray="800px" stroke-dashoffset="800px" stroke-width="2px" fill-rule="evenodd" opacity="1" d="M 0 150 l 150 0 s 150 -200 400 0 l 150 0" transform="translate(0,-30)"></path>
                                            <path class="helmet-svg2" fill="none" stroke="white" stroke-dasharray="800px" stroke-dashoffset="800px" stroke-width="2px" fill-rule="evenodd" opacity="1" d="M 20 150 l 130 0 s 150 -200 400 0 l 130 0" transform="translate(0,-15)"></path>
                                            <path class="helmet-svg3" fill="none" stroke="#7b512d" stroke-dasharray="800px" stroke-dashoffset="800px" stroke-width="2px" fill-rule="evenodd" opacity="1" d="M 40 150 l 110 0 s 150 -200 400 0 l 110 0"></path>
                                        </svg>
                                    </div>
                                    <img srcset="
                                            assets/images/aerodynamic.png 1920w,
                                            assets/images/aerodynamic-sm.png 1366w
                                        " 
                                        src="assets/images/aerodynamic.png" alt="Aerodynamic">
                                </div>
                                <h3 class="helmet-func-name">AERODYNAMIC</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="helmet-single bottom-helmet">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-5 col-lg-6 offset-lg-5">
                            <div class="helmet-single-inner">
                                <div class="helmet-back-clock">
                                    <img src="assets/images/clock1.png" class="img-fluid" alt="clock1">
                                </div>
                                <div class="helmet-img">
                                    <div class="helmet-arrows">
                                        <img src="assets/images/ventilation-arrow1.png" class="arrow1" alt="arrow1">
                                        <img src="assets/images/ventilation-arrow2.png" class="arrow2" alt="arrow1">
                                    </div>
                                    <img srcset="
                                        assets/images/aerodynamic.png 1920w,
                                        assets/images/aerodynamic-sm.png 1366w
                                "  src="assets/images/aerodynamic-sm.png" class="img-fluid" alt="Dynamic Ventilation">
                                </div>
                                <h2 class="helmet-func-name">DYNAMIC<br>VENTILATION</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="helmet-single bottom-helmet">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-5 col-lg-6 offset-lg-5">
                            <div class="helmet-single-inner">
                                <div class="helmet-back-clock">
                                    <img src="assets/images/clock1.png" class="img-fluid" alt="clock1">
                                </div>
                                <div class="helmet-img">
                                    <div class="dual-visor-anim">
                                        <img srcset="
                                        assets/images/anim-visor-xl.png 1920w,
                                        assets/images/anim-visor.png 1366w
                                    " 
                                     src="assets/images/anim-visor.png" class="anim-visor" title="inner Visor" alt="Dual Visor">
                                        <img srcset="
                                            assets/images/dual-visor.png 1920w,
                                            assets/images/dual-visor-md.png 1600w,
                                            assets/images/dual-visor-sm.png 1366w
                                        " 
                                        src="assets/images/dual-visor.png" class="img-fluid" alt="dualvisor">
                                        <img srcset="
                                        assets/images/anim-glass-xl.png 1920w,
                                        assets/images/anim-glass.png 1366w
                                    " 
                                     src="assets/images/anim-glass.png" class="anim-glass" title="Outer Visor" alt="Dual Visor">
                                    </div>
                                </div>
                                <h2 class="helmet-func-name">Dual Visor</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="homeblogs mb-5">
        <div class="container position-relative zindex1 text-center">
            <h2 class="sec-title text-white mb-4">Blogs</h2>
            <div class="homeblog-slider" *ngIf="resized">
                <owl-carousel-o [options]="blogOptions">
                    <ng-container *ngFor="let blog of homeBlogs | slice:1:5; let i=index;">
                        <ng-template carouselSlide [id]="i">
                            <div class="item" id="i">
                                <img [src]="blog.thumbnail" class="img-fluid mb-3" alt="{{blog.thumb_alt_tag}}">
                                <div class="blog-desc">
                                    <h3 class="blog-title">{{blog.title}}</h3>
                                    <p class="blog-meta"><i class="fa fa-calendar mr-2"></i>{{ blog.blog_date }}</p>
                                    <a class="btn btn-special" [href]="blog.url">Read More</a>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </section>
    <section class="dealer-sec zindex1">
        <div class="floating-rays">
            <img src="assets/images/helmet-divider.png" class="dealerbg" alt="helmet-driver">	
        </div>
        <div class="container position-relative">
            <h2 class="sec-title text-center mb-4 m-lg-0">DEALER LOCATOR</h2>
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="dealer-form text-center">
                        <select class="form-control" #ct (change)="getstates(ct.value)">
                            <option value="">Select Country</option>
                            <option  *ngFor="let ct of country" [value]="ct.country">{{ct.country | titlecase}}</option>
                        </select>
                        <select class="form-control" #st (change)="getcities(st.value)">
                            <option value="">Select State</option>
                            <option  *ngFor="let st of states" [value]="st.state">{{st.state | titlecase}}</option>
                        </select>
                        <select class="form-control" #ct (change)="getaddress(ct.value,st.value)" *ngIf="cityFound">
                            <option value="">Select City</option>
                            <option *ngFor="let ct of city" [value]="ct.city">{{ct.city | titlecase}}</option>
                        </select>
                        <select class="form-control" *ngIf="cityNotFound">
                            <option value="">Select City</option>
                            <option value="">No Dealers Found</option>
                        </select>
                        <div class="map-loader text-center" *ngIf="mapdataRecieved">
                            <span></span>
                        </div>
                        <div  *ngIf="mapLoad ===  true" class="map-info-wrapper map-text" [ngStyle]="{'opacity':mapLoad === true ? '1' : '0' }">
                            <div (click)="clickedMarker('b',add.id)" style="cursor: pointer" class="map-info-single-text" *ngFor="let add of address">
                                <h4>{{add.name}}</h4>
                                <p>{{add.add1}}, {{add.add2}}, {{add.city}}, {{add.state}}, {{add.zipcode}}</p>
                                <p *ngIf="add.email!=''"><strong>Email Id: </strong><a href="mailto:{{add.email}}">{{add.email}}</a></p>
                                <p><strong>Phone No: </strong><a href="tel:{{add.phone1}}">{{add.phone1}}</a><span *ngIf="add.phone2!=''">, </span> <a href="tel:{{add.phone2}}">{{add.phone2}}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 text-center">
                    <div class="gmap-wrap" [ngStyle]="{'border-radius':mapLoad === true ? '0' : '50%' }">
                        <div class="dealer-anim-wrap" [ngStyle]="{'opacity':mapLoad === true ? '0' : '1' }">
                            <div class="dealer-anim">
                            </div>
                        </div>
                        <div class="map-info-wrapper" [ngStyle]="{'opacity':mapLoad === true ? '1' : '0' }">
                            <div class="map-info-single" *ngIf="mapLoad === true">
                                <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom"  [fitBounds]="true" >
                                    <agm-direction [origin]="origin" [destination]="destination" *ngIf="ifGetDirection == true">
                                    </agm-direction> 
                                    <agm-marker 
                                    *ngFor="let m of address; let i = index"
                                    [latitude]="m.latitude" [longitude]="m.longitude" [agmFitBounds]="true" (markerClick)="clickedMarker(infowindow,m.id)">
                                    <agm-info-window #infowindow [isOpen]="isInfoWindowOpen(m.id)">
                                      <h4>{{m.name}}</h4>
                                      <p>{{m.add1}}, {{m.add2}}, {{m.city}}, {{m.state}}, {{m.zipcode}}</p>
                                      <a target="blank" href="https://www.google.com/maps/dir/?api=1&destination={{m.latitude}},{{m.longitude}}"><button class="btn btn-info btn-xs">Get Directions</button></a>
                                    </agm-info-window>
                                    </agm-marker>
                                </agm-map>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
    
    </div>




    