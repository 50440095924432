import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-safety',
  templateUrl: './safety.component.html',
  styleUrls: ['./safety.component.css']
})
export class SafetyComponent implements OnInit {

  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  constructor(location: Location, private router: Router, private data: ApiService) {
    router.events.subscribe((val) => {
      if(location.path() == ''){
        this.url1 = true;
      } else {
        this.url1 = false;
      }
    });
    this.href = location.path();
    this.pathCount = this.href.split('/').length - 1;
  }

  ngOnInit() {
    if(this.pathCount==1) {
      this.href= this.href.substr(1);
      this.data.getmetadatabypage(this.href).subscribe(data=> {
        this.titles = data;
        if(this.titles.length>0) {
          this.pageTitle=this.titles[0].pagetitle;
          this.pageDescription=this.titles[0].pagedescription;
        }
      })
    }
  }

  openPop():void{
    let vid = <HTMLVideoElement> document.getElementById('popvid');
    // vid.play();
    document.getElementById('vidpop').classList.add('opened');
  }
  closePop():void{
    let vid = <HTMLVideoElement> document.getElementById('popvid');
    vid.pause();
    document.getElementById('vidpop').classList.remove('opened');
  }

}
