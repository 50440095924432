import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-corporate-about',
  templateUrl: './corporate-about.component.html',
  styleUrls: ['./corporate-about.component.css']
})
export class CorporateAboutComponent implements OnInit {

  // Slider Options
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    items: 1,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    center: true,
    margin: 20,
    nav: false
  };
  resized: boolean = true;
  mediaLoader: boolean = false;
  constructor(private data: ApiService) { }
  aboutUs: any;
  aboutUsSections: any;
  aboutUsSlider: any;
  aboutUsSliderTitle: any;
  ngOnInit() {
    this.mediaLoader = true;
    let winWidth = window.innerWidth;
    window.addEventListener('resize',()=>{
      let newWinWidth = window.innerWidth;
      if(winWidth!=newWinWidth){
        winWidth = newWinWidth;
        this.resized = false;
        setTimeout(()=>{
          this.resized = true;
        },300)
      }
    })
    this.data.getAboutUsPage().subscribe(data=>{
      this.aboutUs = data;
      this.aboutUsSections = this.aboutUs.section;
      this.aboutUsSections.forEach(single => {
        single.image = this.data.baseImageUrl+single.image;
      });
      this.aboutUsSlider = this.aboutUs.slider;
      this.aboutUsSlider.forEach(single => {
        single.image = this.data.baseImageUrl+single.image;
      });
      this.aboutUsSliderTitle = this.aboutUsSlider[0].heading;
      this.mediaLoader = false;
    })
  }

  openPop():void{
    let vid = <HTMLVideoElement> document.getElementById('popvid');
    // vid.play();
    document.getElementById('vidpop').classList.add('opened');
  }
  closePop():void{
    let vid = <HTMLVideoElement> document.getElementById('popvid');
    vid.pause();
    document.getElementById('vidpop').classList.remove('opened');
  }

}
