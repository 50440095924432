import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
declare var $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  faqs: any;
  mediaLoader: boolean = false;
  constructor(location: Location, private router: Router, private data: ApiService) {
    router.events.subscribe((val) => {
      if(location.path() == ''){
        this.url1 = true;
      } else {
        this.url1 = false;
      }
    });
    this.href = location.path();
    this.pathCount = this.href.split('/').length - 1;
  }

  ngOnInit() {
    this.mediaLoader = true;
    if(this.pathCount==1) {
      this.href= this.href.substr(1);
      this.data.getmetadatabypage(this.href).subscribe(data=> {
        this.titles = data;
        if(this.titles.length>0) {
          this.pageTitle=this.titles[0].pagetitle;
          this.pageDescription=this.titles[0].pagedescription;
        }
      })
    }
    this.data.getfaq().subscribe(data=>{
      this.faqs = data;
      this.mediaLoader = false;
      this.faqs.forEach(single => {
        single.hashtag = single.hashtag.toLowerCase().replace(/\s+/g, '-');
      });
      console.log(this.faqs);
    })
  }

  toggleFaq(i, hashtag){
    history.pushState({}, null, '/faq#'+hashtag);
    //console.log(i)
    $('.faq-single:not(:nth-child('+(i+1)+')) .faq-body').slideUp(400);
    $('.faq-single:nth-child('+(i+1)+') .faq-body').slideToggle(400);
    if($('.check'+i).hasClass('fa-chevron-down')){
       $('.check'+i).removeClass('fa-chevron-down').addClass('fa-chevron-right');
    }else{
    $('.faq-single:nth-child('+(i+1)+') .fa').removeClass("fa-chevron-right").addClass("fa-chevron-down");
    $('.faq-single:not(:nth-child('+(i+1)+')) .fa').addClass("fa-chevron-right").removeClass("fa-chevron-down");
    }
   
    
  }

}
