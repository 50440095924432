import { Component, OnInit, ViewChild, ViewContainerRef,ComponentFactoryResolver,ComponentRef, ComponentFactory } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmCoreModule, AgmInfoWindow } from '@agm/core';
declare var $: any;

@Component({
  selector: 'app-dealers',
  templateUrl: './dealers.component.html',
  styleUrls: ['./dealers.component.css']
})
export class DealersComponent implements OnInit {
  states: any;
  city: any;
  country: any;
  address: any;
  mapLoad: boolean = false;
  cityFound: boolean = false;
  cityNotFound: boolean = false;
  stateFound: boolean = true;
  stateNotFound: boolean = false;
  mapdataRecieved: boolean = false;
  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  mediaLoader: boolean = false;
  lat:any
  lng:any
  zoom:any
  maphtml:any
  componentRef: any;
  agmFitBounds:boolean = true;
  fitBounds:boolean = true;
  ifGetDirection:any;
  origin:any
  destination:any
  mylat:any;
  mylng:any;
  previous;
 // setCenter:any;
 popupDetails: any;
 protected map: any;

  constructor(location: Location, private router: Router, private data: ApiService,private gmaps: AgmCoreModule,private resolver: ComponentFactoryResolver) {
    router.events.subscribe((val) => {
      if(location.path() == ''){
        this.url1 = true;
      } else {
        this.url1 = false;
      }
    });
    this.href = location.path();
    this.pathCount = this.href.split('/').length - 1;
  }

  ngOnInit() {
    this.mapLoad = false;
   // this.setCurrentLocation();
    this.mediaLoader = true;
    if(this.pathCount==1) {
      this.href= this.href.substr(1);
      this.data.getmetadatabypage(this.href).subscribe(data=> {
        this.titles = data;
        if(this.titles.length>0) {
          this.pageTitle=this.titles[0].pagetitle;
          this.pageDescription=this.titles[0].pagedescription;
        }
      })
    }
    this.data.getcountry().subscribe(data=> {
      this.country = data;
      this.mediaLoader = false;
    })
    this.data.getfaqbyid().subscribe(data=>{
      this.popupDetails = data;
    })
  }

  getstates(ct:any) {
    this.mapdataRecieved = true;
    this.mapLoad = false;
    this.data.getstates(ct).subscribe(data=> {
      this.states = data;
      this.city = '';
      this.cityFound = false;
       //console.log(this.states);
      if(this.states.length>0) {
        this.stateFound = true;
        this.stateNotFound = false;
      }
      if(this.states.length == 0) {
        this.stateNotFound = true;
        this.stateFound = false;
      }
      this.mapdataRecieved = false;
    })
  }

  getcities(st:any) {
    this.mapdataRecieved = true;
    this.data.getcityfromstate(st).subscribe(data=> {
      this.city = data;
      if(this.city.length>0) {
        this.cityFound = true;
        this.cityNotFound = false;
      }
      if(this.city.length == 0) {
        this.cityNotFound = true;
        this.cityFound = false;
      }
      this.mapdataRecieved = false;
    })
  }
  

  getaddress(ct:any,st:any) {
    this.ifGetDirection = false;
    this.mapLoad = true;
    this.mapdataRecieved = true;
    this.data.getaddressfromcity(ct,st).subscribe(data=> {
      
      this.address = data;
      // console.log(this.address);
      this.lat = this.address[0].latitude;
      this.lng = this.address[0].longitude;
     
      this.zoom = 13;
      this.mapdataRecieved = false;
      if(this.address.length == 1){
        this.agmFitBounds = false;
        this.fitBounds = false;
        if (this.map){
          this.map.setCenter({ lat: +this.address[0].latitude, lng: +this.address[0].longitude  });
          this.zoom = 13;
        }
      }else{
        this.agmFitBounds = true;
        this.fitBounds = true;
      }
      this.address.sort((a,b)=>{
        return (a.position - b.position);
      })


    })
    this.agmFitBounds = true;
  }

  public mapReady(map) {
    this.map = map;
  }

 

   //Get Current Location Coordinates
  //  private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       console.log(position)
  //       this.lat = position.coords.latitude;
  //       this.lng = position.coords.longitude;
  //       this.mylat=position.coords.latitude;
  //       this.mylng=position.coords.longitude;
  //       this.zoom = 13;
  //     });
  //   }else console.log('N');
  // }

openedWindow : number = 0; // alternative: array of numbers

  clickedMarker(infowindow, markerid:any) {
    this.openedWindow = markerid;
    if (this.previous) {
        this.previous.close();
    }
    this.previous = infowindow;
 }
 
isInfoWindowOpen(id) {
    return this.openedWindow == id; // alternative: check if id is in array
}
openPopup(): void{
  document.getElementById('detail-popup').classList.add('active');
}
closePopup(): void{
  document.getElementById('detail-popup').classList.remove('active');
}
openPop():void{
  let vid = <HTMLVideoElement> document.getElementById('popvid');
  // vid.play();
  document.getElementById('vidpop').classList.add('opened');
}
closePop():void{
  let vid = <HTMLVideoElement> document.getElementById('popvid');
  vid.pause();
  document.getElementById('vidpop').classList.remove('opened');
}
  
}
