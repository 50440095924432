import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgDynamicBreadcrumbModule} from "ng-dynamic-breadcrumb";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { CategoriesComponent } from './categories/categories.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CorporateAboutComponent } from './corporate-about/corporate-about.component';
import { CorporateAwardsComponent } from './corporate-awards/corporate-awards.component';
import { CorporateMediaComponent } from './corporate-media/corporate-media.component';
import { CSRActivitiesComponent } from './csractivities/csractivities.component';
import { DealersComponent } from './dealers/dealers.component';
import { InvestorRelationsComponent } from './investor-relations/investor-relations.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsUseComponent } from './terms-of-use/terms-of-use.component';
import { ProductComponent } from './product/product.component';
import { SafetyComponent } from './safety/safety.component';
import { CareersComponent } from './careers/careers.component';
import { HttpClient } from '@angular/common/http';
import { AccessoriesComponent } from './accessories/accessories.component';
import { AccessoryComponent } from './accessory/accessory.component';
import { BlogComponent } from './blog/blog.component';
import { BlogSingleComponent } from './blog-single/blog-single.component';
import { BrandOutletComponent } from './brand-outlet/brand-outlet.component';
import { ManufacturingFacilityComponent } from './manufacturing-facility/manufacturing-facility.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SafePipe } from './safe.pipe';
import { ProductComparisonComponent } from './product-comparison/product-comparison.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FaqComponent } from './faq/faq.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { MapComponent } from './map/map.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AgmDirectionModule } from 'agm-direction';
import { TagsComponent } from './tags/tags.component'; 
import { LightboxModule } from 'ngx-lightbox';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PageComponent } from './page/page.component';
import { EventsComponent } from './events/events.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CategoriesComponent,
    ContactUsComponent,
    CorporateAboutComponent,
    CorporateAwardsComponent,
    CorporateMediaComponent,
    CSRActivitiesComponent,
    DealersComponent,
    InvestorRelationsComponent,
    PrivacyPolicyComponent,
    TermsUseComponent,
    ProductComponent,
    SafetyComponent,
    CareersComponent,
    AccessoriesComponent,
    AccessoryComponent,
    BlogComponent,
    BlogSingleComponent,
    BrandOutletComponent,
    ManufacturingFacilityComponent,
    GalleryComponent,
    SafePipe,
    ProductComparisonComponent,
    NotFoundComponent,
    FaqComponent,
    MapComponent,
    TagsComponent,
    PageComponent,
    EventsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    CarouselModule,
    BrowserAnimationsModule,
    NgDynamicBreadcrumbModule,
    NgxPaginationModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB_VQjCXYhlMzxZAPS5Tpkag1f73w8XWq4'
    }),
    AgmDirectionModule,
    AgmJsMarkerClustererModule,
    LightboxModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents:[MapComponent]
})
export class AppModule { }
