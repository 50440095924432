<div class="main-artboard position-relative">
		<div class="floating-rays">
			<img src="assets/images/big-studd.png" class="car-f0 zindex1" alt="big studds">
		</div>
		<div class="map-loader text-center" *ngIf="mediaLoader==true">
			<span></span>
		</div>
		<section class="dealer-sec career-sec zindex1" *ngIf="mediaLoader==false">
			<div class="container position-relative">
				<h1 class="sec-title text-center mb-2">{{pageTitle}}</h1>
				<p style="font-family: 'Roboto', sans-serif; font-size: 1.2em; margin-top: .5em; margin-bottom: 2em;" [innerHTML]="pageDescription"></p>
				<div class="row">
					<div class="col-lg-10 offset-lg-1 mt-5">
						<div class="row">
							<div class="col-md-12 p-0">
								<h3 class="job-heading">JOB OPENINGS</h3>
							</div>
							<div class="col-md-6" *ngFor = "let career of careerData">
								<div class="career-single">
									<h4 class="job-title">{{career.name}}</h4>
									<div class="job-detail">
										<div class="detail-single"><p><strong>Location: </strong>{{career.location}}</p></div>
										<div class="detail-single"><p><strong>Education: </strong>{{career.education}}</p></div>
										<div class="detail-single"><p><strong>Work Experience: </strong>{{career.experience}}</p></div>
										<div class="job-action text-right">
											<button class="job-btn detail-btn" (click)="openDetails($event, career)">View Details</button>
											<button class="job-btn apply-btn" (click)="openForm($event, career.name)">Apply Now</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="col-md-12 text-center mb-4">
							<a href="javascript:void(0)" class="more-jobs">View More Jobs</a>
						</div> -->
					</div>
				</div>
			</div>
		</section>
		<div class="careerdetailpopup">
			<div class="careerdetailpopup-inner">
				<p><strong>Job Title: </strong><span id="jtit"></span></p>
				<p><strong>Location: </strong><span id="jloc"></span></p>
				<p><strong>Education: </strong><span id="jedu"></span></p>
				<p><strong>Work Experience: </strong><span id="jexp"></span></p>
				<div class="career-description" id="jdesc">
				</div>
				<div class="text-center">
					<button class="job-btn apply-btn" (click)="openForm($event, jobDet.name)">Apply Now</button>
				</div>
				<span class="close-details" (click)="closeDetails()"><i class="fa fa-times"></i></span>
			</div>
		</div>
		<div class="careerpopup">
			<div class="careerpopup-inner">
				<div class="career-form">
					<form [formGroup]="testForm" (ngSubmit)="onSubmit2()">
						<div class="container">
							<div class="row">
								<div class="col-lg-10 offset-lg-1 col-md-12">
									<h2 class="careerpopup-title text-white text-center mt-5">Applying for <span class="job-position">Key Account Manager</span></h2>
									<div class="row cont-form">
				            			<div class="col-md-6">
						            		<div class="form-input" [ngClass]="{'has-error': testForm.get('name').errors && (testForm.get('name').touched || testForm.get('name').dirty) }">
												<input type="text" formControlName="name" class="form-field" id="cName" placeholder="Name*" required>
												<div id="postApplied">
													<input type="hidden" formControlName="postApplied" value="">
												</div>
												<span class="help-block" *ngIf="testForm.get('name').errors && (testForm.get('name').touched || testForm.get('name').dirty)">
													Name is required
												</span>
						            		</div>
						            	</div>
						            	<div class="col-md-6">
						            		<div class="form-input" [ngClass]="{'has-error': testForm.get('email').errors && (testForm.get('email').touched || testForm.get('email').dirty) }">
												<input type="email" formControlName="email" class="form-field" id="cMail" placeholder="E-mail Address*" required>
												<span class="help-block" *ngIf="testForm.get('email').errors && (testForm.get('email').touched || testForm.get('email').dirty)">
													Email is required
												</span>
						            		</div>
						            	</div>
						            	<div class="col-md-6">
						            		<div class="form-input" [ngClass]="{'has-error': testForm.get('city').errors && (testForm.get('city').touched || testForm.get('city').dirty) }">
						            			<select class="form-field" formControlName="city" id="cCity" required>
						            				<option value="">City*</option>
						            				<option>Gurugram</option>
						            				<option>Faridabad</option>
						            				<option>Noida</option>
												</select>
												<span class="help-block" *ngIf="testForm.get('city').errors && (testForm.get('city').touched || testForm.get('city').dirty)">
													Please select your city
												</span>
						            		</div>
						            	</div>
						            	<div class="col-md-6">
						            		<div class="form-input" [ngClass]="{'has-error': testForm.get('phone').errors && (testForm.get('phone').touched || testForm.get('phone').dirty) }">
												<input type="number" formControlName="phone" class="form-field" id="cPhone" placeholder="Phone Number*" required>
												<span class="help-block" *ngIf="testForm.get('phone').errors && (testForm.get('phone').touched || testForm.get('phone').dirty)">
													Phone Number is required
												</span>
						            		</div>
						            	</div>
						            	<div class="col-md-6">
						            		<div class="form-input" [ngClass]="{'has-error': testForm.get('experience').errors && (testForm.get('experience').touched || testForm.get('experience').dirty) }">
						            			<select formControlName="experience" class="form-field">
						            				<option value="">Total Experience*</option>
						            				<option value="1year">1 Year</option>
						            				<option value="2year">2 Years</option>
						            				<option value="3year">3 Years</option>
						            				<option value="4year">4 Years</option>
						            				<option value="5year">5 Years</option>
						            				<option value="6year">6 Years</option>
						            				<option value="7year">7 Years</option>
						            				<option value="8year">8 Years</option>
						            				<option value="9year">9 Years</option>
						            				<option value="10plusyear">10+ Years</option>
												</select>
												<span class="help-block" *ngIf="testForm.get('experience').errors && (testForm.get('experience').touched || testForm.get('experience').dirty)">
													Experience is required
												</span>
						            		</div>
						            	</div>
						            	<div class="col-md-6">
						            		<div class="form-input">
						            			<select formControlName="education" class="form-field">
						            				<option value="">Highest Education</option>
						            				<option value="Primary education">Primary education</option>
													<option value="Secondary education">Secondary education or high school</option>
													<option value="Bachelor's degree">Bachelor's degree</option>
													<option value="Master's degree">Master's degree</option>
													<option value="Doctorate or higher">Doctorate or higher</option>
												</select>
						            		</div>
						            	</div>
						            	<div class="col-md-6">
						            		<div class="form-input" [ngClass]="{'has-error': testForm.get('designation').errors && (testForm.get('designation').touched || testForm.get('designation').dirty) }">
												<input type="text" formControlName="designation" class="form-field" placeholder="Current Designation*">
												<span class="help-block" *ngIf="testForm.get('designation').errors && (testForm.get('designation').touched || testForm.get('designation').dirty)">
													Designation is required
												</span>
						            		</div>
						            	</div>
						            	<div class="col-md-6">
						            		<div class="form-input" [ngClass]="{'has-error': testForm.get('dateOfBirth').errors && (testForm.get('dateOfBirth').touched || testForm.get('dateOfBirth').dirty) }">
						            			<label class="label-inline text-white">Date of Birth*</label>
												<input type="date" formControlName="dateOfBirth" value="1970-01-01" min="1970-01-01" max="2005-12-31" class="form-field inline">
												<span class="help-block" *ngIf="testForm.get('dateOfBirth').errors && (testForm.get('dateOfBirth').touched || testForm.get('dateOfBirth').dirty)">
													Date of Birth is required
												</span>
						            		</div>
						            	</div>
						            	<div class="col-md-6">
						            		<div class="form-input">
						            			<label class="checkbox-inline text-white">
						            				<input type="checkbox" formControlName="relocate">
						            				Willing to relocate to Faridabad
						            			</label>
						            		</div>
						            	</div>
						            	<div class="col-md-6">
						            		<div class="form-input" [ngClass]="{'has-error': testForm.get('expectedSalary').errors && (testForm.get('expectedSalary').touched || testForm.get('expectedSalary').dirty) }">
												<input type="text"  formControlName="expectedSalary" class="form-field" placeholder="Expected Gross Salary/month (in Rs.)*">
												<span class="help-block" *ngIf="testForm.get('expectedSalary').errors && (testForm.get('expectedSalary').touched || testForm.get('expectedSalary').dirty)">
													Expected Salary is required
												</span>
						            		</div>
						            	</div>
						            	<div class="col-md-6">
						            		<div class="form-input" [ngClass]="{'has-error': testForm.get('noticePeriod').errors && (testForm.get('noticePeriod').touched || testForm.get('noticePeriod').dirty) }">
												<input type="text" formControlName="noticePeriod" class="form-field" placeholder="Notice Period (in Days)*">
												<span class="help-block" *ngIf="testForm.get('noticePeriod').errors && (testForm.get('noticePeriod').touched || testForm.get('noticePeriod').dirty)">
													Notice Period is required
												</span>
						            		</div>
						            	</div>
						            	<div class="col-md-6">
						            		<div class="form-input d-flex" [ngClass]="{'has-error': testForm.get('file').errors && (testForm.get('file').touched || testForm.get('file').dirty) }">
						            			<label class="label-inline text-white">Attach your Resume*<br><span style="font-size: .7em">(max file size allowed: 2MB)</span></label>
												<input type="file" formControlName="file" class="form-field inline" accept=".doc,.docx,.pdf" (change)="postMethod($event.target.files)">
												<span class="help-block" *ngIf="testForm.get('file').errors && (testForm.get('file').touched || testForm.get('file').dirty)">
													Resume is required
												</span>
						            		</div>
						            	</div>
						            	<div class="col-md-12">
						            		<div class="form-input">
												<textarea formControlName="message" class="form-field" id="cfeedback" placeholder="Message"></textarea>
						            		</div>
						            	</div>
						            	<div class="col-md-12 text-center">
						            		<div class="form-input">
												<button type="submit" class="btn btn-special" [disabled]="!testForm.valid">Submit</button>
												<div class="submit-loader text-center" *ngIf="loader" style="left: calc(50% + 50px);">
													<span></span>
												</div>
											</div>
											<div *ngIf="hide==false">
												<div class="alert alert-success mb-5" *ngIf="errorno==0">
													Thank You for applying. We will connect with you soon. 
												</div>
												<div class="alert alert-success mb-5" *ngIf="errorno==1">
													Some error occured. Please try again
												</div>
											</div>
										</div>
						            </div>
						        </div>
						    </div>
						</div>
					</form>
				</div>
				
			</div>
			<span class="closeCareer" (click)="closePopup()"><i class="fa fa-times"></i></span>
        </div>
		</div>
		<!-- <form [formGroup]="testForm" (ngSubmit)="onSubmit2()">
			<input type="text" formControlName="name" placeholder="Name">
			 <input id="myForm" type="file" formControlName="file" (change)="postMethod($event.target.files)">
			 <button type="submit" class="btn btn-special">Submit</button>
		</form> -->