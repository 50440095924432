<div class="main-artboard position-relative">
    <div class="floating-rays">
        <img src="assets/images/big-studd.png" class="safetyray0 zindex1" alt="big studds">
    </div>
    <section class="blog-section position-relative zindex1">
        <div class="container position-relative">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <div class="row align-items-center">
                        <div class="col-6">
                            <h1 class="sec-title text-white mb-2">Blogs</h1>
                        </div>
                        <div class="col-5 searchinput">
                            <!-- <a href="javascript:void(0)" class="blg-search-trigger" id="blg-search-trigger" (click)="openSearchForm()"><i class="fa fa-search"></i></a> -->
                            <div class="blog-search" id="blog-search-form" [ngClass]="{'opened': blogResultOpened}">
                                <input id="inpt" type="text" class="search-input" #blogSearchInput placeholder="Search Keyword" required  (keyup.enter)="showrelatedwithkeywords(blogSearchInput.value)">
                                
                            </div>
                        </div>
                        <div class="col-1">
                                <button class="btn btn-special" style="margin: 0px 10px 0px 0px;font-size: 13px"  (click)="showrelatedwithkeywords(blogSearchInput.value)">SEARCH</button>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="map-loader text-center" *ngIf="mediaLoader==true">
                <span></span>
            </div>
            <div class="row" *ngIf="mediaLoader==false">
                <div class="col-lg-10 offset-lg-1">
                    <div class="row blog-wrapper">
                        <div class="col-lg-8" *ngFor="let blg of blogs | slice:0:1">
                            <a href="blog/{{blg.blogUrl}}" class="blog-single">
                                <div class="blg-img-wrap"><img [src]="blg.thumbnail" class="img-fluid" alt="{{blg.blog_title}}"></div>
                                <div class="blog-single-inner">
                                    <h4 class="blog-single-title">{{blg.blog_title}}</h4>
                                    <p class="blog-single-excerpt">{{blg.excerpt}}</p>
                                    <h4 class="blg-more text-left">Keep Reading</h4>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-4">
                            <a href="blog/{{blg.blogUrl}}" class="blog-single single-sm" *ngFor="let blg of blogs | slice:1:3">
                                <div class="blg-img-wrap"><img [src]="blg.thumbnail" class="img-fluid" alt="{{blg.blog_title}}"></div>
                                <div class="blog-single-inner">
                                    <h4 class="blog-single-title">{{blg.blog_title}}</h4>
                                    <p class="blog-single-excerpt">{{blg.excerpt}}</p>
                                    <h4 class="blg-more text-left">Keep Reading</h4>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-4">
                            <a href="blog/{{blg.blogUrl}}" class="blog-single single-sm" *ngFor="let blg of blogs | slice:3:5">
                                <div class="blg-img-wrap"><img [src]="blg.thumbnail" class="img-fluid" alt="{{blg.blog_title}}"></div>
                                <div class="blog-single-inner">
                                    <h4 class="blog-single-title">{{blg.blog_title}}</h4>
                                    <p class="blog-single-excerpt">{{blg.excerpt}}</p>
                                    <h4 class="blg-more text-left">Keep Reading</h4>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-8" *ngFor="let blg of blogs | slice:5:6">
                            <a href="blog/{{blg.blogUrl}}" class="blog-single">
                                <div class="blg-img-wrap"><img [src]="blg.thumbnail" class="img-fluid" alt="{{blg.blog_title}}"></div>
                                <div class="blog-single-inner">
                                    <h4 class="blog-single-title">{{blg.blog_title}}</h4>
                                    <p class="blog-single-excerpt">{{blg.excerpt}}</p>
                                    <h4 class="blg-more text-left">Keep Reading</h4>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-8" *ngFor="let blg of blogs | slice:8:9">
                            <a href="blog/{{blg.blogUrl}}" class="blog-single">
                                <div class="blg-img-wrap"><img [src]="blg.thumbnail" class="img-fluid" alt="{{blg.blog_title}}"></div>
                                <div class="blog-single-inner">
                                    <h4 class="blog-single-title">{{blg.blog_title}}</h4>
                                    <p class="blog-single-excerpt">{{blg.excerpt}}</p>
                                    <h4 class="blg-more text-left">Keep Reading</h4>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-4">
                            <a href="blog/{{blg.blogUrl}}" class="blog-single single-sm" *ngFor="let blg of blogs | slice:6:8">
                                <div class="blg-img-wrap"><img [src]="blg.thumbnail" class="img-fluid" alt="{{blg.blog_title}}"></div>
                                <div class="blog-single-inner">
                                    <h4 class="blog-single-title">{{blg.blog_title}}</h4>
                                    <p class="blog-single-excerpt">{{blg.excerpt}}</p>
                                    <h4 class="blg-more text-left">Keep Reading</h4>
                                </div>
                            </a>
                        </div>
                    </div>
                    <ul class="blog-pagination text-center" *ngIf="activatePagination">
                        <li *ngIf="showPrev"><a href="/blogs/{{prevPage}}"><i class="fa fa-angle-double-left"></i></a></li>
                        <li *ngFor="let page of paginationArray"><a href="/blogs/{{page.indexPage}}" [routerLinkActive]="['active']">{{page.indexPage}}</a></li>
                        <li *ngIf="showNext"><a href="/blogs/{{nextPage}}"><i class="fa fa-angle-double-right"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</div>