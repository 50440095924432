import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessoriesComponent } from './accessories/accessories.component';
import { AccessoryComponent } from './accessory/accessory.component';
import { BlogComponent } from './blog/blog.component';
import { BlogSingleComponent } from './blog-single/blog-single.component';
import { HomeComponent } from './home/home.component';
import { CareersComponent } from './careers/careers.component';
import { CategoriesComponent } from './categories/categories.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CorporateAboutComponent } from './corporate-about/corporate-about.component';
import { CorporateMediaComponent } from './corporate-media/corporate-media.component';
import { GalleryComponent } from './gallery/gallery.component';
import { CorporateAwardsComponent } from './corporate-awards/corporate-awards.component';
import { CSRActivitiesComponent } from './csractivities/csractivities.component';
import { DealersComponent } from './dealers/dealers.component';
import { BrandOutletComponent } from './brand-outlet/brand-outlet.component';
import { InvestorRelationsComponent } from './investor-relations/investor-relations.component';
import { ManufacturingFacilityComponent } from './manufacturing-facility/manufacturing-facility.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsUseComponent } from './terms-of-use/terms-of-use.component';
//import { SafetyDeclarationComponent } from './safety-declaration/safety-declaration.component';
import { ProductComponent } from './product/product.component';
import { SafetyComponent } from './safety/safety.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FaqComponent } from './faq/faq.component';
import { TagsComponent } from './tags/tags.component';
import { PageComponent } from './page/page.component';
import { EventsComponent } from './events/events.component';

const routes: Routes = [
  {path:'',component:HomeComponent },
  {path: '' , redirectTo:'home',pathMatch:'full'},
  // {path:'motorcycle-accessories', component:AccessoriesComponent,
  // data: {
  //   breadcrumb: [
  //     {
  //       label: 'Home',
  //       url: '/'
  //     }, {
  //       label: 'Motorcycle Accessories',
  //       url: ''
  //     }
  //   ]
  // }
  // },
  // {path:'motorcycle-accessories/:accessoryname', component:AccessoriesComponent,
  // data: {
  //   breadcrumb: [
  //     {
  //       label: 'Home',
  //       url: '/'
  //     }, {
  //       label: 'Motorcycle Accessories',
  //       url: '/motorcycle-accessories'
  //     }, {
  //       label: '{{dynamicText}}',
  //       url: ''
  //     }
  //   ]
  // }
  // },
  // {path:'motorcycle-accessory/:accessoryname/:accname', component:AccessoryComponent,
  // data: {
  //   breadcrumb: [
  //     {
  //       label: 'Home',
  //       url: '/'
  //     }, {
  //       label: 'motorcycle accessories',
  //       url: '/motorcycle-accessories'
  //     }, {
  //       label: '{{dynamicText2}}',
  //       url: '/motorcycle-accessories/:accessoryname'
  //     }, {
  //       label: '{{dynamicText}}',
  //       url: ''
  //     }
  //   ]
  // }
  // },

  {path:'blogs', component:BlogComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'Blogs',
        url: ''
      }
    ]
  }
  },
  {path:'blogs/search', component:BlogComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'Blogs',
        url: ''
      }
    ]
  }
  },
  {path:'blogs/:blogpageid', component:BlogComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'Blogs',
        url: ''
      }
    ]
  }
  },
  {path:'tags/:key', component:TagsComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'Blogs',
        url: ''
      }
    ]
  }
  },
  {path:'blog/:name', component:BlogSingleComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'Blogs',
        url: '/blogs'
      }, {
        label: '{{dynamicText}}',
        url: ''
      }
    ]
  }
  },
  {path:'careers', component:CareersComponent, 
    data: {
      breadcrumb: [
        {
          label: 'Home',
          url: '/'
        }, {
          label: 'Careers',
          url: ''
        }
      ]
    }  
  },
  {path:'contact-us', component:ContactUsComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'Contact Us',
        url: ''
      }
    ]
  }
  },
  {path:'about', component:CorporateAboutComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'Our Company',
        url: ''
      }
    ]
  }
  },
  {path:'media', component:CorporateMediaComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'Media',
        url: ''
      }
    ]
  }
  },
  {path:'media/:mediapageid', component:CorporateMediaComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'Media',
        url: ''
      }
    ]
  }
  },
  {path:'gallery', component:GalleryComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'Gallery',
        url: ''
      }
    ]
  }
  },
  {path:'awards', component:CorporateAwardsComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'Awards',
        url: ''
      }
    ]
  }
  },
  {path:'csr-activities', component:CSRActivitiesComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'CSR Activities',
        url: ''
      }
    ]
  }
  },
  {path:'dealers', component:DealersComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'dealers',
        url: ''
      }
    ]
  }
  },
  {path:'brand-outlet', component:BrandOutletComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'exclusive brand outlets',
        url: ''
      }
    ]
  }
  },
  {path:'brand-outlet/:state', component:BrandOutletComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'exclusive brand outlets',
        url: ''
      }
    ]
  }
  },
  {path:'brand-outlet/:state/:city', component:BrandOutletComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'exclusive brand outlets',
        url: ''
      }
    ]
  }
  },
  {path:'investor-relations', component:InvestorRelationsComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'investor relations',
        url: ''
      }
    ]
  }
  },
  {path:'manufacturing-facility', component:ManufacturingFacilityComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'manufacturing facilities',
        url: ''
      }
    ]
  }
  },
  {path:'privacy-policy', component:PrivacyPolicyComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'privacy policy',
        url: ''
      }
    ]
  }
  },
  {path:'terms-of-use', component:TermsUseComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'terms of use',
        url: ''
      }
    ]
  }
  },
 
  // {path:'helmet/:categoryname/:productname', component:ProductComponent,
  // data: {
  //   breadcrumb: [
  //     {
  //       label: 'Home',
  //       url: '/'
  //     }, {
  //       label: 'Helmets',
  //       url: '/helmets'
  //     }, {
  //       label: '{{dynamicText}}',
  //       url: '/helmets/:categoryname'
  //     }
  //     , {
  //       label: '{{dynamicText2}}',
  //       url: ''
  //     }
  //   ]
  // }
  // },
  {path:'safety', component:SafetyComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'Safety',
        url: ''
      }
    ]
  }
  },
  {path:'faq', component:FaqComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'faq',
        url: ''
      }
    ]
  }
  },
  {path:'events', component:EventsComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'events',
        url: ''
      }
    ]
  }
  },
  {path:'page/:pagename', component:PageComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: '{{dynamicText}}',
        url: ''
      }
    ]
  }
  },
  {path: '404', component: NotFoundComponent},

  {path:':catname', component:CategoriesComponent,
      data: {
        breadcrumb: [
          {
            label: 'Home',
            url: '/'
          }, {
            label: '{{dynamicCat}}',
            url: ''
          }
        ]
      }
  },
  {path:':catname/:prodname', component:CategoriesComponent,
      data: {
        breadcrumb: [
          {
            label: 'Home',
            url: '/'
          }, {
            label: '{{dynamicCat}}',
            url: ':catname'
          }, {
            label: '{{dynamicProd}}',
            url: ''
          }
        ]
      }
  },
  { path:'helmet/:prodname/:productname', component: ProductComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: 'helmets',
        url: 'helmets'
      }, {
        label: '{{dynamicText2}}',
        url: 'helmets/:prodname'
      }, {
        label: '{{dynamicText3}}',
        url: ''
      }
    ]
  }
 },
 { path:'motorcycle-accessory/:prodname/:productname', component: ProductComponent,
 data: {
   breadcrumb: [
     {
       label: 'Home',
       url: '/'
     }, {
       label: 'Motorcycle Accessories',
       url: 'motorcycle-accessories'
     }, {
       label: '{{dynamicText2}}',
       url: 'motorcycle-accessories/:prodname'
     }, {
       label: '{{dynamicText3}}',
       url: ''
     }
   ]
 }
},
  { path:':catname/:prodname/:productname', component: ProductComponent,
  data: {
    breadcrumb: [
      {
        label: 'Home',
        url: '/'
      }, {
        label: '{{dynamicText}}',
        url: ':catname'
      }, {
        label: '{{dynamicText2}}',
        url: ':catname/:prodname'
      }, {
        label: '{{dynamicText3}}',
        url: ''
      }
    ]
  }
 },
{path: '**', redirectTo: '404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
