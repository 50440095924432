<div class="main-artboard position-relative about-page">
        <div class="floating-rays">
         <img src="assets/images/big-studd.png" class="car-f0 zindex1" alt="big studds">
       </div> 
       <div class="map-loader text-center" *ngIf="mediaLoader2==true">
        <span></span>
    </div>
       <section class="st-news zindex1" *ngIf="mediaLoader2==false">
           <div class="container">
               <div class="col-lg-10 col-md-12 offset-lg-1">
               <h1 class="sec-title text-center">{{pageTitle}}</h1>
               <p style="font-family: 'Roboto', sans-serif; text-align: center; color: #fff; font-size: 1.1em; margin-top: 0; margin-bottom: 1.2em;" [innerHTML]="pageDescription"></p>
               <div class="media-tabs">
                   <ul class="year-list mb-5">
                       <ng-container *ngFor="let year of mediaYears; let i=index">
                           <li (click)="getmedia(year.year, i)" [ngClass]="i==0? 'active' : ''">{{year.year}}</li>
                       </ng-container>
                   </ul>
               </div>
               <div class="st-news-wrap" *ngIf="mediaLoader == false">
                   <div class="st-news-single" *ngFor="let med of currentMedia | paginate: { itemsPerPage: 10, currentPage: p }">
                       <h4 class="st-news-title text-md-left">{{med.title}}</h4>
                       <div class="row align-items-center">
                           <div class="col-lg-4 col-md-5 mb-4 mb-md-0">
                               <img [src]="med.picture" class="img-fluid" alt="{{ med.alt_tag }}">
                           </div>
                           <div class="col-lg-8 col-md-7 text-md-left">
                               <p class="st-news-desc">{{med.desc}}</p>
                               <div class="row align-items-center">
                                   <div class="col-md-5 mb-4 mb-md-0"><a rel="nofollow" class="med-read" target="_blank" [href]="med.link">Read More</a></div>
                                   <div class="col-md-7 text-center text-md-right"><p class="st-news-meta">
                                       <strong style="font-size: 20px;text-transform: uppercase;">{{med.name}}</strong><br> {{med.date}}</p></div>
                               </div>
                           </div>
                       </div>
                   </div>
                   <pagination-controls (pageChange)="p = $event" class="my-pagination"></pagination-controls>
               </div>
                <div class="map-loader text-center" *ngIf="mediaLoader==true">
                    <span></span>
                </div>
           </div>
        </div>
    </section>
</div>

<button class="vid-trigger3" (click)="openPop()">
    <svg height="64" width="140">
        <path d="M62,31 a30,30 1 1,0 -30,30 l100 0" stroke-dasharray="240px" stroke-dashoffset="240px" stroke="#0fd1cf" stroke-width="2" fill="none" />
    </svg><img src="assets/images/studdsbutton2.png"><span>STUDDS In News</span></button>
<div class="vidpop" id="vidpop">
    <div class="vidpopinner text-center">
        <h4 class="vcaption">Our respected MD, Mr. Sidharth Khurana in a video interview with Jagran HiTech about import from China becoming zero, #Studds new manufacturing units, doubling capacity of motorcycle helmets, export plans, helmet industry approach as well as Covid-19 impact and new smart helmet technology.</h4>
        <div class="wrap">
            <iframe id="popvid" width="100%" height="320" src="https://www.youtube.com/embed/NNRsC0rQodI?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <span class="fa fa-times" (click)="closePop()"></span>
    </div>
</div>