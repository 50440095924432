import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {
  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  faqs: any;
  mediaLoader: boolean = false;
  firstid: any;
  sub: any;
  page:any;
  metaDescrition:any;
  metaKeywords:any;
  content:any;
  constructor(private router:Router, private route:ActivatedRoute,private titleService: Title,private meta: Meta,  private data:ApiService,private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }

  ngOnInit(): void {
    this.mediaLoader = true;

    this.sub = this.route.params.subscribe(params => {
      this.firstid = params['pagename']; // (+) converts string 'id' to a number
    });
    this.data.getDynamicpage(this.firstid).subscribe(data=>{
      this.mediaLoader = false;
      this.page = data;
     // console.log(this.page);
      if(this.page.length == 0) {
        this.pageTitle='Uh! Ooh....No Page Found'
      }else{

      const breadcrumb =  {dynamicText: this.firstid};
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
        this.content = this.page[0].content;
        this.pageTitle=this.page[0].pageHeading;
        this.pageDescription=this.page[0].pageDescription;
        // Meta Tags Value
        this.pageTitle = this.page[0].metatitle;
        this.metaDescrition = this.page[0].metadescription;
        this.metaKeywords = this.page[0].metakeyword;
        this.titleService.setTitle(this.pageTitle);
        this.meta.addTags([
          {name: 'description', content: this.metaDescrition},
          {name: 'keywords', content: this.metaKeywords},
          {name: 'twitter:card',content: 'Summary'},
          {name: 'twitter:site', content: '@StuddsHelmet'},
          {name: 'twitter:title', content: this.pageTitle},
          {name: 'twitter:description', content: this.metaDescrition},
          {name: 'twitter:image', content: 'https://www.studds.com/assets/images/logo-red.png'},
          {property: 'og:title', content: this.pageTitle},
          {property: 'og:description', content: this.metaDescrition},
          {property: 'og:image', content: 'https://www.studds.com/assets/images/logo-red.png'},
        ]);
        }
      })
  }

}
