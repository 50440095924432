import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  careerData: any;
  careerPopup: any;
  jobPosition: any;
  jobDet: any;
  employeeForm: FormGroup;
  testForm: FormGroup;
  formSuccess: boolean;
  response: any;
  fileData: File = null;
  loader:boolean=false;
  errorno: any;
  hide: boolean = false;
  filetoupload: any;

  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  mediaLoader: boolean = false;
  constructor(private data: ApiService, private router: Router, private fb: FormBuilder, private HttpClient: HttpClient, location: Location) {
    router.events.subscribe((val) => {
      if(location.path() == ''){
        this.url1 = true;
      } else {
        this.url1 = false;
      }
    });
    this.href = location.path();
    this.pathCount = this.href.split('/').length - 1;
  }

  ngOnInit() {
    this.mediaLoader = true;
    if(this.pathCount==1) {
      this.href= this.href.substr(1);
      this.data.getmetadatabypage(this.href).subscribe(data=> {
        this.titles = data;
        if(this.titles.length>0) {
          this.pageTitle=this.titles[0].pagetitle;
          this.pageDescription=this.titles[0].pagedescription;
        }
      })
    }
    this.data.getcareerdata().subscribe(data=> {
      this.careerData = data;
      this.mediaLoader = false;
    })
    this.testForm = this.fb.group({
      name : ['', [Validators.required]],
      postApplied : [''],
      email : ['', [Validators.required]],
      city : ['', [Validators.required]],
      phone : ['', [Validators.required]],
      experience : ['', [Validators.required]],
      education : [''],
      designation : ['', [Validators.required]],
      dateOfBirth : ['', [Validators.required]],
      relocate : [''],
      expectedSalary : ['', [Validators.required]],
      noticePeriod : ['', [Validators.required]],
      file : ['', [Validators.required]],
      message : ['']
    })
    
  }
  
  postMethod(files: FileList) {
    this.filetoupload = files.item(0); 
  }
  onSubmit2(){
    this.loader=true;
    //console.log(this.testForm.value);
    let Jpos = document.getElementsByClassName('job-position')[0].innerHTML;
    this.testForm.value.postApplied = Jpos;
    const formData = new FormData(); 
    formData.append("name", this.testForm.value.name);
    formData.append("postApplied", this.testForm.value.postApplied);
    formData.append("email", this.testForm.value.email);
    formData.append("city", this.testForm.value.city);
    formData.append("phone", this.testForm.value.phone);
    formData.append("experience", this.testForm.value.experience);
    formData.append("education", this.testForm.value.education);
    formData.append("designation", this.testForm.value.designation);
    formData.append("dateOfBirth", this.testForm.value.dateOfBirth);
    formData.append("relocate", this.testForm.value.relocate);
    formData.append("expectedSalary", this.testForm.value.expectedSalary);
    formData.append("noticePeriod", this.testForm.value.noticePeriod);
    formData.append("file", this.filetoupload, this.filetoupload.name);
    formData.append("message", this.testForm.value.message);
    this.HttpClient.post("https://www.studds.com/Adminpanel/index.php/Webapi/uploadfilestest", formData,
    { headers: new HttpHeaders({'x-api-key':'askjdhkasd897hbj98hjknmk98bnm'}) }).subscribe((val) => {
      
      if(val==1){
        this.loader=false;
        this.errorno = 0;
        setTimeout(function(){
          this.hide = true;
          this.testForm.reset();
        }.bind(this), 2000)
      }
      else {
        this.errorno = 1;
      }
    });
    return false; 
  }
 
  openForm(event:any, jobTitle:any){
    document.getElementsByClassName('careerdetailpopup')[0].classList.remove('active');
    this.careerPopup = document.getElementsByClassName('careerpopup')[0];
    this.jobPosition = document.getElementsByClassName('job-position')[0];
    this.jobPosition.innerHTML = jobTitle;
    document.getElementById('postApplied').getElementsByTagName('input')[0].value = jobTitle;
    this.careerPopup.classList.add('active');
  }

  closePopup(){
    this.careerPopup.classList.remove('active');
  }
  openDetails(event: any, jobDetails: any) {
    this.jobDet = jobDetails;
    document.getElementById('jtit').innerHTML = this.jobDet.name;
    document.getElementById('jloc').innerHTML = this.jobDet.location;
    document.getElementById('jedu').innerHTML = this.jobDet.education;
    document.getElementById('jexp').innerHTML = this.jobDet.experience;
    document.getElementById('jdesc').innerHTML = this.jobDet.description;
    document.getElementsByClassName('careerdetailpopup')[0].classList.add('active');
  }
  closeDetails(){
    document.getElementsByClassName('careerdetailpopup')[0].classList.remove('active');
  }

}
