<div class="main-artboard position-relative">
		<div class="floating-rays">
			<img src="assets/images/big-studd.png" class="car-f0 zindex1" alt="big studds">
		</div>

		<section class="dealer-sec career-sec privacy-sec zindex1" style="padding-top: 120px;">
			<div class="container position-relative">
				<h1 class="sec-title text-center mb-2 text-white">{{pageTitle}}</h1>
				<p style="font-family: 'Roboto', sans-serif; text-align: center; color: #fff; font-size: 1.1em; margin-top: 0; margin-bottom: 1.2em;" [innerHTML]="pageDescription"></p>
				<div class="row-content mt-5" [innerHTML]="TermsContent"></div>
			</div>
        </section>
    </div>