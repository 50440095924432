import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import {NgxPaginationModule} from 'ngx-pagination';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  productdetail : any;
  firstid: any;
  secondid: any;
  thirdid: any;
  productcat: any;
  sub: any;
  productid: any;
  productname: any;
  quantity: any;
  genricname: any;
  price: any;
  image: any;
  description: any;
  productVarient: any;
  productsizes: any;
  features: any;
  featuresempty: boolean = false;
  varCount: number;
  checkUrban: boolean = false;
  accessGlobal: any;
  reviewForm: FormGroup;
  emptyForm: boolean = false;
  emptyFormStar: boolean = false;
  emptyFormBoth: boolean = false;
  emptyFormTitle: boolean = false;
  emptyFormEmail: boolean = false;
  emptyFormMobile: boolean = false;
  reviewSuccess: boolean = false;
  serverError: boolean = false;
  loading: boolean = false;
  reviewData: any;
  overallReview: number = 0;
  mediaLoader: boolean = false;
  reviewCount:number;
  p: number = 1;
  categoryname :string;
  allPaths: any;
  pageTitle:any;
  alt_tag:any;
  pageDescription:any;
  motoAccess: any;
  allCats: any;
  showAccess: boolean = false;
  nosizes: boolean = false;
  constructor(location: Location, private router: Router, private data: ApiService, private route: ActivatedRoute, private titleService: Title, private meta: Meta, private fb: FormBuilder, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) {
    this.allPaths = location.path().split('/');
  }

  ngOnInit() {
    this.mediaLoader = true;
    this.sub = this.route.params.subscribe(params => {
      this.firstid = params['productname']; // (+) converts string 'id' to a number
      
      this.categoryname = params['productname'];
      if (this.firstid=='urban' || this.firstid == 'suburban') {
        this.checkUrban = true;
      }
    });
    
    this.secondid = this.allPaths[2];
    this.thirdid = this.allPaths[1];



    if(this.thirdid == 'helmet'){
      this.thirdid = 'helmets';
    }
    if(this.thirdid == 'motorcycle-accessory'){
      this.thirdid = 'motorcycle-accessories';
    }
    const breadcrumb =  {dynamicText: this.thirdid.replace(/-/g, ' '), dynamicText2: this.secondid.replace(/-/g, ' '),dynamicText3: this.firstid.replace(/-/g, ' ')};
    this.ngDynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);

    this.data.getproductdata(this.thirdid, this.secondid, this.firstid).subscribe(data=>{
      this.productdetail = data;
      //console.log(this.productdetail);
      this.productdetail = this.productdetail.productdetail;
      
      if(!this.productdetail[0]['name']) {
       // window.location.href="404";
      }
      else {
        this.titleService.setTitle(this.productdetail[0]['metatitle']);
        this.meta.addTags([
          {name: 'description', content: this.productdetail[0]['metadescription']},
          {name: 'keywords', content: this.productdetail[0]['metakeyword']},

          {name: 'twitter:card',content: 'Summary'},
          {name: 'twitter:site', content: '@StuddsHelmet'},
          {name: 'twitter:title', content: this.productdetail[0]['metatitle']},
          {name: 'twitter:description', content: this.productdetail[0]['metadescription']},
          {name: 'twitter:image', content: 'https://www.studds.com/assets/images/logo-red.png'},
          {property: 'og:title', content: this.productdetail[0]['metatitle']},
          {property: 'og:description', content: this.productdetail[0]['metadescription']},
          {property: 'og:image', content: 'https://www.studds.com/assets/images/logo-red.png'},
        ]);

        this.mediaLoader = false;
        this.productid = this.productdetail[0]['id'];
        this.pageTitle = this.productdetail[0]['pageHeading'];
        this.pageDescription = this.productdetail[0]['pageDescription'];
        this.categoryname = this.productdetail[0]['name'];
        this.productname = this.productdetail[0]['pname'];
        this.price = this.productdetail[0]['price'];
        this.genricname = this.productdetail[0]['genricname'];
        this.quantity = this.productdetail[0]['quantity'];
        this.image = this.data.baseImageUrl+this.productdetail[0]['image'];
        this.description = this.productdetail[0]['description'];
        this.alt_tag = this.productdetail[0]['alt_tag'];
        // this.productsizes=this.productdetail[0]['sizes'].split(',');
        this.productsizes=this.productdetail[0]['sizes_name'];
        this.features = this.productdetail[0]['featured_check_name'];
        if(this.features.length>0){
          this.featuresempty = true;
          this.features.forEach(single => {
            single.icons = this.data.baseImageUrl+single.icons;
          });
        }
        else{
          this.featuresempty = false;
        }
        if(this.productsizes.length<=0){
          this.nosizes = true;
        }
        this.data.getproductreview(this.productid).subscribe(data=>{
          this.reviewData = data;
          this.reviewCount = this.reviewData.length;
          this.reviewData.forEach(single=>{
            this.overallReview = this.overallReview + parseInt(single.rating);
            let date = single.dateadded.split(' ')[0];
            date = date = new Date(date);
            let day = date.getDate(date);
            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            let month = months[date.getMonth()];
            let year = date.getFullYear();
            single.date = day+' '+month+' '+year;
          })
          this.overallReview = this.overallReview/this.reviewData.length;
        })
      }
      
     
    })
    
    
    this.data.getvarient(this.firstid).subscribe(data=>{
      this.productVarient = data;
      this.productVarient.forEach(single => {
        single.image = this.data.baseImageUrl+single.image;
      });
      this.varCount = this.productVarient.length;
    })

    this.data.getCategory().subscribe(data=> {
      this.allCats = data;
      this.allCats.forEach(single=> {
        let urltomatch = single.name.toLowerCase().replace(/\s+/g, '-');
        if(urltomatch==this.thirdid && single.show_acc==1) {
          this.showAccess = true;
          this.data.getaccessories().subscribe(data=>{
            this.motoAccess=data;
            this.motoAccess.forEach(single=>{
              single.icons = this.data.baseImageUrl + single.icons;
              single.purl = single.pname.toLowerCase().replace(/\s+/g, '-');
              single.url = single.name.toLowerCase().replace(/\s+/g, '-');
            })
          })
          return;
        }
      })
    })

    this.reviewForm = this.fb.group({
      title : ['', Validators.required],
      email : ['', Validators.required],
      mobile : ['', Validators.required],
      rating : ['', Validators.required],
      message : ['', Validators.required]
    })
  }


  getVarient (id: any, image: any) {
    let mainProd = document.getElementsByClassName('main-helmet')[0];
    let mainProdImg = mainProd.getElementsByTagName('img')[0];
    document.getElementById('myele'+id).style.transform = "rotate(-180deg) scale(0)";
    document.getElementsByClassName('main-helmet')[0].getElementsByTagName('img')[0].style.transform = "scale(0)";
    document.getElementsByClassName('main-helmet-wrap')[0].getElementsByTagName('img')[0].style.transform = "rotate(0deg) scale(0)";
    document.getElementsByClassName('main-helmet-wrap')[0].getElementsByTagName('img')[1].style.transform = "translate(-50%, -50%) rotate(0deg) scale(0)";
    document.getElementsByClassName('main-helmet-wrap')[0].getElementsByTagName('img')[2].style.transform = "rotate(0deg) scale(0)";
    setTimeout(function(){
      mainProdImg.src = image;
      setTimeout(function(){
        document.getElementById('myele'+id).style.transform = "rotate(0deg) scale(1)";
        document.getElementsByClassName('main-helmet')[0].getElementsByTagName('img')[0].style.transform = "scale(1)";
        document.getElementsByClassName('main-helmet-wrap')[0].getElementsByTagName('img')[0].style.transform = "rotate(0deg) scale(1)";
        document.getElementsByClassName('main-helmet-wrap')[0].getElementsByTagName('img')[1].style.transform = "translate(-50%, -50%) rotate(0deg) scale(1)";
        document.getElementsByClassName('main-helmet-wrap')[0].getElementsByTagName('img')[2].style.transform = "rotate(0deg) scale(1)";
      }, 300)
    }, 300)
    
  }

  reviewSubmit(): void{
    this.loading = true;
    this.data.getproductdetail(this.firstid).subscribe(data=> {
      let formDetails = data;
      this.reviewForm.value.productid = formDetails[0].id;
      // if(this.reviewForm.value.rating == '' && this.reviewForm.value.message == '' && this.reviewForm.value.heading == ''){
      //   this.emptyFormBoth = true;
      //   setTimeout(function(){
      //     this.loading = false;
      //     this.emptyFormBoth = false;
      //   }.bind(this),2000)
      // }
      this.loading = false
      if(this.reviewForm.value.rating == '') {
        this.emptyFormStar = true;
        setTimeout(function(){
          this.emptyFormStar = false;
        }.bind(this),4000)
      }
      if(this.reviewForm.value.title == '') {
        this.emptyFormTitle = true;
        setTimeout(function(){
          this.emptyFormTitle = false;
        }.bind(this),4000)
      }
      if(this.reviewForm.value.email == '') {
        this.emptyFormEmail = true;
        setTimeout(function(){
          this.emptyFormEmail = false;
        }.bind(this),4000)
      }
      if(this.reviewForm.value.mobile == '') {
        this.emptyFormMobile = true;
        setTimeout(function(){
          this.emptyFormMobile = false;
        }.bind(this),4000)
      }
      // if(this.reviewForm.value.heading == '') {
      //   this.emptyFormTitle = true;
      //   setTimeout(function(){
      //     this.emptyFormTitle = false;
      //   }.bind(this),4000)
      // }
      if(this.reviewForm.value.message == '') {
        this.emptyForm = true;
        setTimeout(function(){;
          this.emptyForm = false;
        }.bind(this),4000)
      }
      
      if(this.emptyForm == false && this.emptyFormStar ==  false && this.emptyFormTitle ==  false && this.emptyFormEmail ==  false && this.emptyFormMobile ==  false )  {
        this.data.productreview(this.reviewForm.value).subscribe(data=>{
        //  console.log(data);
          if(data==1) {
            this.reviewSuccess = true;
            setTimeout(function(){
              this.loading = false;
              this.commentSuccess = false;
              this.reviewSuccess= false;
            }.bind(this), 3000)
          }
          else {
            this.serverError = true;
            setTimeout(function(){
              this.serverError = false;
            }.bind(this), 2000)
          }
          this.loading = false;
        })
      }
      
    })
  }

  scrollToElement($element): void {
    // console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  openKnow() {
    let popup = document.getElementById('know-popup');
    popup.classList.add('opened');
  }
  closeKnow() {
    let popup = document.getElementById('know-popup');
    popup.classList.remove('opened');
  }





}
