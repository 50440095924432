<div class="main-artboard position-relative about-page">
        <div class="floating-rays">
             <img src="assets/images/big-studd.png" class="car-f0 zindex1" alt="big studds">
       </div>
       <div class="map-loader text-center" *ngIf="mediaLoader==true">
        <span></span>
    </div> 
       <section class="investor-sec zindex1"  *ngIf="mediaLoader==false">
           <div class="container text-center mb-4">
               <div class="row">
                   <div class="col-lg-10 offset-lg-1 pb-3">
                       <h1 class="sec-title text-white mb-3">{{pageTitle}}</h1>
                       <p style="font-family: 'Roboto', sans-serif; text-align: center; color: #fff; font-size: 1.1em; margin-top: 0; margin-bottom: 1.2em;" [innerHTML]="pageDescription"></p>
                       <h2 class="sec-subtitle text-white mb-4 mt-5">COMPOSITION OF CORPORATE SOCIAL RESPONSIBILITY COMMITTEE</h2>
                       <div class="table-responsive">
                           <table class="table table-bordered text-white mb-4" style="font-family: 'Roboto', sans-serif;">
                               <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Director Identification Number</th>
                                        <th scope="col">Designation</th>
                                    </tr>
                               </thead>
                               <tbody>
                                   <tr>
                                       <td>Mr. Madhu Bhushan Khurana</td>
                                       <td>00172770</td>
                                       <td>Chairperson (Chairman and Managing Director)</td>
                                   </tr>
                                   <tr>
                                        <td>Mr. Sidhartha Bhushan Khurana</td>
                                        <td>00172788</td>
                                        <td>Member (Managing Director)</td>
                                    </tr>
                                    <tr>
                                        <td>Ms. Pallavi Saluja</td>
                                        <td>07006557</td>
                                        <td>Member (Independent Director Non-Executive)</td>
                                    </tr>
                               </tbody>
                           </table>
                       </div>
                       <a target="blank" href="/assets/Files/CSR_Policy.pdf" class="btn btn-special">View CSR Policy</a>
                       <a target="blank" href="/assets/Files/approved_by_Board.pdf" class="btn btn-special" style="margin-left:10px">Projects Approved By Board </a>
                   </div>
               </div>
           </div>
           <div class="container">
               <div class="row">
                   <div class="col-md-4 csr-img" *ngFor="let invester of csrArray; let i=index">
                            <img [src]="invester.content[0].imglinks" width="100%" style="cursor: pointer;" class="img-responsive" (click)="openDetails($event,invester.content[0])" alt="{{invester.content[0].alt_tag}}">
                            <h4 class="csr-title" style="cursor: pointer;" (click)="openDetails($event,invester.content[0])">{{invester.content[0].imgtitle}}</h4>
                   </div>
               </div>
           </div>
       </section>
       <div class="careerdetailpopup">
            <div class="careerdetailpopup-inner">
                <img id="csrimage" [src]="" width="100%" class="img-responsive" alt="CSR Image">
                <h1 class="heading"><span id="heading"></span></h1>
                <p id="details"></p>
                <span class="close-details" (click)="closeDetails()"><i class="fa fa-times"></i></span>
            </div>
        </div>
    </div>

    <button class="vid-trigger3" (click)="openPop()">
        <svg height="64" width="140">
            <path d="M62,31 a30,30 1 1,0 -30,30 l100 0" stroke-dasharray="240px" stroke-dashoffset="240px" stroke="#0fd1cf" stroke-width="2" fill="none" />
        </svg><img src="assets/images/studdsbutton2.png"><span>STUDDS In News</span></button>
    <div class="vidpop" id="vidpop">
        <div class="vidpopinner text-center">
            <h4 class="vcaption">Our respected MD, Mr. Sidharth Khurana in a video interview with Jagran HiTech about import from China becoming zero, #Studds new manufacturing units, doubling capacity of motorcycle helmets, export plans, helmet industry approach as well as Covid-19 impact and new smart helmet technology.</h4>
            <div class="wrap">
                <iframe id="popvid" width="100%" height="320" src="https://www.youtube.com/embed/NNRsC0rQodI?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <span class="fa fa-times" (click)="closePop()"></span>
        </div>
    </div>