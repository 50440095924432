import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SlidesOutputData, OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-corporate-awards',
  templateUrl: './corporate-awards.component.html',
  styleUrls: ['./corporate-awards.component.css']
})
export class CorporateAwardsComponent implements OnInit {

  // Slider Options
  customOptions: OwlOptions = {
    autoplay:true,
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    center: true,
    margin: 20,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      }
    },
    nav: true
  };

  awards: any;
  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  mediaLoader: boolean = false;
  activeSlides: SlidesOutputData;
  slidesStore: any[];
  resized: boolean = true;
  constructor(location: Location, private router: Router, private data: ApiService) {
    router.events.subscribe((val) => {
      if(location.path() == ''){
        this.url1 = true;
      } else {
        this.url1 = false;
      }
    });
    this.href = location.path();
    this.pathCount = this.href.split('/').length - 1;
  }

  ngOnInit() {
    this.mediaLoader = true;
    let winWidth = window.innerWidth;
    window.addEventListener('resize',()=>{
      let newWinWidth = window.innerWidth;
      if(winWidth!=newWinWidth){
        winWidth = newWinWidth;
        this.resized = false;
        setTimeout(()=>{
          this.resized = true;
        },300)
      }
    })
    if(this.pathCount==1) {
      this.href= this.href.substr(1);
      this.data.getmetadatabypage(this.href).subscribe(data=> {
        this.titles = data;
        if(this.titles.length>0) {
          this.pageTitle=this.titles[0].pagetitle;
          this.pageDescription=this.titles[0].pagedescription;
        }
      })
    }
    this.data.getawards().subscribe(data=> {
      this.awards = data;
      this.awards.forEach(single => {
        single.image = this.data.baseImageUrl+single.image;
      });
      this.mediaLoader = false;
    })
  }

  getPassedData(data: SlidesOutputData) {
    this.activeSlides = data;
    let awardContent = document.querySelectorAll('.award-content');
    awardContent.forEach(single=>{
      single.setAttribute('style','display: none');
    })
    document.getElementById('award'+this.activeSlides.startPosition).style.display="block";
  }

  openPop():void{
    let vid = <HTMLIFrameElement> document.getElementById('popvid');
    // vid.src += "&autoplay=1";
    document.getElementById('vidpop').classList.add('opened');
  }
  closePop():void{
    let vid = <HTMLIFrameElement> document.getElementById('popvid');
    vid.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
    // vid.src = vid.src.substring(0, vid.src.length-11);
    document.getElementById('vidpop').classList.remove('opened');
  }

}
