import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  studds_unq:any = environment.user_local;
  basePath: any;
  constructor(private http: HttpClient) {
    // this.basePath = window.location.origin;
    // console.log(this.basePath);
  }
  
  
  
  // for production access
  private baseUrl = 'https://www.studds.com/Adminpanel/index.php/Webapi';  
  public baseImageUrl = 'https://www.studds.com/Adminpanel';

  // // //  for Local access
  //  private baseUrl = 'http://localhost/studds-aarti/admin/index.php/Webapi';  
  //  public baseImageUrl = 'http://localhost/studds-aarti/admin';
 
  getCategory(){
    return this.http.get(this.baseUrl + '/getcategory',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getCategoryByName(name:string){
    return this.http.get(this.baseUrl + '/getcategorybyname?id=' + name,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getSubCategoryByName(catname: string){
    return this.http.get(this.baseUrl + '/getsubcategorybyname?id=' + catname,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getproductsbyname(prodname: string){
    return this.http.get(this.baseUrl + '/getproductsbyname?id=' + prodname,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getproductdata(parentid:string, catid:string, prodid:string){
    return this.http.get(this.baseUrl + '/getproductdata?parentid='+parentid+'&catid='+catid+'&prodid='+prodid,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getaccessories(){
    return this.http.get(this.baseUrl + '/getaccessories',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }

  getCategoryWithId(catid: string){
    return this.http.get(this.baseUrl + '/getcategorywithid?id=' + catid,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getacccategorywithid(accid:string) {
    return this.http.get(this.baseUrl + '/getacccategorywithid?id=' + accid,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  // getProductfromCategoryId(){
  //   return this.http.get(this.baseUrl + '/getproductfromcategory?id=full',
  //   { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
  //   )
  // }
  getallProducts(categoryid: string){
    return this.http.get(this.baseUrl + '/getproductfromcategory?name=' + categoryid,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getproductdetail(productid: string){
    return this.http.get(this.baseUrl + '/getproduct?id=' + productid,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getvarient(productid: string){
    return this.http.get(this.baseUrl + '/getproductvarients?id=' + productid,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getcareerdata(){
    return this.http.get(this.baseUrl + '/getcareerdata',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getaccessorycategory(){
    return this.http.get(this.baseUrl + '/getaccessorycategory',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getproductfromaccessory(accessoryurl: string){
    return this.http.get(this.baseUrl + '/getproductfromaccessory?id='+accessoryurl,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getaccessory(accessoryname: string){
    return this.http.get(this.baseUrl + '/getaccessory?id='+accessoryname,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getawards(){
    return this.http.get(this.baseUrl + '/getawards',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getmedia(){
    return this.http.get(this.baseUrl + '/getmedia',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getmediabyyear(year: any){
    return this.http.get(this.baseUrl + '/getmediabyyear?year=' + year,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getevents(){
    return this.http.get(this.baseUrl + '/getevents',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  geteventsbyyear(year: any){
    return this.http.get(this.baseUrl + '/geteventsbyyear?year=' + year,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getblogs(){
    return this.http.get(this.baseUrl + '/getblogs',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  
  getrelatedblogs(key){
    return this.http.get(this.baseUrl + '/getrelatedblogs?key=' + key,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getsingleblogs(name: any){
    return this.http.get(this.baseUrl + '/getsingleblogs?id='+name,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }

  getcountry(){
    return this.http.get(this.baseUrl + '/getcountry',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }

  getstates(name:string){
    return this.http.get(this.baseUrl + '/getstate?country=' + name,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getcityfromstate(name: string){
    return this.http.get(this.baseUrl + '/getcityfromstate?state='+name,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getaddressfromcity(name: string,state:string){
    return this.http.get(this.baseUrl + '/getaddressfromcity?city='+name +'&state=' + state,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }

  uploadcareerdata(formdata: any){
      return this.http.post<any>(this.baseUrl + '/addcareerdata',formdata,
         { headers: new HttpHeaders({'x-api-key':this.studds_unq,  'Content-Type': 'multipart/form-data'}) })
  }
  uploadcontactdata(formdata: any){
    return this.http.post<any>(this.baseUrl + '/addcontactdata',formdata,
       { headers: new HttpHeaders({'x-api-key':this.studds_unq, "Content-Type": "application/json; charset = utf-8;"}) })
  }
  
  addcomment(commentdata: any){
    return this.http.post<any>(this.baseUrl + '/addcomment',commentdata,
       { headers: new HttpHeaders({'x-api-key':this.studds_unq, "Content-Type": "application/json; charset = utf-8;"}) })
  }
  // Review
  productreview(reviewdata){
    return this.http.post<any>(this.baseUrl + '/productreview',reviewdata,
       { headers: new HttpHeaders({'x-api-key':this.studds_unq, "Content-Type": "application/json; charset = utf-8;"}) })
  }
  getproductreview(pid) {
    return this.http.get(this.baseUrl + '/getproductreview?productid='+pid,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }

  accessoryreview(reviewdata){
    return this.http.post<any>(this.baseUrl + '/accessoryreview',reviewdata,
       { headers: new HttpHeaders({'x-api-key':this.studds_unq, "Content-Type": "application/json; charset = utf-8;"}) })
  }
  getaccessoryreview(aid) {
    return this.http.get(this.baseUrl + '/getaccessoryreview?accessoryid='+aid,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }


  getcomments(blogid: any){
    return this.http.get(this.baseUrl + '/getcomments?id='+blogid,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getaccessoryvarient(accessoryid: string){
    return this.http.get(this.baseUrl + '/getaccessoryvarients?id=' + accessoryid,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  uploadnewsletter(formdata: any){
    return this.http.post<any>(this.baseUrl + '/addnewsletter',formdata,
       { headers: new HttpHeaders({'x-api-key':this.studds_unq, "Content-Type": "application/json; charset = utf-8;"}) })
  }
  getoutlets(){
    return this.http.get(this.baseUrl + '/getalloutlet',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getOutletState(){
    return this.http.get(this.baseUrl + '/getoutletstate',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getoutletcitybystate(state:any) {
    return this.http.get(this.baseUrl + '/getoutletcitybystate?state='+state,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getsingleoutlet(city:any) {
    return this.http.get(this.baseUrl + '/getoutletby?city='+city,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getallproduct(){
    return this.http.get(this.baseUrl + '/getallproduct',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getmetadatabypage(index){
    return this.http.get(this.baseUrl + '/getmetadatabypage?id='+index,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getallmanufacturer(){
    return this.http.get(this.baseUrl + '/getallmanufacturer',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getallforsearch(){
    return this.http.get(this.baseUrl + '/getallforsearch',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getproductsfeature(){
    return this.http.get(this.baseUrl + '/getproductsfeature',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getInvesters(){
    return this.http.get(this.baseUrl + '/getinvestordetail',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getCSR(){
    return this.http.get(this.baseUrl + '/getcsractivitydetail',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getfaq(){
    return this.http.get(this.baseUrl + '/getfaq',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getfaqbyid(){
    return this.http.get(this.baseUrl + '/getfaqbyid',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getFooterSettings(){
    return this.http.get(this.baseUrl + '/getfooter',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getFooterSocial(){
    return this.http.get(this.baseUrl + '/getsocial',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getPrivacyPolicy(){
    return this.http.get(this.baseUrl + '/getprivacycontent',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  
  getTermUse(){
    return this.http.get(this.baseUrl + '/gettermsofusecontent',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  
  getCompanySettings(){
    return this.http.get(this.baseUrl + '/getcompanysetting',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getAboutUsPage(){
    return this.http.get(this.baseUrl + '/getaboutuspage',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  gethelmetofthemonth(){
    return this.http.get(this.baseUrl + '/gethelmetofthemonth',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getvideogallery(){
    return this.http.get(this.baseUrl + '/getvideogallery',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getsmkbanner(){
    return this.http.get(this.baseUrl + '/getbanners',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  gethomeslide(){
    return this.http.get(this.baseUrl + '/gethomeslide',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getinvestorcontact(){
    return this.http.get(this.baseUrl + '/getinvestorcontact',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }

  markblogread(id:any){
    return this.http.get(this.baseUrl + '/markblogread?blogid=' + id,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  trendingblogs(){
    return this.http.get(this.baseUrl + '/trendingblogs',
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }

  getDynamicpage(name:any){
    return this.http.get(this.baseUrl + '/getdynamicpage?pagename=' + name,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }

  getGallery(type:any){
    return this.http.get(this.baseUrl + '/getgallery?type=' + type,
    { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }
  getJSON(){
    return this.http.get('https://api.db-ip.com/v2/free/self'
    // { headers: new HttpHeaders({'x-api-key':this.studds_unq}) }
    )
  }

}
