import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, RouterModule, Routes } from '@angular/router';
import { ApiService } from '../api.service';
import { Title, Meta } from '@angular/platform-browser';
import { CanService } from '../can.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    public href: any;
    public pathCount: any;
    public url1: boolean;
    public route: string;
    public category: any;
    public accessories: any;
    public cat_url : any;
    public acc_url : any;
    public catLen: number;
    public accLen: number;
    searchOpened: boolean = false;
    resultOpened: boolean = false;
    public allProducts: any;
    public sortedProducts: any;
    public sortedAccessories: any;
    public prod_url: any;
    public alldata: any;
    public metatags: any
    public mainCats: any = [];
    public subCats: any = [];
    companySetting: any;
    companyLogo: any;
    desktop:any;
    scrollValue: number = 400;
    scrolled2: number = 0;
    
    constructor(private CanService: CanService, location: Location, private router: Router, private data: ApiService, private titleService: Title, private meta: Meta) {
      router.events.subscribe((val) => {
        if(location.path() == ''){
          this.url1 = true;
        } else {
          this.url1 = false;
        }
      });
     // console.log(location.path());
      this.href = location.path();
      this.pathCount = this.href.split('/').length - 1;
    }

  ngOnInit() {
    if(window.innerWidth>1440) {
      this.scrollValue = 800;
    }
    this.CanService.createCanonicalURL();
    let winWidth = window.innerWidth;
    if(winWidth > 575) {
      this.desktop = true;
    }
    else {
      this.desktop = false;
    }
   // console.log(this.pathCount);
    //if(this.pathCount==3) {
      if(this.href != ''){
        if(this.href.indexOf('blogs')!==-1) {
          this.href = 'blogs';
        }
        else {
          this.href= this.href.substr(1);
        }
        // console.log(this.href);
      }else{
        this.href = 'index';
      }
      $(document).on('click', '.dropdown-menu', function (e) {
        e.stopPropagation();
      });
      
      
      this.data.getmetadatabypage(this.href).subscribe(data=> {
        this.metatags = data;
       // console.log(this.metatags)
        if(this.metatags.length>0) {
          this.titleService.setTitle(this.metatags[0].title);
         // this.meta.updateTag({ name: 'description', content: this.metatags[0].description });
        //this.meta.updateTag({ name: 'keywords', content: this.metatags[0].keywords });
          this.meta.addTags([
            {name: 'description', content: this.metatags[0].description},
            {name: 'keywords', content: this.metatags[0].keywords},

            {name: 'twitter:card',content: 'Summary'},
            {name: 'twitter:site', content: '@StuddsHelmet'},
            {name: 'twitter:title', content: this.metatags[0].title},
            {name: 'twitter:description', content: this.metatags[0].description},
            {name: 'twitter:image', content: 'https://www.studds.com/assets/images/logo-red.png'},
            {property: 'og:title', content: this.metatags[0].title},
            {property: 'og:description', content: this.metatags[0].description},
            {property: 'og:image', content: 'https://www.studds.com/assets/images/logo-red.png'},
          ]);
        }
      })
   // }
   
    this.data.getCompanySettings().subscribe(data=> {
      this.companySetting = data;
      this.companyLogo = this.data.baseImageUrl+this.companySetting[0].logo;
    })
    this.data.getCategory().subscribe(data=> {
      this.category = data;
      
      this.category.forEach((single, i)=>{
        let main_url = single.name.toLowerCase().replace(/\s+/g, '-');
        single.mainurl = main_url;
        single.category.forEach(single2 => {
          let cat_url = single2.name.toLowerCase().replace(/\s+/g, '-');
          single2.catnameurl = cat_url;
          if(this.desktop == true){
            single2.image = this.data.baseImageUrl+single2.image;
          }
        });
        single.category.sort((a,b)=>{
          return a.menu_position - b.menu_position;
        })
        const picked1 = (({ name, mainurl }) => ({ name, mainurl }))(single);
        const picked2 = (({ category }) => ({ category }))(single);
        this.mainCats[i] = picked1;
        this.subCats[i] = picked2.category;
      })
      
      
    })

    window.addEventListener('click', (event:any)=>{ 
      if (document.getElementById('searchForm').contains(event.target) || document.getElementById('formTrigger').contains(event.target)){
        return false;
      } else{
        this.searchOpened = false;
      }
    });
  }
  openSearchForm(){
    this.searchOpened = true;
  }
  showresults(eve:any) {
    eve = eve.toLowerCase();
    this.data.getallforsearch().subscribe(data=> {
      this.alldata = data;
      
      this.allProducts = this.alldata.Product;
      this.allProducts.forEach(function(accnames){
        let accname_url = accnames.name.toLowerCase().replace(/\s+/g, '-');
        let sorted_name = accnames.name.toLowerCase();
        accnames.url = accname_url;
        accnames.sortedName = sorted_name;
      })
      
      
      if(eve!='') {
        this.resultOpened = true;
        this.allProducts = this.allProducts.filter(item=>{
          return item.sortedName.search(eve) > -1;
        })
        this.allProducts.forEach(single=>{
         // console.log(single.categoryname);
          if(single.categoryname == 'Motorcycle Accessories'){
            single.categoryname ='motorcycle-accessory'
          }
          if(single.categoryname == 'Helmets'){
            single.categoryname ='helmet';
          }
          single.url = (single.categoryname.toLowerCase().replace(/\s+/g, '-') +'/'+ single.subcategoryname.toLowerCase().replace(/\s+/g, '-') +'/'+ single.name.toLowerCase().replace(/\s+/g, '-')).replace(/&/gi, "and");
          // single.url = single.url.replace(/&/gi, "and");
        })
       // console.log(this.allProducts);
      }
      else {
        this.resultOpened = false;
      }
    })
  }
  switchCats(id:string, e:any) {
    // console.log(id);
    $('.mega-menu .trigger-nav a.active').removeClass('active');
    e.target.classList.add('active');
    $('.mega-showcase-wrap').hide();
    $('#'+id).show();
  }
  moveDot(e:any) {
    $('.mega-showcase-wrap .mega-showcase .mega-showcase-item.active').removeClass('active');
    e.target.classList.add('active');
    let parentOffset = e.target.parentElement.offsetLeft;
    let currentOffset = e.target.offsetLeft;
    let finalOffset = currentOffset - parentOffset - 75;
    $('.lighten-item').css('left', finalOffset);
  }
  headPrev() {
    if(this.scrolled2 == this.scrollValue) {
      this.scrolled2=this.scrolled2-this.scrollValue;
        $(".mega-showcase").animate({
            scrollLeft: this.scrolled2
        });
        $('.mega-prev1').css('opacity','0');
        $('.mega-next1').css('opacity','1');
    }
    else {
        return false;
    }    
  }
  headNext() {
    if(this.scrolled2 == 0) {
      this.scrolled2=this.scrolled2+this.scrollValue;
        $(".mega-showcase").animate({
            scrollLeft: this.scrolled2
        });
        $('.mega-prev1').css('opacity','1');
        $('.mega-next1').css('opacity','0');
    }
    else {
        return false;
    }    
  }
  customLevel(index) {
    $('.mega-dropdown>.dropdown>.dropdown-menu').removeClass('show');
    document.getElementById('customdropdown'+index).classList.toggle('show');
  }
  
}
