import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { AgmCoreModule, AgmInfoWindow } from '@agm/core';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  category : any;
  country: any;
  states: any;
  city: any;
  address: any;
  lat: any;
  lng: any;
  zoom:any;
  latitude:any
  longitude:any
  center: object;
  mapLoad: boolean = false;
  cityFound: boolean = false;
  cityNotFound: boolean = false;
  stateFound: boolean = true;
  stateNotFound: boolean = false;
  mapdataRecieved: boolean = false;
  helmetOfTheMonth: any;
  videoGallery: any;
  banners: any;
  accessGlobal: any;
  homeSlides: any;
  homeSlides1: any;
  homeSlides2: any;
  homeSlides3: any;
  homeImage1: string;
  homeImage2: string;
  homeImage3: string;
  homeImage4: string;
  homeImage5: string;
  homeImage6: string;
  resized: boolean = true;
  homeBlogs: any;
  desktop: boolean;
  ifGetDirection:any;
  origin:any
  destination:any
  mylat:any;
  mylng:any;
  previous;
  agmFitBounds:boolean = true;
  fitBounds:boolean = true;
  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  homeloaded:boolean = false;
  mediaLoader: boolean = true;
  vidMuted : boolean = false;
  motoAccess: any;
  socialLink:any;
  // Slider Options
  activeSlides: SlidesOutputData;
  galleryOptions: OwlOptions = {
    autoplay: true,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    smartSpeed: 1000,
    center: true,
    margin: 10,
    autoplayHoverPause: true,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1,
        autoplay: false
      },
      400: {
        items: 1,
        autoplay: false
      },
      768: {
        items: 1,
        autoplay: false
      },
      992: {
        items: 3,
        autoplay: true
      }
    },
    nav: true
  };
  blogOptions: OwlOptions = {
    autoplay: true,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    smartSpeed: 1000,
    center: true,
    margin: 10,
    autoplayHoverPause: true,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1,
        autoplay: true
      },
      550: {
        items: 3,
        autoplay: true
      },
      768: {
        items: 3,
        autoplay: true
      },
      992: {
        items: 3,
        autoplay: true
      }
    },
    nav: true
  };
  bannerOptions: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 3000,
    items: 1,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 0,
    center: true,
    margin: 17,
    autoplayHoverPause: false,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    nav: false,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn'
  };
  featuredOptions: OwlOptions = {
    autoplay: true,
    items: 1,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    autoplayTimeout: 3000,
    autoplaySpeed: 700,
    navSpeed: 1000,
    margin: 10,
    autoplayHoverPause: true,
    nav: false
  };
  helmetSlider: OwlOptions = {
    autoplay: true,
    items: 1,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    autoplayTimeout: 6000,
    autoplaySpeed: 700,
    navSpeed: 1000,
    margin: 30,
    autoplayHoverPause: true,
    nav: false,
  };

  constructor(private data: ApiService, private gmaps: AgmCoreModule,private router: Router,location: Location) {
    router.events.subscribe((val) => {
      if(location.path() == ''){
        this.url1 = true;
      } else {
        this.url1 = false;
      }
    });
    this.href = location.path();
    this.pathCount = this.href.split('/').length - 1;
   }


  ngOnInit() {
    //$("#b_vid").prop('muted', false);
    // if( $("video").prop('unmuted') ){
    //   this.vidMuted = false;
    // }

    if(this.href=='' || this.href == 'index') {
      this.href= 'index';
      this.data.getmetadatabypage(this.href).subscribe(data=> {
        this.titles = data;
        if(this.titles.length>0) {
          this.pageTitle=this.titles[0].pagetitle;
          this.pageDescription=this.titles[0].pagedescription;
        }
      })
    }

    $("video").prop('muted', true);

    $("#mute-video").click( function (){
      if( $("video").prop('muted') ) {
            $("video").prop('muted', false);
      } else {
        $("video").prop('muted', true);
      }
    });

    this.mapLoad = false;
    $('.custom-bread-crumb').hide();
    let winWidth = window.innerWidth;
    if(winWidth>575) {
      this.desktop = true;
    }
    else {
      this.desktop = false;
    }
    window.addEventListener('resize',()=>{
      let newWinWidth = window.innerWidth;
      // console.log(newWinWidth);
      if(winWidth!=newWinWidth){
        winWidth = newWinWidth;
        this.resized = false;
        setTimeout(()=>{
          this.resized = true;
        },300)
      }
      if(winWidth>575) {
        this.desktop = true;
      }
      else {
        this.desktop = false;
      }
    })

    this.data.getCategory().subscribe(data=> {
      this.category = data;
      this.category = this.category[0].category;
      // console.log(this.category);
      this.homeloaded = true;
      this.category.forEach(single => {
        single.image = this.data.baseImageUrl + single.image;
      });
      this.homeImage1 = this.category[0].image;
      this.homeImage2 = this.category[1].image;
      this.homeImage3 = this.category[2].image;
      this.homeImage4 = this.category[3].image;
      this.homeImage5 = this.category[4].image;
      this.homeImage6 = this.category[5].image;
    })
    this.data.getsmkbanner().subscribe(data=> {
      this.mediaLoader = false;
      this.banners = data;
      this.banners.forEach(single => {
        single.banner = this.data.baseImageUrl + single.banner;
        single.m_banner = this.data.baseImageUrl + single.m_banner;
      });
      //console.log(this.banners);
    })
    this.data.getFooterSocial().subscribe(data=>{
      this.socialLink = data;
    })

    this.data.getaccessories().subscribe(data=>{
      this.motoAccess=data;
      this.motoAccess.forEach(single=>{
        single.icons = this.data.baseImageUrl + single.icons;
        single.purl = single.pname.toLowerCase().replace(/\s+/g, '-');
        single.url = single.name.toLowerCase().replace(/\s+/g, '-');
      })
    })

    this.lat = 20.5937;
    this.lng = 78.9629;
    this.center = {
      "latitude": 21.582753,
      "longitude": 75.9165938
    }

    this.data.gethelmetofthemonth().subscribe(data=> {
      this.helmetOfTheMonth = data;
      // console.log(this.helmetOfTheMonth);
      this.helmetOfTheMonth.forEach(single => {
        single.mainpicture = this.data.baseImageUrl + single.mainpicture;
      });
    })

  $( document ).ready( ()=> {
      window.addEventListener('scroll', (e)=> {

        if( this.isOnScreen( $( '.special-month' ) ) ) { /* Pass element id/class you want to check */
          if($('.special-month').hasClass('active')){
            return false;
          }else{
            $('.special-month').addClass('active');
            this.data.getblogs().subscribe(data=>{
              this.homeBlogs = data;
              this.homeBlogs.forEach(single => {
                single.thumbnail = this.data.baseImageUrl + single.thumbnail;
                let blg_url = single.blog_url.toLowerCase().replace(/\s+/g, '-');
                single.url = 'blog/'+blg_url;
              });
            })
          }
        }	
        
        if( this.isOnScreen( $( '.access-sec' ) ) ) { /* Pass element id/class you want to check */
          if($('.access-sec').hasClass('active')){
            return false;
          }else{
            this.data.getvideogallery().subscribe(data=> {
              $('.access-sec').addClass('active');
              this.videoGallery = data;
              this.videoGallery.forEach(single => {
                single.video_src = this.data.baseImageUrl + single.video_src;
              // single.iframe = "https://www.youtube.com/embed/" + single.iframe +"?enablejsapi=1&mute=1";
              });
            })
          }
        }	
        if( this.isOnScreen( $( '.gallery-sec' ) ) ) { /* Pass element id/class you want to check */
          if($('.gallery-sec').hasClass('active')){
            return false;
          }else{
            $('.gallery-sec').addClass('active');
              this.data.getcountry().subscribe(data=> {
              this.country = data;
            })
          }
        }

        if( this.isOnScreen( $( '.dealer-sec' ) ) ) { /* Pass element id/class you want to check */
          if($('.dealer-sec').hasClass('active')){
            return false;
          }else{
            
         }
        }	
      });
      
    });
  }
  getstates(ct:any) {
    this.mapdataRecieved = true;
    this.mapLoad = false;
    this.data.getstates(ct).subscribe(data=> {
      this.states = data;
      this.cityFound = false;
      //console.log(this.states);
      if(this.states.length>0) {
        this.stateFound = true;
        this.stateNotFound = false;
      }
      if(this.states.length == 0) {
        this.stateNotFound = true;
        this.stateFound = false;
      }
      this.mapdataRecieved = false;
    })
  }
  getcities(st:any) {
    this.mapdataRecieved = true;
    this.data.getcityfromstate(st).subscribe(data=> {
      this.city = data;
      if(this.city.length>0) {
        this.cityFound = true;
        this.cityNotFound = false;
      }
      if(this.city.length == 0) {
        this.cityNotFound = true;
        this.cityFound = false;
      }
      this.mapdataRecieved = false;
    })
  }
  getaddress(ct:any,st:any) {
    this.mapdataRecieved = true;
    this.data.getaddressfromcity(ct,st).subscribe(data=> {
      this.address = data;
      this.lat = this.address[0].latitude;
      this.lng = this.address[0].longitude;
      this.zoom = 13;
      this.mapLoad = true;
      this.mapdataRecieved = false;
    })
    
  }

  getPassedData(data: SlidesOutputData) {
    this.activeSlides = data;

    var currentSlide = $('.gallery-slider .owl-item.center');
    var prevslide = $('.gallery-slider .owl-item').not('.center');
    var player1 = currentSlide.find("iframe").get(0);
    // var player2 = prevslide.find("iframe").get(0);
    var command1 = {
      "event": "command",
      "func": "playVideo"
    };
    var command2 = {
      "event": "command",
      "func": "pauseVideo"
    };
  }



  getlatlong(lat,lng){
    this.ifGetDirection = true;
    this.origin = { lat: +this.mylat, lng: +this.mylng };
    this.destination = { lat: +lat, lng: +lng };
    this.mapLoad = true;
    this.agmFitBounds = true;
    this.fitBounds = true;
    this.zoom = 10;
    $( ".gm-style-iw" ).css( "display", "none" );
   
  }

  openedWindow : number = 0; // alternative: array of numbers

  clickedMarker(infowindow, markerid:any) {
    this.openedWindow = markerid;
    if (this.previous) {
        this.previous.close();
    }
    this.previous = infowindow;
 }
 
isInfoWindowOpen(id) {
    return this.openedWindow == id; // alternative: check if id is in array
}

 isOnScreen(elem) {
	// if the element doesn't exist, abort
	if( elem.length == 0 ) {
		return;
	}
	var $window = $(window)
	var viewport_top = $window.scrollTop()
	var viewport_height = $window.height()
	var viewport_bottom = viewport_top + viewport_height
	var $elem = $(elem)
	var top = $elem.offset().top
	var height = $elem.height()
	var bottom = top + height

	return (top >= viewport_top && top < viewport_bottom) ||
	(bottom > viewport_top && bottom <= viewport_bottom) ||
	(height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
}


mute(){
  let bVid = document.getElementById('b_vid') as HTMLVideoElement;
  bVid.muted = true;
  this.vidMuted = false;
}
unmute(){
  let bVid = document.getElementById('b_vid') as HTMLVideoElement;
  bVid.muted = false;
  this.vidMuted = true;
}



}
