import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-investor-relations',
  templateUrl: './investor-relations.component.html',
  styleUrls: ['./investor-relations.component.css']
})
export class InvestorRelationsComponent implements OnInit {
  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  investorcontacts: any;
  mediaLoader: boolean = false;
  constructor(location: Location, private router: Router, private data: ApiService) {
    router.events.subscribe((val) => {
      if(location.path() == ''){
        this.url1 = true;
      } else {
        this.url1 = false;
      }
    });
    this.href = location.path();
    this.pathCount = this.href.split('/').length - 1;
  }

  investerArray: any;
  ngOnInit() {
    this.mediaLoader = true;
    if(this.pathCount==1) {
      this.href= this.href.substr(1);
      this.data.getmetadatabypage(this.href).subscribe(data=> {
        this.titles = data;
        if(this.titles.length>0) {
          this.pageTitle=this.titles[0].pagetitle;
          this.pageDescription=this.titles[0].pagedescription;
        }
      })
    }
    this.data.getinvestorcontact().subscribe(data=>{
      this.investorcontacts = data;
    if(this.investorcontacts.length == 0){
      this.investorcontacts = 0;
    }
     // console.log(this.investorcontacts);
    })
    this.data.getInvesters().subscribe(data=> {
      this.investerArray = data;
      this.investerArray = Object.keys( this.investerArray).map(key =>  this.investerArray[key])
      this.investerArray.forEach(single => {
        single.pdfs.forEach(double=>{
          double.pdflinks = this.data.baseImageUrl+double.pdflinks;
        })
      });
      // console.log(this.investerArray);
      this.mediaLoader = false;
    })
  }
  toggleInvesters(eve , id){
    let allnavs = document.querySelectorAll('.investor-nav > li');
    let allinvesters = document.querySelectorAll('.investor-details');
    allinvesters.forEach(element => {
      element.setAttribute('style','display: none');
      element.classList.remove('opened');
    });
    document.getElementById(id).style.display = 'block';
    document.getElementById(id).classList.add('opened');
    allnavs.forEach(element => {
      element.classList.remove('active');
    });
    eve.target.classList.add('active');
  }
  toggleBody(e, id) {
    let allindet = document.querySelectorAll('.investor-detail.opened .investor-single');
    // let current = document.getElementById('indet-'+id);
    allindet.forEach(single=>{
      single.querySelector('.investor-body')[0].classList.remove('active');
    })
    e.target.classList.toggle('active');
  }

  openPop():void{
    let vid = <HTMLIFrameElement> document.getElementById('popvid');
    // vid.src += "&autoplay=1";
    document.getElementById('vidpop').classList.add('opened');
  }
  closePop():void{
    let vid = <HTMLIFrameElement> document.getElementById('popvid');
    vid.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
    // vid.src = vid.src.substring(0, vid.src.length-11);
    document.getElementById('vidpop').classList.remove('opened');
  }

}
