import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {NgxPaginationModule} from 'ngx-pagination';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  galleryItems: any;
  popupdiv: any;
  gallery:any;
  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  p: number = 1;
  detail:any;
  mediaLoader: boolean = true;
  galleryLoader: boolean = true;
  type:any;
  constructor( location: Location,private data: ApiService, private router: Router) {
    router.events.subscribe((val) => {
    if(location.path() == ''){
      this.url1 = true;
    } else {
      this.url1 = false;
    }
  });
  this.href = location.path();
  this.pathCount = this.href.split('/').length - 1; }

  ngOnInit() {
    if(this.pathCount==1) {
      this.href= this.href.substr(1);
      this.data.getmetadatabypage(this.href).subscribe(data=> {
        this.titles = data;
        if(this.titles.length>0) {
          this.pageTitle=this.titles[0].pagetitle;
          this.pageDescription=this.titles[0].pagedescription;
        }
      })
    }

    this.galleryItems = document.querySelectorAll('#customgallery1 .gallery-single');
    this.popupdiv = document.createElement('div');
    this.popupdiv.classList.add('popupdiv');
    this.popupdiv.innerHTML = '<div class="popupdiv-inner"><div class="popup-img"><img src=""></div><div class="popup-list"></div></div>';
    this.galleryItems.forEach((item:any)=>{
    })
    
    this.data.getGallery('Image').subscribe(data=>{
        this.gallery = data;
       // console.log(this.gallery);
       this.type = 'Image';
        this.mediaLoader = false;
        this.galleryLoader = false;
        this.gallery.forEach(single=>{
          if(single.type == 'Image' || single.type == 'Mp4 Video'){
            single.data = this.data.baseImageUrl + single.data;
          }
        })
    })
  }

  openDetails(event: any,img:any,title:any,alt_tag:any,desc:any) {
    document.getElementById('heading').innerHTML = title;
    document.getElementById('details').innerHTML = desc;
    document.getElementById('image').setAttribute( 'src', img);
    document.getElementsByClassName('careerdetailpopup')[0].classList.add('active');
  }
  closeDetails(){
    document.getElementsByClassName('careerdetailpopup')[0].classList.remove('active');
  }

  getGallery(type:any){
    this.galleryLoader = true;
    this.data.getGallery(type).subscribe(data=>{
      this.gallery = data;
      this.type = type;
     // console.log(this.gallery);
      this.mediaLoader = false;
      this.galleryLoader = false;
      this.gallery.forEach(single=>{
        if(single.type == 'Image' || single.type == 'Mp4 Video'){
          single.data = this.data.baseImageUrl + single.data;
        }
      })
  })
  }

  openPop():void{
    let vid = <HTMLIFrameElement> document.getElementById('popvid');
    // vid.src += "&autoplay=1";
    document.getElementById('vidpop').classList.add('opened');
  }
  closePop():void{
    let vid = <HTMLIFrameElement> document.getElementById('popvid');
    vid.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
    // vid.src = vid.src.substring(0, vid.src.length-11);
    document.getElementById('vidpop').classList.remove('opened');
  }

}
