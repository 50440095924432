import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';


@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  category : any;
  firstid: any;
  secondid: any;
  urlName: any;
  sub: any;
  categoryname: any;
  categoryMeta: any;
  sortedcat: any;
  cat_url: any;
  categorycheck: boolean= false;
  categoryproduct: boolean= false;
  accessGlobal: any;
  mediaLoader: boolean = false;
  categoryforurl:any;
  pageTitle:any;
  pageDescription:any;
  url1: any;
  href: any;
  pathCount: any;
  subPath: any;
  titles: any;
  mainTitle: any;
  mainDesc: any;
  metaDetails: any;
  allCats: any;
  showAccess: boolean = false;
  motoAccess: any;
  parentcategory:any;
  isHelmet: boolean;
  constructor(location: Location, private router: Router,private data: ApiService, private route: ActivatedRoute, private titleService: Title, private meta: Meta, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) {
    router.events.subscribe((val) => {
      if(location.path() == ''){
        this.url1 = true;
      } else {
        this.url1 = false;
      }
    });
    this.href = location.path();
    this.pathCount = this.href.split('/').length - 1;
    this.subPath = this.href.split('/')[2];
   }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.firstid = params['catname'];
    });
    this.mediaLoader = true;
    // this.data.getaccessorycategory().subscribe(data=> {
    //   this.accessGlobal = data;
    //   this.accessGlobal.forEach(single => {
    //     single.icons = this.data.baseImageUrl + single.icons;
    //     single.url = single.name.toLowerCase().replace(/\s+/g, '-');
    //   });
    // })

    
    if (this.pathCount==1) {
      const breadcrumb =  {dynamicCat: this.firstid.replace(/-/g, ' ')};
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
      this.categorycheck = true;
      this.data.getmetadatabypage(this.firstid).subscribe(data=>{
        this.metaDetails = data;
        this.metaDetails = this.metaDetails[0];
        
        this.titleService.setTitle(this.metaDetails.title);
        this.meta.addTags([
          {name: 'description', content: this.metaDetails.description},
          {name: 'keywords', content: this.metaDetails.keywords},

          {name: 'twitter:card',content: 'Summary'},
          {name: 'twitter:site', content: '@StuddsHelmet'},
          {name: 'twitter:title', content: this.metaDetails.title},
          {name: 'twitter:description', content: this.metaDetails.description},
          {name: 'twitter:image', content: 'https://www.studds.com/assets/images/logo-red.png'},
          {property: 'og:title', content: this.metaDetails.title},
          {property: 'og:description', content: this.metaDetails.description},
          {property: 'og:image', content: 'https://www.studds.com/assets/images/logo-red.png'},
        ]);
      })

      this.data.getCategoryByName(this.firstid).subscribe(data=> {
        this.parentcategory = data;
        this.mainDesc = this.parentcategory[0].description;
      })

      this.data.getSubCategoryByName(this.firstid).subscribe(data=> {
        this.category = data;
        
        if(this.category.length == 0){
          window.location.href="404";
        }
        this.mediaLoader = false;
        
        this.cat_url = {};
        this.category.forEach(catnames=>{
          let prodname_url = catnames.name.toLowerCase().replace(/\s+/g, '-');
          catnames.producturl = prodname_url;
          catnames.image = this.data.baseImageUrl+catnames.image;
        })
        this.category.sort((a,b)=>{
          return (a.menu_position - b.menu_position);
        })
        // console.log(this.category);
      })
      this.mainTitle = this.firstid.toUpperCase().replace(/-/g, ' ');
      if(this.firstid.toLowerCase()=='helmets') {
        this.isHelmet = true;
      }
      else {
        this.isHelmet = false;
      }
     
    }
    else if (this.pathCount==2) {
      this.secondid = this.subPath;
      const breadcrumb =  {dynamicProd: this.secondid.replace(/-/g, ' '), dynamicCat: this.firstid.replace(/-/g, ' ')};
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
      if(this.firstid == 'helmets'){
        this.firstid = 'helmet';
      }
      if(this.firstid == 'motorcycle-accessories'){
        this.firstid = 'motorcycle-accessory';
      }
      
     
      this.categoryproduct = true;
      this.data.getproductsbyname(this.secondid).subscribe(data=>{
        this.category = data;
        if(this.category.length == 0){
          window.location.href="404";
        }
        let details = this.category.Category[0];
        this.category = this.category.Products;
        this.category.sort((a,b)=>{
          return (a.position - b.position);
        })
        //console.log(this.category);
        this.titleService.setTitle(details.meta_title);
        this.meta.addTags([
          {name: 'description', content: details.meta_description},
          {name: 'keywords', content: details.meta_keywords},

          {name: 'twitter:card',content: 'Summary'},
          {name: 'twitter:site', content: '@StuddsHelmet'},
          {name: 'twitter:title', content: details.meta_title},
          {name: 'twitter:description', content: details.meta_description},
          {name: 'twitter:image', content: 'https://www.studds.com/assets/images/logo-red.png'},
          {property: 'og:title', content: details.meta_title},
          {property: 'og:description', content: details.meta_description},
          {property: 'og:image', content: 'https://www.studds.com/assets/images/logo-red.png'},
        ]);
        this.mediaLoader = false;
        this.category.forEach(catnames=>{
          let prodname_url = catnames.name.toLowerCase().replace(/\s+/g, '-').replace(/&/gi, "and");
          catnames.producturl = prodname_url;
          catnames.image = this.data.baseImageUrl+catnames.image;
        })
        this.mainTitle =  details.name;
        this.mainDesc = details.pageDescription;
      })
    }
    this.data.getCategory().subscribe(data=> {
      this.allCats = data;
      this.allCats.forEach(single=> {
        let urltomatch = single.name.toLowerCase().replace(/\s+/g, '-');
        if(urltomatch==this.firstid && single.show_acc==1) {
          this.showAccess = true;
          this.data.getaccessories().subscribe(data=>{
            this.motoAccess=data;
            this.motoAccess.forEach(single=>{
              single.icons = this.data.baseImageUrl + single.icons;
              single.purl = single.pname.toLowerCase().replace(/\s+/g, '-');
              single.url = single.name.toLowerCase().replace(/\s+/g, '-');
            })
          })
          return;
        }
      })
     // console.log(this.showAccess);
    })
    
   
  }
  sortIt(val) {
    this.mediaLoader = true;
    this.data.getallProducts(this.secondid).subscribe(data=> {
      this.category = data;
      if(val === "lowtohigh") {
        this.sortedcat = this.category.sort((a,b) => parseFloat(a.price) - parseFloat(b.price));
      }
      else if(val === "hightolow") {
        this.sortedcat = this.category.sort((b,a) => parseFloat(a.price) - parseFloat(b.price));
      }
      else if(val === "asc") {
        this.sortedcat = this.category.sort((a,b) => a.name - b.name);
      }
      else if(val === "desc") {
        this.sortedcat = this.category.reverse();
      }
      else {
        this.sortedcat = this.category.sort((a,b) => parseFloat(a.price) - parseFloat(b.price));
      }
      this.sortedcat.forEach(catnames=>{
        let prodname_url = catnames.name.toLowerCase().replace(/\s+/g, '-');
        let catname_url = catnames.categoryname.toLowerCase().replace(/\s+/g, '-');
        catnames.producturl = prodname_url;
        catnames.categoryurl = catname_url;
        catnames.image = this.data.baseImageUrl+catnames.image;
      })
      this.mediaLoader = false;
    })
  }
  openKnow() {
    let popup = document.getElementById('know-popup');
    popup.classList.add('opened');
  }
  closeKnow() {
    let popup = document.getElementById('know-popup');
    popup.classList.remove('opened');
  }
  

}
