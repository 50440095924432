import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  contactData: any;
  contactForm: FormGroup;
  formSuccess: boolean;
  response: any;
  emptyContact: boolean = false;
  loader:boolean=false;
  hide:boolean=true;

  url1: any;
  href: any;
  pathCount: any;
  titles: any;
  pageTitle: any;
  pageDescription: any;
  mediaLoader: boolean = false;
  constructor(location: Location, private router: Router, private data: ApiService, private fb: FormBuilder) {
    router.events.subscribe((val) => {
      if(location.path() == ''){
        this.url1 = true;
      } else {
        this.url1 = false;
      }
    });
    this.href = location.path();
    this.pathCount = this.href.split('/').length - 1;
  }
  companySetting: any;

  ngOnInit() {
    this.mediaLoader = true;
    if(this.pathCount==1) {
      this.href= this.href.substr(1);
      this.data.getmetadatabypage(this.href).subscribe(data=> {
        this.titles = data;
        if(this.titles.length>0) {
          this.pageTitle=this.titles[0].pagetitle;
          this.pageDescription=this.titles[0].pagedescription;
        }
      })
    }
    this.data.getCompanySettings().subscribe(data=> {
      this.companySetting = data;
      this.mediaLoader = false;
    })
    this.contactForm = this.fb.group({
      name : ['', Validators.required],
      email : ['', [Validators.required]],
      country : ['', Validators.required],
      state : ['', Validators.required],
      city : ['', Validators.required],
      phone : ['', [Validators.required,Validators.max(1000000000000000)]],
      feedback: ['']
    })
  }
  contactSubmit(): void{
    this.loader=true;
   // console.log(this.contactForm);
    if(this.contactForm.invalid) {
      this.emptyContact = true;
      setTimeout(function(){
        this.emptyContact = false;
      }.bind(this), 2000)
    }
    else {
      this.hide=false;
      this.data.uploadcontactdata(this.contactForm.value).subscribe(
        (res) => {
          this.loader=false;
          if((res) == 1) {
            this.formSuccess = true;
          }
          else if (res == 0) {
            this.formSuccess = false;
          }
          setTimeout(function(){
            this.hide = true;
            this.contactForm.reset();
          }.bind(this), 2000)
        },
        (err) => console.log(err)
        
      )
    }
    
  }
  openPop():void{
    let vid = <HTMLVideoElement> document.getElementById('popvid');
    // vid.play();
    document.getElementById('vidpop').classList.add('opened');
  }
  closePop():void{
    let vid = <HTMLVideoElement> document.getElementById('popvid');
    vid.pause();
    document.getElementById('vidpop').classList.remove('opened');
  }

}
