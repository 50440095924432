<div class="main-artboard position-relative about-page">
        <div class="floating-rays">
         <img src="assets/images/big-studd.png" class="car-f0 zindex1" alt="big studds">
       </div> 
       <div class="map-loader text-center" *ngIf="mediaLoader==true">
        <span></span>
       </div>
       <section class="award-sec zindex1" *ngIf="mediaLoader==false">
           <div class="container">
               <h1 class="sec-title text-center text-white mb-4">{{pageTitle}}</h1>
               <p style="font-family: 'Roboto', sans-serif; text-align: center; color: #fff; font-size: 1.2em; margin-top: 0; margin-bottom: 2em;" [innerHTML]="pageDescription"></p>
                <div class="col-lg-12 offset-lg-0 col-md-6 col-sm-4 offset-md-3 offset-sm-4">
                    <div class="award-slider text-center text-white" *ngIf="resized">
                        <owl-carousel-o [options]="customOptions" (translated)="getPassedData($event)">
                            <ng-container *ngFor="let award of awards">
                                <ng-template carouselSlide [id]="award.id">
                                    <div class="item">
                                        <img [src]="award.image" alt="{{award.alt_tag}}">
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
               <div class="award-content-wrap text-center text-white mt-4">
                   <ng-container *ngFor="let aw of awards; let i = index">
                    <div class="award-content" id="award{{i}}" [ngStyle]="{'display':i === 1 ? 'block' : 'none' }">
                        <h3 class="award-title">{{aw.title}}</h3>
                        <div class="award-desc">
                            <p>{{aw.desc}}</p>
                        </div>
                    </div>
                   </ng-container>
               </div>
               <div id="award-dots" class="text-center"></div>
           </div>
       </section>
    </div>

    <button class="vid-trigger3" (click)="openPop()">
        <svg height="64" width="140">
            <path d="M62,31 a30,30 1 1,0 -30,30 l100 0" stroke-dasharray="240px" stroke-dashoffset="240px" stroke="#0fd1cf" stroke-width="2" fill="none" />
        </svg>
        <img src="assets/images/studdsbutton2.png"><span>STUDDS In News</span>
    </button>
    <div class="vidpop" id="vidpop">
        <div class="vidpopinner text-center">
            <h4 class="vcaption">Our respected MD, Mr. Sidharth Khurana in a video interview with Jagran HiTech about import from China becoming zero, #Studds new manufacturing units, doubling capacity of motorcycle helmets, export plans, helmet industry approach as well as Covid-19 impact and new smart helmet technology.</h4>
            <div class="wrap">
                <iframe id="popvid" width="100%" height="320" src="https://www.youtube.com/embed/NNRsC0rQodI?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <span class="fa fa-times" (click)="closePop()"></span>
        </div>
    </div>