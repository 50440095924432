import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { isNumber } from 'util';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  blogs: any;
  firstid: any;
  sub: any;
  paginationArray: any = [];
  prevPage: number;
  nextPage: number;
  showPrev: boolean;
  showNext: boolean;
  activatePagination: boolean;
  blogSearchOpened: boolean = false;
  public allBlogs: any;
  blogResultOpened: boolean = false;
  blog_url: any;
  mediaLoader: boolean = false;
  searchedValue:any;
  key:any;
  designsearchtype:any;
  resized: boolean = true;
  trending:any;
  show:any;
  firstActive: boolean = false;

  blogOptions: OwlOptions = {
    autoplay: true,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    smartSpeed: 1000,
    center: true,
    margin: 10,
    autoplayHoverPause: true,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1,
        autoplay: true
      },
      550: {
        items: 3,
        autoplay: true
      },
      768: {
        items: 3,
        autoplay: true
      },
      992: {
        items: 3,
        autoplay: true
      }
    },
    nav: true
  };

  constructor(private data: ApiService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.mediaLoader = true;
    this.route.queryParams.subscribe(params => {
      this.key = params['q'];
    });
    this.sub = this.route.params.subscribe(params => {
      this.firstid = params['blogpageid']; // (+) converts string 'id' to a number
    });

    this.data.trendingblogs().subscribe(data=> {
      this.trending = data;
      this.trending.forEach(single => {
        single.mainpicture = this.data.baseImageUrl+single.mainpicture;
        single.thumbnail = this.data.baseImageUrl+single.thumbnail;
      });
      this.trending.forEach(blgData => {
        let blgurl = blgData.blog_url;
        blgData.url = blgurl;
      });
    })


    if(this.key == undefined){
      this.designsearchtype = false;
      if(this.firstid == '' || this.firstid == undefined ){
        this.firstActive = true;
        this.data.getblogs().subscribe(data=> {
          this.blogs = data;
          // console.log(this.blogs);
          this.blogs.forEach(single => {
            single.mainpicture = this.data.baseImageUrl+single.mainpicture;
            single.thumbnail = this.data.baseImageUrl+single.thumbnail;
          });
          let blgindex = 0;
          let blogcount = 9;
          let blogStart = blgindex * blogcount;
          let pageCount = Math.ceil(this.blogs.length/blogcount);
          if(pageCount > 1) {
            this.nextPage = 2;
            this.showNext = true;
            this.activatePagination = true;
          }
          for(let i = 0; i<pageCount; i++) {
            this.paginationArray[i] = {
              'indexPage': i+1
            };
          }

          this.paginationArray = this.paginationArray.slice(1, pageCount);
          this.blogs = this.blogs.slice(blogStart, blogcount);
          this.blogs.forEach(blgData => {
            let blgurl = blgData.blog_url;
            blgData.excerpt = blgData.excerpt.slice(0, 75) + '...';
            blgindex++;
            blgData.blogUrl = blgurl;
            blgData.blogindex = blgindex;
          });
          this.mediaLoader = false;
        })
      }
      else {
        
        this.data.getblogs().subscribe(data=> {
          if(this.firstid==1) {
            this.firstActive = true;
            this.showPrev = false;
          }
          else {
            this.showPrev = true;
          }
          this.prevPage = parseInt(this.firstid) - 1;
          this.nextPage = parseInt(this.firstid) + 1;
          this.blogs = data;
          this.blogs.forEach(single => {
            single.mainpicture = this.data.baseImageUrl+single.mainpicture;
            single.thumbnail = this.data.baseImageUrl+single.thumbnail;
          });
          let blgindex = 0;
          let blogIndex = this.firstid - 1;
          let blogcount = (9 * (this.firstid-1))-1;
          let blogStart = (blogIndex * 9) + 1;
          let blogEnd = blogStart + 9;
          let pageCount = Math.ceil(this.blogs.length/8);
          if(pageCount > 1) {
            this.activatePagination = true;
          }
          if (this.nextPage <= pageCount) {
            this.showNext = true;
          }
          let current = location.pathname;
          for(let i = 0; i<pageCount; i++) {
            if(('/blogs/'+(i+1)).indexOf(current)!==-1) {
              this.paginationArray[i] = {
                'indexPage': i+1,
                'active': true
              };
            }
            else {
              this.paginationArray[i] = {
                'indexPage': i+1,
                'active': false
              };
            }
            
          }
          this.paginationArray = this.paginationArray.slice(1, pageCount);
          this.blogs = this.blogs.slice(blogStart, blogEnd);
          this.blogs.forEach(blgData => {
            let blgurl = blgData.blog_url;
            blgData.excerpt = blgData.excerpt.slice(0, 75) + '...';
            blgindex++;
            blgData.blogUrl = blgurl;
            blgData.blogindex = blgindex;
          });
          this.mediaLoader = false;
        })
      } 
         
    }
    else{
      this.designsearchtype = true;
      this.data.getrelatedblogs(this.key).subscribe(data=> {
        this.blogs = data;
        //console.log(this.blogs.length);
        if(this.blogs.length == 0){
          this.show = false;
        }else {
          this.show = true;
        }
        //console.log(this.show)
        this.blogs.forEach(single => {
          single.mainpicture = this.data.baseImageUrl+single.mainpicture;
          single.thumbnail = this.data.baseImageUrl+single.thumbnail;
        });
        let blgindex = 0;
        let blogcount = 9;
        let blogStart = blgindex * blogcount;
        let pageCount = Math.ceil(this.blogs.length/blogcount);
        if(pageCount > 1) {
          this.nextPage = 2;
          this.showNext = true;
          this.activatePagination = true;
        }
        for(let i = 0; i<pageCount; i++) {
          this.paginationArray[i] = {
            'indexPage': i+1
          };
        }
        this.paginationArray = this.paginationArray.slice(1, pageCount);
        console.log(this.paginationArray);
        this.blogs = this.blogs.slice(blogStart, blogcount);
        this.blogs.forEach(blgData => {
          let blgurl = blgData.blog_url;
          blgData.excerpt = blgData.excerpt.slice(0, 175) + '...';
          blgindex++;
          blgData.blogUrl = blgurl;
          blgData.blogindex = blgindex;
        });
        this.mediaLoader = false;
        this.blogResultOpened = false;
      })
    }

    
  }
  showblogresults(eve:any) {
    eve = eve.toLowerCase();
    this.searchedValue = eve;
    this.data.getblogs().subscribe(data=> {
      this.allBlogs = data;
      this.blog_url = {};
      this.allBlogs.forEach(function(accnames){
        let accname_url = accnames.title.toLowerCase().replace(/\s+/g, '-');
        let sorted_name = accnames.title.toLowerCase();
        accnames.url = accname_url;
        accnames.sortedName = sorted_name;
        accnames.sortedName = sorted_name;
        if(accnames.tags!=null) {
          accnames.tags = accnames.tags.split(',');
        }
      })
      if(eve!='') {
        this.blogResultOpened = true;
        this.allBlogs = this.allBlogs.filter(item=>{
          let tagMatched = false;
          if(item.tags!=null){
            item.tags.forEach(singleTag => {
              if(singleTag.toLowerCase().search(eve) > -1){
                tagMatched = true;
              }
            });
          }
          if(item.sortedName.search(eve) > -1 || tagMatched){
            return item;
          }
        })
      }
      else {
        this.blogResultOpened = false;
      }
    })
  }
  showrelatedwithkeywords(key:any){
   // console.log(key);
    if(key == '' || key == undefined){ 
      return false;
    }
    location.href = 'blogs/search?q=' + key;
  }

}
