<div class="main-artboard position-relative">
    <div class="floating-rays">
        <img src="assets/images/big-studd.png" class="car-f0 zindex1">
    </div>
    <div class="map-loader text-center" *ngIf="mediaLoader==true">
     <span></span>
    </div>
    <section class="gallery-page zindex1">
        <div class="container position-relative">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                        <h1 class="sec-title text-center text-white mb-4">{{pageTitle}}</h1>
                        <p style="font-family: 'Roboto', sans-serif; text-align: center; color: #fff; font-size: 1.2em; margin-top: 0; margin-bottom: 2em;" [innerHTML]="pageDescription"></p>
                </div>
            </div>
            <div class="row text-center">
                <div class="media-tabs">
                    <ul class="year-list mb-5">
                        <ng-container >
                            <li (click)="getGallery('Image')" [ngClass]="type=='Image'? 'active' : ''">IMAGES</li>
                            <li (click)="getGallery('Video')" [ngClass]="type=='Video'? 'active' : ''">VIDEOS</li>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <div class="map-loader text-center" *ngIf="galleryLoader==true">
                <span></span>
               </div>
            <div class="row gallery-wrapper" id="customgallery1" *ngIf="galleryLoader == false">
                <div class="col-md-4 col-sm-6" *ngFor="let gl of gallery | paginate: { itemsPerPage: 10, currentPage: p }">
                    <div  *ngIf="gl.type == 'Youtube Video'">
                        <div class="gallery-single">
                            <iframe width="100%" height="250" [src]="gl.data | safe:'resourceUrl'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <p class="text-center">{{gl.title}}</p>
                    </div>
                    <div *ngIf="gl.type == 'Image'">
                        <div class="gallery-single">
                            <img [src]="gl.data" width="100%" alt="{{gl.alt_tag}}" (click)="openDetails($event,gl.data,gl.title,gl.alt_tag,gl.description)">
                        </div>
                        <p class="text-center">{{gl.title}}</p>
                    </div>
                    <div *ngIf="gl.type == 'Mp4 Video'">
                        <div class="gallery-single">
                            <video width="100%" height="250" controls muted [muted]="true" preload="auto" playsinline="true">
                                <source [src]="gl.data" type="video/mp4">
                            </video>
                        </div>
                        <p class="text-center">{{gl.title}}</p>
                    </div>
                </div>
            </div>
            <div class="text-center">
                    <pagination-controls (pageChange)="p = $event" class="my-pagination"></pagination-controls>
            </div>
           
        </div>
    </section>
    <div class="careerdetailpopup">
            <div class="careerdetailpopup-inner">
                <img id="image" [src]="" width="100%" class="img-responsive" alt="">
                <h2 class="heading"><span id="heading"></span></h2>
                <p class="details" id="details"></p>
                <span class="close-details" (click)="closeDetails()"><i class="fa fa-times"></i></span>
            </div>
        </div>
</div>

<button class="vid-trigger3" (click)="openPop()">
    <svg height="64" width="140">
        <path d="M62,31 a30,30 1 1,0 -30,30 l100 0" stroke-dasharray="240px" stroke-dashoffset="240px" stroke="#0fd1cf" stroke-width="2" fill="none" />
    </svg><img src="assets/images/studdsbutton2.png"><span>STUDDS In News</span></button>
<div class="vidpop" id="vidpop">
    <div class="vidpopinner text-center">
        <h4 class="vcaption">Our respected MD, Mr. Sidharth Khurana in a video interview with Jagran HiTech about import from China becoming zero, #Studds new manufacturing units, doubling capacity of motorcycle helmets, export plans, helmet industry approach as well as Covid-19 impact and new smart helmet technology.</h4>
        <div class="wrap">
            <iframe id="popvid" width="100%" height="320" src="https://www.youtube.com/embed/NNRsC0rQodI?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <span class="fa fa-times" (click)="closePop()"></span>
    </div>
</div>