<div class="main-artboard position-relative about-page">
		<div class="floating-rays">
			<img src="assets/images/big-studd.png" class="safetyray0 zindex1" alt="big studds"> 
			<!-- <img src="assets/images/clock.png" class="safetyray2 zindex1"> -->
			<img src="assets/images/ray-red.png" class="safetyray1 zindex1" alt="ray red">
		</div>
		<section class="safety-sec text-center text-white zindex1">
			<div class="container">
				<h1 class="sec-title">SAFETY DECLARATION</h1>
				
				<div class="row mb-5">
					<div class="col-lg-12">
						<div >
							<ul style="text-align:left;">
								<li>Helmets have been designed to offer you the right kind of protection during your two-wheeler ride and to reduce the risk of injury. But do remember no helmet provides 100% protection against injuries.</li>
								<li>No helmet can protect against all foreseeable and unforeseeable impacts or injuries. Helmet cannot protect against spinal injuries, neck injuries, or injury to portion of the body which is not covered by the Helmet.</li>
								<li>Helmet is made of impact absorbing materials which may or may not damage/ crack/ crush depending upon the nature and type of impact. Sometimes the impact of the force is such that helmets are not physically damaged and sometimes the impact of force is such that the helmets damages completely. In either case, at least some of the force of the impact is transmitted to the head and brain which can result in temporary or permanent injury or can result in death.</li>
								<li>Head injuries may be caused by other forces also, like scrambling an egg just by shaking it, helmets cannot prevent such type of injuries. Due to various scenarios of accident, it is not possible to mention all the scenarios when your helmet will or will not protect you against injury or death.</li>
								<li>While driving it is necessary to ensure that your helmet is properly fastened.</li>
								<li>You should not wear scarves under the fastening system nor wear caps or scarves under the helmet.</li>
								<li>The helmet can muffle traffic noise. You must always be cautious and vigilant so that you can hear essential sounds such as horns and emergency vehicle sirens.</li>
								<li>Always keep the helmet away from heat sources like the exhaust muffler, the bag seat, or the interior of a vehicle.</li>
								<li>Do not modify nor damage the helmet or any part of helmet for whatsoever reason. Use only original accessories and/or spare parts suitable for your specific helmet model.</li>
								<li>Damage to helmets may result from variety of reasons which may or may not be visible on the helmets. Helmets which received any type of impacts or injury due to any reason has to be replaced.</li>
								<li>No attachments should be made to the helmet except those recommended, if any, by Studds Accessories Limited for the helmet manufactured by it.</li>
								<li>It is your responsibility to determine which helmet model is best suited to your needs and preferences. Helmets which are not selected correctly provides less impact protection and may be more susceptible to helmet ejection during accident or any impact, which can result in injury or death.</li>
								<li>It is your responsibility to ensure the adequacy of your vision prior to using helmet. Always wear or use proper visor/ face shield/ goggles for safe riding. Poor visibility can cause an accident and result in serious injury or death, no eyewear can give a complete protection. Helmets come equipped with a visor or face shield as standard equipment. If eye protection is not provided with the helmet, it is your responsibility to obtain eye protection and use it.</li>
								<li>Tinted/ mirrored visor, goggles, or other forms of eye protection should not be used at night or under other conditions to avoid any poor visibility.</li>
								<li>A liability action cannot be brought against the Studds Accessories Limited if, at the time of harm, the product was misused, altered, modified, or not used as per the safety instruction.</li>
								<li>Studds Accessories Limited shall not be liable for failure to instruct or warn about a danger which is obvious or commonly known to you for helmets or which, you ought to have known considering the characteristics of such product.</li>
							</ul>
						</div>
					</div>
					
				</div>
				<h2 class="sec-title">{{pageTitle}}</h2>
				<h2 class="text-uppercase mt-5 mb-3" style="font-size: 1.7em">Always Remember The Number One Rule</h2>
				<div class="row mb-5">
					<div class="col-lg-6">
						<div class="saf-withbg">
							A bicycle or motorcycle rider must understand that wearing a helmet is the most effective way to 
							prevent injuries in case of a crash or fall. Scratches on the arms and knees are easier to treat 
							as compared to a head injury, which may even lead to death. With high-speed motorcycles, the risk 
							of an accident is also high. Wearing a motorcycle helmet mitigates that risk and dramatically reduces 
							the chances of a severe head injury.
						</div>
					</div>
					<div class="col-lg-6">
						<div class="saf-withbg">
							Make sure that your helmet is certified, fits you snugly and never forget to fasten the strap! 
							Motorcycle helmets do not only safeguard the head from severe injury. They also shield the rider 
							against strong winds, dust, bugs and other irritants, which may harm the eyes and compromise his/her vision of the road.
						</div>
					</div>
				</div>
				<!-- <p class="mb-4" style="line-height: 1.5em;">A bicycle or motorcycle rider must understand that wearing a helmet is the most effective way to prevent injuries in case of a crash or fall. Scratches on the arms and knees are easier to treat as compared to a head injury, which may even lead to death. With high-speed motorcycles, the risk of an accident is also high. Wearing a motorcycle helmet mitigates that risk and dramatically reduces the chances of a severe head injury.</p> -->
				<!-- <p class="mb-5" style="line-height: 1.5em;">Make sure that your helmet is certified, fits you snugly and never forget to fasten the strap! Motorcycle helmets do not only safeguard the head from severe injury. They also shield the rider against strong winds, dust, bugs and other irritants, which may harm the eyes and compromise his/her vision of the road.</p> -->
				<h2 style="margin: .6em 0 .6em; font-size: 1.7em">
					A standard motorcycle helmet has two principal protective components
				</h2>
				<div class="row text-left mb-5">
					<div class="col-lg-5 offset-lg-1 col-md-6">
						<div class="safe-pr">
							<h3>1</h3>
							<p>A thin and hard outer shell made of a plastic polymer material such as thermoplastic, or fibreglass (sometimes kevlar or carbon)</p>
						</div>
					</div>
					<div class="col-lg-5 col-md-6">
						<div class="safe-pr">
							<h3>2</h3>
							<p>A soft and thick protective padding made of expanded polystyrene or expanded polypropylene foam</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4">
						<div class="safety-single safetyleft">
							<img src="assets/images/safety1.png" class="img-fluid" alt="OPEN FACE HELMETS">
							<h3 class="safety-title mt-3">OPEN FACE HELMETS</h3>
						</div>
					</div>
					<div class="col-md-4">
						<div class="safety-single safetycenter active">
							<img src="assets/images/safety2.png" class="img-fluid" alt="MOTOCROSS HELMETS">
							<h3 class="safety-title mt-3">MOTOCROSS HELMETS</h3>
						</div>
					</div>
					<div class="col-md-4">
						<div class="safety-single safetyright">
							<img src="assets/images/safety3.png" class="img-fluid" alt="FULL FACE HELMETS">
							<h3 class="safety-title mt-3">FULL FACE HELMETS</h3>
						</div>
					</div>
				</div>
				
				
				<h3 style="margin-top: 1em; margin-bottom: .7em;">Features of the Hard Outer Shell</h3>
				<p style="margin: 0">Prevents a sharp object from penetrating the helmet, thereby protecting the inner shell from being punctured.</p>
				<p style="margin: 0">Provides structure to the inner lining, so it does not disintegrate upon a hard contact.</p>
				<h3 style="margin-top: 2em; margin-bottom: 1em;">Features of the Protective Padding</h3>
				<div class="row text-center">
					<div class="col-lg-4 col-md-6">
						<div class="padding-feature">
							<div class="pad-featimg">
								<img src="assets/icons/padding-feature1.png" alt="padding feature 1">
							</div>
							<p class="pad-featname">Absorbs the force of the impact to the rider's head in case of an accident.</p>
						</div>
					</div>
					<div class="col-lg-4 col-md-6">
						<div class="padding-feature">
							<div class="pad-featimg">
								<img src="assets/icons/padding-feature2.png" alt="padding feature 2">
							</div>
							<p class="pad-featname">Reduces the speed of deceleration, thereby increasing the distance and time over which the helmet stops.</p>
						</div>
					</div>
					<div class="col-lg-4 col-md-6">
						<div class="padding-feature">
							<div class="pad-featimg">
								<img src="assets/icons/padding-feature3.png" alt="padding feature 3">
							</div>
							<p class="pad-featname">A helmet is a good fit if it sits well on the head.</p>
						</div>
					</div>
					<div class="col-lg-4 col-md-6">
						<div class="padding-feature">
							<div class="pad-featimg">
								<img src="assets/icons/padding-feature4.png" alt="padding feature 4">
							</div>
							<p class="pad-featname">It should not be tilted forward or backward.</p>
						</div>
					</div>
					<div class="col-lg-4 col-md-6">
						<div class="padding-feature">
							<div class="pad-featimg">
								<img src="assets/icons/padding-feature5.png" alt="padding feature 5">
							</div>
							<p class="pad-featname">It should have tight, wide straps that fasten snugly under the chin.</p>
						</div>
					</div>
					<div class="col-lg-4 col-md-6">
						<div class="padding-feature">
							<div class="pad-featimg">
								<img src="assets/icons/padding-feature6.png" alt="padding feature 6">
							</div>
							<p class="pad-featname">It should be tight enough that no sudden movement should be able to move it.</p>
						</div>
					</div>
				</div>

				<h3 style="font-size: 1.5em;" class="mt-5">THE PRIMARY OBJECTIVE OF A MOTORCYCLE HELMET IS TO KEEP THE RIDER SAFE AND COMFORTABLE</h3>
			</div>
        </section>
	</div>
	

	<button class="vid-trigger3" (click)="openPop()">
		<svg height="64" width="140">
            <path d="M62,31 a30,30 1 1,0 -30,30 l100 0" stroke-dasharray="240px" stroke-dashoffset="240px" stroke="#0fd1cf" stroke-width="2" fill="none" />
        </svg><img src="assets/images/studdsbutton2.png"><span>Do You Know?</span></button>
	<div class="vidpop" id="vidpop">
		<div class="vidpopinner text-center">
			<h4 class="vcaption">A helmet is made of 100 small parts and components. Check the video to know how a helmet is made.</h4>
			<div class="wrap">
				<video controls preload="auto" playsinline="true" id="popvid">
					<source src="assets/images/about-vid.mp4" type="video/mp4">
				</video>
			</div>
			<span class="fa fa-times" (click)="closePop()"></span>
		</div>
	</div>