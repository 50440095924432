<div class="main-artboard position-relative">
		<div class="floating-rays">
			<img src="assets/images/big-studd.png" class="safetyray0 zindex1" alt="big studds">
		</div>
		<div class="map-loader text-center" *ngIf="mediaLoader==true">
			<span></span>
		</div>
		<section class="dealer-sec zindex1" *ngIf="mediaLoader==false">
			<div class="container position-relative">
				<div class="row">
					<div class="col-md-12">
						<h1 class="sec-title text-center mb-5">{{pageTitle}}</h1>
						<p style="font-family: 'Roboto', sans-serif; font-size: 1.2em; margin-top: .5em; margin-bottom: 2em;" [innerHTML]="pageDescription"></p>
						<div class="row mb-4 align-items-center" *ngFor="let man of manufacturers">
							<div class="col-lg-5 cont-det">
								<h4>{{man.title}}</h4>
								<address>
									{{man.address}}
								</address>
								<p>Ph. No.: <a href="tel:{{man.phoneUrl}}">{{man.phone}}</a></p>
								<p>Email : <a href="mailto:{{man.email}}" title="Email: {{man.email}}">{{man.email}}</a></p>
							</div>
							<div class="col-lg-7 text-center">
								<div class="cont-map" [innerHTML]="man.iframe | safe: 'html' ">
								</div>
							</div>
						</div>
					</div>
				</div>	
			</div>
        </section>
	</div>
	

	<button class="vid-trigger3" (click)="openPop()">
		<svg height="64" width="140">
            <path d="M62,31 a30,30 1 1,0 -30,30 l100 0" stroke-dasharray="240px" stroke-dashoffset="240px" stroke="#0fd1cf" stroke-width="2" fill="none" />
        </svg><img src="assets/images/studdsbutton2.png"><span>Do You Know?</span></button>
	<div class="vidpop" id="vidpop">
		<div class="vidpopinner text-center">
			<h4 class="vcaption">A helmet is made of 100 small parts and components. Check the video to know how a helmet is made.</h4>
			<div class="wrap">
				<video controls preload="auto" playsinline="true" id="popvid">
					<source src="assets/images/about-vid.mp4" type="video/mp4">
				</video>
			</div>
			<span class="fa fa-times" (click)="closePop()"></span>
		</div>
	</div>