<div class="main-artboard position-relative">
		<div class="floating-rays">
            <img src="assets/images/big-studd.png" class="car-f0 zindex1" alt="big studds">
		</div>
		<div class="map-loader text-center" *ngIf="mediaLoader==true">
			<span></span>
		</div>
		<section class="dealer-sec zindex1" *ngIf="mediaLoader==false">
			<div class="floating-rays">
				<img src="assets/images/helmet-divider.png" class="dealerbg" alt="helmet divider">	
			</div>
			<div class="container position-relative">
				<div class="row">
					<div class="col-lg-10 offset-lg-1">
						<h1 class="sec-title text-center mb-4 m-lg-0">{{pageTitle}}</h1>
						<p style="font-family: 'Roboto', sans-serif; text-align: center; font-size: 1.2em; margin-top: .5em; margin-bottom: 2em;" [innerHTML]="pageDescription"></p>
					</div>
				</div>
				<div class="row align-items-center">
					<div class="col-lg-5">
						<div class="dealer-form text-center">
							<p style="font-family: 'Roboto', sans-serif; font-size: 1.2em; margin-top: .5em; margin-bottom: 2em;">Wearing the right sized helmet is important for your safety!</p>
							<button class="btn popup-trigger mb-5" (click)="openPopup()">Know your helmet size</button>
							<select class="form-control" #ct (change)="getstates(ct.value)">
								<option value="">Select Country</option>
								<option  *ngFor="let ct of country" [value]="ct.country">{{ct.country | titlecase}}</option>
							</select>
							<select class="form-control" #st (change)="getcities(st.value)">
								<option value="">Select State</option>
								<option  *ngFor="let st of states" [value]="st.state">{{st.state | titlecase}}</option>
							</select>
							<select class="form-control" #ct (change)="getaddress(ct.value,st.value)" *ngIf="cityFound">
								<option value="">Select City</option>
								<option *ngFor="let ct of city" [value]="ct.city">{{ct.city | titlecase}}</option>
							</select>
							<select class="form-control" *ngIf="cityNotFound">
								<option value="">Select City</option>
								<option value="">No Dealers Found</option>
							</select>
							<div class="map-loader text-center" *ngIf="mapdataRecieved">
								<span></span>
							</div>
							<div  *ngIf="mapLoad ===  true" class="map-info-wrapper map-text" [ngStyle]="{'opacity':mapLoad === true ? '1' : '0' }">
								<div (click)="clickedMarker('b',add.id)" style="cursor: pointer" class="map-info-single-text" *ngFor="let add of address">
									<h4 >{{add.name}}</h4>
									<p>{{add.add1}}, {{add.add2}}, {{add.city}}, {{add.state}}, {{add.zipcode}}</p>
									<p *ngIf="add.email!=''"><strong>Email Id: </strong><a href="mailto:{{add.email}}">{{add.email}}</a></p>
									<p><strong>Phone No: </strong><a href="tel:{{add.phone1}}">{{add.phone1}}</a><span *ngIf="add.phone2!=''">, </span> <a href="tel:{{add.phone2}}">{{add.phone2}}</a></p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-7 text-center">
					 	<div class="gmap-wrap" [ngStyle]="{'border-radius':mapLoad === true ? '0' : '50%' }">
							<div class="dealer-anim-wrap" [ngStyle]="{'opacity':mapLoad === true ? '0' : '1' }">
								<div class="dealer-anim">
								</div>
							</div>
							<div class="map-info-wrapper" [ngStyle]="{'opacity':mapLoad === true ? '1' : '0' }" >
								<div class="map-info-single-map" *ngIf="mapLoad ===  true" >
									<agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [fitBounds]="fitBounds" (mapReady)="mapReady($event)">
											<agm-direction [origin]="origin" [destination]="destination" *ngIf="ifGetDirection == true">
												</agm-direction>
                                          <agm-marker *ngFor="let m of address; let i = index" [latitude]="m.latitude" [longitude]="m.longitude" [agmFitBounds]="agmFitBounds" (markerClick)="clickedMarker(infowindow,m.id)">
                                          <agm-info-window #infowindow  [isOpen]="isInfoWindowOpen(m.id)">
                                            <h4>{{m.name}}</h4>
											<p>{{m.add1}}, {{m.add2}}, {{m.city}}, {{m.state}}, {{m.zipcode}}</p>
											<a target="blank" href="https://www.google.com/maps/dir/?api=1&destination={{m.latitude}},{{m.longitude}}"><button class="btn btn-info btn-xs">Get Directions</button></a>
                                          </agm-info-window>
                                          </agm-marker>
                                    </agm-map>	
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </section>
	</div>
	<div class="detail-popup" id="detail-popup">
		<div class="popup-inner">
			<ng-container *ngFor="let det of popupDetails | slice:0:1">
				<h3 class="text-center">{{det.heading}}</h3>
				<div [innerHTML]="det.description | safe:'html'"></div>
			</ng-container>
			<span class="fa fa-times" (click)="closePopup()"></span>
		</div>
	</div>

	<button class="vid-trigger3" (click)="openPop()">
		<svg height="64" width="140">
            <path d="M62,31 a30,30 1 1,0 -30,30 l100 0" stroke-dasharray="240px" stroke-dashoffset="240px" stroke="#0fd1cf" stroke-width="2" fill="none" />
        </svg><img src="assets/images/studdsbutton2.png"><span>Do You Know?</span></button>
	<div class="vidpop" id="vidpop">
		<div class="vidpopinner text-center">
			<h4 class="vcaption">A helmet is made of 100 small parts and components. Check the video to know how a helmet is made.</h4>
			<div class="wrap">
				<video controls preload="auto" playsinline="true" id="popvid">
					<source src="assets/images/about-vid.mp4" type="video/mp4">
				</video>
			</div>
			<span class="fa fa-times" (click)="closePop()"></span>
		</div>
	</div>