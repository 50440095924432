import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  newsletterForm: FormGroup;
  formSuccess: boolean;
  errorno:any;
  hide:any;
  loader:any;
  constructor(private data: ApiService, private route: ActivatedRoute, private fb: FormBuilder) { }
  footerData: any;
  footerQuotes: any;
  footerQuotesBy: any;
  footerLinks: any;
  footerSocial: any;
  footerCopyrights: any;
  desktop:any;
  ngOnInit() {
    let winWidth = window.innerWidth;
    if(winWidth > 575) {
      this.desktop = true;
    }
    else {
      this.desktop = false;
    }


    $( document ).ready( ()=> {
      window.addEventListener('scroll', (e)=> {

        if( this.isOnScreen( $( '.footer-sec' ) ) ) { 
            this.data.getFooterSettings().subscribe(data=>{
              this.footerData = data;
              this.footerQuotes = this.footerData[0].quotes;
              this.footerQuotesBy = this.footerData[0].quotesby;
              this.footerCopyrights = this.footerData[0].copyright;
              this.footerLinks = this.footerData[0].footerpageid.split(',');
            })
            this.data.getFooterSocial().subscribe(data=>{
              this.footerSocial = data;
            })
          }
        });
    });

    this.newsletterForm = this.fb.group({
      email : ['', [Validators.required,Validators.email]]
    })
  }

  newsletterSubmit(): void{
    this.loader=true;
    this.errorno='';
    if(this.newsletterForm.value == ""){
      this.formSuccess = false;
      this.loader=false;
    }
    else{
     
      this.hide=false;
        this.data.uploadnewsletter(this.newsletterForm.value).subscribe(
          (res) => {
            this.loader=false;
            if((res) == 1) {
              this.errorno=1;
            }
            else if (res == 0) {
              this.formSuccess = false;
            }
            else if (res == 3) {
              this.errorno=3;
            }
            setTimeout(function(){
              this.hide = true;
              this.newsletterForm.reset();
            }.bind(this), 2000)
          },
          (err) => console.log(err)
        )
       
      }
    }

    isOnScreen(elem) {
      // if the element doesn't exist, abort
      if( elem.length == 0 ) {
        return;
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height
    
      return (top >= viewport_top && top < viewport_bottom) ||
      (bottom > viewport_top && bottom <= viewport_bottom) ||
      (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
    }

}
