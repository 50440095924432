    <div class="main-artboard position-relative">
		<div class="floating-rays">
            <img src="assets/images/big-studd.png" class="car-f0 zindex1" alt="big studds">
		</div>
        <div class="map-loader text-center" *ngIf="mediaLoader==true">
            <span></span>
        </div>
		<section class="outlet-sec zindex1" *ngIf="mediaLoader==false">
			<div class="container position-relative">
				<div class="row">
					<div class="col-lg-10 offset-lg-1 text-left text-md-center">
						<h1 class="sec-title mb-4 m-lg-0">{{pageTitle}}</h1>
                        <p style="font-family: 'Roboto', sans-serif; font-size: 1.2em; margin-top: .5em; margin-bottom: 1.5em;" [innerHTML]="pageDescription"></p>
                        <p style="font-family: 'Roboto', sans-serif; font-size: 1.2em; margin-top: .5em; margin-bottom: 2.5em;">Wearing the right sized helmet is important for your safety!&nbsp;&nbsp;<button class="btn popup-trigger mt-3 mt-md-0" (click)="openPopup()">Know your helmet size</button></p>
							
					</div>
				</div>
				<div class="row">
					<div class="col-lg-10 offset-lg-1">
                        <div class="row text-center">
                            <div class="col-md-6 mb-5">
                                <select class="form-control" #st (change)="getCityData(st.value, false)">
                                    <option value="">Select State</option>
                                    <ng-container *ngFor="let state of states">
                                        <option [attr.selected]="state.stateurl == currentState ? true : null">{{state.state | titlecase}}</option>
                                    </ng-container>
                                    <option *ngIf="noState">No States to show</option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-5">
                                <select class="form-control" #ct (change)="getOutletData(ct.value, false)">
                                    <option>Select City</option>
                                    <ng-container *ngFor="let city of cities">
                                        <option [attr.selected]="city.cityurl == currentCity ? true : null">{{city.city | titlecase}}</option>
                                    </ng-container>
                                    <option *ngIf="noCity">No City to show</option>
                                </select>
                            </div>
                        </div>
                        <ng-container *ngIf="loading==false">
                            <div class="outlet-single row align-items-center" *ngFor="let otl of outlets">
                                <div class="outlet-inner col-md-6">
                                    <h4>{{otl.name}}</h4>
                                    <p style="line-height: 15px">{{otl.description}}, {{otl.address}}</p>
                                    <p><strong>Email Id: </strong>{{otl.email}}, <strong>Phone No: </strong>{{otl.phone}}</p>
                                    <agm-map [latitude]="otl.lat" [longitude]="otl.lng" [zoom]="13" [fitBounds]="false" >
                                        <agm-marker [latitude]="otl.lat" [longitude]="otl.lng" [agmFitBounds]="false">
                                        <agm-info-window>
                                          <h4>{{otl.name}}</h4>
                                          <p>{{otl.address}} {{otl.city}}, {{otl.state}}, {{otl.zipcode}}</p>
                                          <a target="blank" href="https://www.google.com/maps/dir/?api=1&destination={{otl.lat}},{{otl.lng}}"><button class="btn btn-info btn-xs">Get Directions</button></a>
                                        </agm-info-window>
                                        </agm-marker>
                                    </agm-map>
                                </div>
                                <div class="outlet-img col-md-6" style="cursor: pointer;margin-top: 15px;">
                                    <div class="outlet-slider">
                                        <owl-carousel-o [options]="brandOptions">
                                            <ng-container *ngFor="let img of otl.image; let i=index;">
                                                <ng-template carouselSlide [id]="i">
                                                    <img [src]="img.image" class="img-fluid" alt="{{img.alt_tag}}" (click)="openDetails($event,otl, otl.image)">
                                                </ng-template>
                                            </ng-container>
                                        </owl-carousel-o>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="map-loader text-center" *ngIf="loading==true">
                            <span></span>
                        </div>

                    </div>
				</div>
			</div>
        </section>

        <div class="careerdetailpopup" id="branddetailpopup" *ngIf="openpopup==true">
            <div class="careerdetailpopup-inner">
                <div class="popuptext">
                    <h4>{{all.name}}</h4>
                        <p>{{all.city}}, {{ all.state}}</p>
                </div>
                    <owl-carousel-o [options]="brandPopUpOptions">
                            <ng-container *ngFor="let popupimg of allImages; let i=index;">
                                <ng-template carouselSlide [id]="i">
                                    <img [src]="popupimg.image" class="img-fluid popupimg" alt="{{all.name}},{{all.city}}, {{ all.state}}" >
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                        <span class="close-details" (click)="closeDetails()"><i class="fa fa-times"></i></span>
            </div>
        </div>

    </div>

    <div class="detail-popup" id="detail-popup">
		<div class="popup-inner">
			<ng-container *ngFor="let det of popupDetails | slice:0:1">
				<h3 class="text-center">{{det.heading}}</h3>
				<div [innerHTML]="det.description | safe:'html'"></div>
			</ng-container>
			<span class="fa fa-times" (click)="closePopup()"></span>
		</div>
    </div>
    
    <button class="vid-trigger3" (click)="openPop()">
		<svg height="64" width="140">
            <path d="M62,31 a30,30 1 1,0 -30,30 l100 0" stroke-dasharray="240px" stroke-dashoffset="240px" stroke="#0fd1cf" stroke-width="2" fill="none" />
        </svg><img src="assets/images/studdsbutton2.png"><span>Do You Know?</span></button>
	<div class="vidpop" id="vidpop">
		<div class="vidpopinner text-center">
			<h4 class="vcaption">A helmet is made of 100 small parts and components. Check the video to know how a helmet is made.</h4>
			<div class="wrap">
				<video controls preload="auto" playsinline="true" id="popvid">
					<source src="assets/images/about-vid.mp4" type="video/mp4">
				</video>
			</div>
			<span class="fa fa-times" (click)="closePop()"></span>
		</div>
	</div>